import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Footer extends Component {
	render() {
		return (
			<div className='layout-footer-container responsive-container'>
				<div>
					<h1 className='footer-section-title'>Om os</h1>
					<p className='footer-text'>
						Andreas Udlejning ApS beskæftiger sig med udlejning af
						kvalitetsdygtige autocampere til en pris alle kan være
						med på.
					</p>
				</div>
				<div>
					<h1 className='footer-section-title'>Generelt</h1>
					<p className='footer-text'>Andreasudlejning ApS</p>
					<a href='tel:+4532422464' className='footer-link'>
						<p className='footer-text'>+45 32 42 24 64</p>
					</a>
					<a
						href='mailto:kontakt@andreasudlejning.dk'
						className='footer-link'
					>
						<p className='footer-text'>
							kontakt@
							<wbr />
							andreasudlejning.dk
						</p>
					</a>
					<p className='footer-text'>
						Hundtofte 14
						<br /> 5771 Stenstrup
					</p>
					<p>CVR: 41 92 95 53</p>
				</div>
				<div>
					<h1 className='footer-section-title'>Information</h1>
					<Link to='/udlejer' className='footer-link'>
						<p className='footer-text'>Bliv samarbejdspartner</p>
					</Link>
					<Link to='/handelsbetingelser' className='footer-link'>
						<p className='footer-text'>Handelsbetingelser</p>
					</Link>
					<Link to='/lejebetingelser' className='footer-link'>
						<p className='footer-text'>Lejebetingelser</p>
					</Link>
					<Link to='/privatlivspolitik' className='footer-link'>
						<p className='footer-text'>
							Persondatapolitik, Cookie- og Privatlivspolitik
						</p>
					</Link>
				</div>
				<div>
					<h1 className='footer-section-title'>
						Telefonens åbningstider
					</h1>
					<div className='footer-date-time-container'>
						<p className='footer-text'>Mandag</p>
						<p className='footer-text'>09:00 - 17:00</p>
					</div>
					<div className='footer-date-time-container'>
						<p className='footer-text'>Tirsdag</p>
						<p className='footer-text'>09:00 - 17:00</p>
					</div>
					<div className='footer-date-time-container'>
						<p className='footer-text'>Onsdag</p>
						<p className='footer-text'>09:00 - 17:00</p>
					</div>
					<div className='footer-date-time-container'>
						<p className='footer-text'>Tordag</p>
						<p className='footer-text'>09:00 - 17:00</p>
					</div>
					<div className='footer-date-time-container'>
						<p className='footer-text'>Fredag</p>
						<p className='footer-text'>09:00 - 17:00</p>
					</div>
					<div className='footer-date-time-container'>
						<p className='footer-text'>Lørdag</p>
						<p className='footer-text'>09:00 - 17:00</p>
					</div>
					<div className='footer-date-time-container'>
						<p className='footer-text'>Søndag</p>
						<p className='footer-text'>09:00 - 13:00</p>
					</div>
				</div>
				<div className='copyright-footer'>
					<p>© Andreasudlejning – 2021</p>
					<p>Af: CodeTask</p>
				</div>
			</div>
		);
	}
}
