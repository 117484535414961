import LoadingContainer from '../../containers/loadingcontainer/loadingcontainer';
import AcAddons from './acaddons';
import AcBookHeader from './acbookheader';
import AcDetail from './acdetail';
import AcImage from './acimage';
import AcLocation from './aclocation';
import AcPolicy from './acpolicy';
// import AcRating from './acrating';

const AcLayout = ({
	displaySize,
	autocamper,
	scrollToCalender,
	setButtonRef,
	displayHeaderButton,
	setImageIndex,
}) => {
	const ac = autocamper.getObject();

	return (
		<>
			{displaySize === 'mobile' ? (
				<div className='ac-page-details-mobile-container ac-page-responsive-container'>
					<div className='ac-page-details-mobile-column'>
						<LoadingContainer requestObject={autocamper}>
							<AcImage
								displaySize={displaySize}
								autocamper={ac}
								setImageIndex={setImageIndex}
							/>
							<AcDetail
								autocamper={ac}
								scrollToCalender={scrollToCalender}
								setButtonRef={setButtonRef}
							/>
							<AcLocation autocamper={ac} />
							<AcAddons autocamper={ac} />
						</LoadingContainer>
					</div>
				</div>
			) : (
				<div className='ac-page-details-desktop-container ac-page-responsive-container'>
					<div className='ac-page-details-desktop-column'>
						<LoadingContainer requestObject={autocamper}>
							<AcImage
								displaySize={displaySize}
								autocamper={ac}
								setImageIndex={setImageIndex}
							/>
							<AcLocation autocamper={ac} />
						</LoadingContainer>
					</div>
					<div className='ac-page-details-desktop-column'>
						<LoadingContainer
							requestObject={autocamper}
							showErrors={false}
						>
							<AcDetail
								autocamper={ac}
								scrollToCalender={scrollToCalender}
								setButtonRef={setButtonRef}
							/>
							<AcAddons autocamper={ac} />
							<AcPolicy autocamper={ac} />
						</LoadingContainer>
					</div>
				</div>
			)}
			<AcBookHeader
				display={displayHeaderButton}
				autocamper={ac}
				scrollToCalender={scrollToCalender}
			/>
		</>
	);
};

export default AcLayout;
