import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import DefaultLayout from '../../containers/layout/defaultlayout/defaultlayout';
import './../rentelconditionpage/styles.css';

export default class TermsOfTradePage extends Component {
	render() {
		return (
			<>
				<Helmet>
					<title>Handelsbetingelser</title>
					<meta
						name='description'
						content='Handelsbetingelser for Andreas Udlejning'
					/>
				</Helmet>
				<DefaultLayout>
					<div className='policy-layout-container responsive-container'>
						<div className='policy-title-container'>
							<h1 className='policy-title'>
								Handelsbetingelser for Andreas Udlejning ApS
							</h1>
							<h2 className='policy-subtitle'>
								Når du handler og booker hos os gælder
								nedenstående
							</h2>
							<h3 className='policy-undertitle'>
								Andreas Udlejning ApS
								<br /> Hundstrupvej 60
								<br /> 5771 Stenstrup
								<br />
								TLF: +4532422464
								<br /> CVR: 41929553
								<br />
							</h3>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Betaling</h1>
							<p className='policy-section-text'>
								Betaling skal ske ved booking med mindre andet
								er aftalt eller fremgår af din ordre. Alle beløb
								på hjemmesiden er inklusiv moms. Hjemmesiden
								bruger følgende valutaer til prissætning:
								Denmark – Kroner (DKK). Andreas Udlejning ApS’s
								egen hjemmeside bruger ligeledes kryptering med
								SSL protokol.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Fortrydelsesret
							</h1>
							<p className='policy-section-text'>
								Der gives 14 dages fuld fortrydelsesret på varer
								købt på hjemmesiden med mindre andet er aftalt
								eller fremgår af din ordre. Den 14 dags periode
								starter den dag hvor booking er registeret.
								Ønske om fortrydelse skal meddeles os senest 14
								efter booking. Ønske om brug af fortrydelsesret
								skal sendes på mail kontakt@andreasudlejning.dk
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Kunde- & Privatlivspolitik
							</h1>
							<p className='policy-section-text'>
								Vi videresælger ikke personlige oplysninger og
								videregiver ikke dine personlige oplysninger til
								andre, de er kun registreret i vores
								kundekartotek. Du kan til enhver tid få slettet
								dine oplysninger. For at du kan indgå aftale med
								Andreas Udlejning ApS, har vi brug for følgende
								oplysninger: <br />
								<br />
								Navn <br />
								Adresse <br /> Telefonnummer <br /> E-mail
								adresse
								<br />
								<br /> Vi foretager registreringen af dine
								personoplysninger med det formål, at kunne
								levere varen til dig. Personoplysningerne
								registreres hos Andreas Udlejning ApS og
								opbevares i op til fem år, hvorefter
								oplysningerne slettes. Når der indsamles
								personoplysninger via vores hjemmeside, sikrer
								vi, at det altid sker ved afgivelse af dit
								udtrykkelige samtykke, således at du er
								informeret om præcis, hvilke oplysninger, der
								indsamles og hvorfor. Direktøren og de ansatte
								for Andreas Udlejning ApS har adgang til de
								oplysninger, der registreres om dig. Den
								dataansvarlige i Andreas Udlejning ApS er:
								Andreas Rummelhoff Vi opbevarer og transmitterer
								ikke kundeoplysninger krypteret. Som registreret
								hos Andreas Udlejning ApS har du altid ret til
								at gøre indsigelse mod registreringen. Du har
								også ret til indsigt i, hvilke oplysninger, der
								er registreret om dig. Disse rettigheder har du
								efter Persondataloven og henvendelse i
								forbindelse hermed rettes til
								kontakt@andreasudlejning.dk Cookies På
								andreasudlejning.dk anvendes cookies med det
								formål at optimere hjemmesiden og dets
								funktionalitet, og dermed gøre besøget så nemt
								som muligt for dig. Du kan til enhver tid slette
								cookies fra din computer, se vejledningen på{' '}
								<a
									href='https://www.thagaard.org/cookiehandtering/'
									className='policy-section-link'
								>
									Webshop Cookies og Privatlivspolitik
								</a>
								. Log-statistik Vi bruger en log-statistik på
								andreasudlejning.dk, hvilket betyder, at et
								statistik-system opsamler information, som kan
								give et statistisk billede af, hvor mange
								besøgende vi har, hvor de kommer fra og hvor på
								websitet det forlades mv. Logstatistikken
								anvendes kun med det formål, at optimere Andreas
								Udlejning ApS’s hjemmeside.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Forbrugerbeskyttelse
							</h1>
							<p className='policy-section-text'>
								Læs mere om din beskyttelse som forbruger når du
								køber via en PensoPay betalingsløsning:
								<a
									href='https://pensopay.com/mere/forbrugerbeskyttelse/'
									className='policy-section-link'
								>
									https://pensopay.com/mere/forbrugerbeskyttelse/
								</a>
								.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Klagemuligheder
							</h1>
							<p className='policy-section-text'>
								Ved klage skal vores e-mail adresse angives:
								kontakt@andreasudlejning.dk samt hjemmesiden du
								har købt varen på. En klage over en vare eller
								tjenesteydelse kan indgives til Center for
								Klageløsning, Nævnenes Hus, Toldboden 2, 8800
								Viborg. Du kan klage til Center for Klageløsning
								via Klageportalen for Nævnenes Hus (
								<a
									href='https://kpo.naevneneshus.dk/Public/Home/ChooseLoginProvider?returnUrl=https://kpo.naevneneshus.dk/External'
									className='policy-section-link'
								>
									Link
								</a>
								). For EU-borgere uden for EU skal klager sendes
								via EU Kommissionens online klageplatform.{' '}
								<a
									href='ec.europa.eu'
									className='policy-section-link'
								>
									ec.europa.eu
								</a>
							</p>
						</div>
					</div>
				</DefaultLayout>
			</>
		);
	}
}
