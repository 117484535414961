import { useCallback, useEffect, useState } from 'react';

const useResize = () => {
	const [ref, setRef] = useState(null);
	const [size, setSize] = useState({ width: 0, height: 0 });

	const handleResize = useCallback(() => {
		setSize({
			width: ref?.offsetWidth || 0,
			height: ref?.offsetHeight || 0,
		});
	}, [ref?.offsetWidth, ref?.offsetHeight]);

	window.addEventListener('resize', handleResize);

	useEffect(() => {
		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [ref?.offsetWidth, ref?.offsetHeight, handleResize]);

	return [setRef, size];
};

export default useResize;
