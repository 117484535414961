import { GoogleMap, Marker } from '@react-google-maps/api';
import { getGeocode, getLatLng } from 'use-places-autocomplete';
import { useEffect, useState } from 'react';

const AcMap = ({ address }) => {
	const [marker, setMarker] = useState(null);

	useEffect(() => {
		const markerSetup = async () => {
			try {
				const results = await getGeocode({
					address: address,
				});
				const { lat, lng } = getLatLng(results[0]);
				setMarker({ lat, lng });
			} catch (error) {}
		};

		markerSetup();
	}, [address]);

	return (
		<>
			{marker !== null ? (
				<GoogleMap
					zoom={12}
					center={marker}
					mapContainerClassName='test-map'
					options={{
						mapTypeControl: false,
						scaleControl: false,
						streetViewControl: false,
						rotateControl: false,
						fullscreenControl: false,
					}}
				>
					<Marker position={{ lat: marker?.lat, lng: marker?.lng }} />
				</GoogleMap>
			) : (
				<p>Loading...</p>
			)}
		</>
	);
};

export default AcMap;
