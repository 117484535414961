import axios from 'axios';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import DefaultLayout from '../../containers/layout/defaultlayout/defaultlayout';
import LoadingButton from '../../loadingbutton/loadingbutton';

const ForgotPage = () => {
	const [forgotEmail, setForgotEmail] = useState('');
	const [forgotError, setForgotError] = useState('');
	const [loadingForgot, setLoadingForgot] = useState(false);
	const [forgotSent, setForgotSent] = useState(false);

	const forgotPassword = async () => {
		try {
			setLoadingForgot(true);
			const response = await axios({
				url: process.env.REACT_APP_API_URL + '/auth/forgot-password',
				method: 'POST',
				data: {
					email: forgotEmail,
				},
			});

			if (response.status === 200) {
				setForgotSent(true);
			} else {
				setForgotError('Noget gik galt.');
			}
		} catch (error) {
			if (error?.response?.status === 400) {
				setForgotError('Findes ingen bruger med denne email.');
			} else {
				setForgotError('Noget gik galt.');
			}
		}
		setLoadingForgot(false);
	};

	return (
		<DefaultLayout>
			<Helmet>
				<title>Glemt adgangskode</title>
				<meta
					name='description'
					content='Har du glemt din adgangskode?'
				/>
				<meta name='robots' content='' />
			</Helmet>
			<div className='login-page-section'>
				<div className='login-container forgot-password-container'>
					{forgotSent === false ? (
						<>
							<div className='forgot-header-container'>
								<h1 className='forgot-title'>
									Glemt adgangskode?
								</h1>
								<h2 className='forgot-subtitle'>
									Skriv din email for at nulstille din
									adgangskode.
								</h2>
							</div>

							<div className='forgot-password-buttons-container'>
								<div className='login-field-container'>
									<label className='login-field-input-label'>
										Email
									</label>
									<input
										type='text'
										className='signup-input'
										value={forgotEmail}
										onChange={(e) =>
											setForgotEmail(e.target.value)
										}
									></input>
								</div>
								{forgotError !== '' && (
									<p className='forgot-error-message'>
										{forgotError}
									</p>
								)}
								<LoadingButton
									classes='login-button'
									isLoading={loadingForgot}
									clickHandler={forgotPassword}
									text='Send email'
								/>
								<Link
									to='/logind'
									className='forgot-password-text'
								>
									Gå tilbage til log ind
								</Link>
							</div>
						</>
					) : (
						<>
							<div className='forgot-header-container'>
								<h1 className='forgot-title'>
									Glemt adgangskode email sendt!
								</h1>
							</div>
							<div>
								Vi har sendt en email til {forgotEmail}, hvori
								der er et link i som kan nulstille adgangskoden.
							</div>
						</>
					)}
				</div>
			</div>
		</DefaultLayout>
	);
};

export default ForgotPage;
