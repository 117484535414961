import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import validateSignup from '../../../utils/validateSignup';
import DefaultLayout from '../../containers/layout/defaultlayout/defaultlayout';
import LoadingButton from '../../loadingbutton/loadingbutton';
import './styles.css';

const ResetPage = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [code, setCode] = useState(null);
	const [newPassword, setNewPassword] = useState('');
	const [repeatPassword, setRepeatPassword] = useState('');
	const [resetError, setResetError] = useState(null);
	const [status, setStatus] = useState('reset');
	const search = useLocation().search;
	const navigate = useNavigate();

	useEffect(() => {
		const urlParams = new URLSearchParams(search);
		const code = urlParams.get('code');

		if (code !== undefined && code !== null) {
			setCode(code);
		} else {
			navigate('/');
		}
	}, [search, navigate]);

	const resetPassword = async () => {
		setIsLoading(true);
		if (validateSignup.validatePassword(newPassword, repeatPassword)) {
			try {
				const response = await axios({
					url: process.env.REACT_APP_API_URL + '/auth/reset-password',
					method: 'POST',
					data: {
						code: code,
						password: newPassword,
						passwordConfirmation: repeatPassword,
					},
				});

				if (response.status === 200) {
					setStatus('complete');
				} else {
					setResetError('Noget gik galt.');
				}
			} catch (error) {
				if (error.message) {
					setResetError(error.message);
				} else {
					setResetError('Noget gik galt.');
				}
			}
		} else {
			setResetError('Ugyldig adgangskode.');
		}
		setIsLoading(false);
		setRepeatPassword('');
	};

	return (
		<DefaultLayout>
			<div className='reset-main-container'>
				<div className='reset-container'>
					{status === 'reset' && (
						<React.Fragment>
							<div>
								<h1>Skift adgangskode</h1>
							</div>
							<div className='reset-inputs-container'>
								<div className='reset-input-field-container'>
									<label className='reset-input-field-label'>
										Ny adgangskode
									</label>
									<input
										className='reset-input-field'
										type='password'
										value={newPassword}
										onChange={(e) =>
											setNewPassword(e.target.value)
										}
									/>
								</div>
								<div className='reset-input-field-container'>
									<label className='reset-input-field-label'>
										Gentag adgangskode
									</label>
									<input
										className='reset-input-field'
										type='password'
										value={repeatPassword}
										onChange={(e) =>
											setRepeatPassword(e.target.value)
										}
									/>
								</div>
							</div>
							<div className='reset-button-container'>
								<LoadingButton
									text='Skift adgangskode'
									classes='reset-button'
									isLoading={isLoading}
									clickHandler={resetPassword}
								></LoadingButton>
							</div>
							{resetError !== null && (
								<div>
									<p className='reset-error-text'>
										{resetError}
									</p>
								</div>
							)}
						</React.Fragment>
					)}
					{status === 'complete' && (
						<div className='reset-complete-container'>
							<h1>Adgangskode ændret</h1>
							<p>Du har nu ændret din adgangskode.</p>
							<button
								className='reset-complete-button'
								onClick={() => navigate('/')}
							>
								OK
							</button>
						</div>
					)}
				</div>
			</div>
		</DefaultLayout>
	);
};

export default ResetPage;
