import { useEffect, useState } from 'react';
import OutsideAlerter from '../../containers/outsidealerter/outsidealerter';
import CalenderItem from './calenderitem';
import './styles.css';

const Calender = ({
	hide,
	setDate,
	selected,
	min,
	max,
	isMin,
	other,
	resetOther,
	availableDays,
	month,
	previousMonth,
	nextMonth,
	isLoading,
	error,
	closestBooking,
}) => {
	const [today] = useState(new Date());
	const [dateItems, setDateItems] = useState([]);

	useEffect(() => {
		let isMounted = true;

		const updateDateItems = () => {
			if (month !== null) {
				const daysInMonth = new Date(
					Date.UTC(month.getFullYear(), month.getMonth() + 1, 0)
				).getDate();

				const newDateItems = [];
				for (let i = 1; i <= daysInMonth; i++) {
					newDateItems.push(
						new Date(
							Date.UTC(
								month.getUTCFullYear(),
								month.getUTCMonth(),
								i
							)
						)
					);
				}

				if (isMounted) setDateItems(newDateItems);
			}
		};

		updateDateItems();

		return () => {
			isMounted = false;
		};
	}, [month]);

	const nameOfDays = ['MAN', 'TIR', 'ONS', 'TOR', 'FRE', 'LØR', 'SØN'];

	return (
		<OutsideAlerter clickHandler={hide}>
			<div className='calender-selection-container'>
				<div className='calender-selection-header-container'>
					<div className='calender-selection-header-arrow-container'>
						<img
							src='/arrow-left.svg'
							className='calender-selection-arrow'
							onClick={() => previousMonth()}
							alt='previous month'
						/>
					</div>
					<div className='calender-selection-title-container'>
						<p className='calender-selection-month-title'>
							{month !== null &&
								month
									.toLocaleString('dk-DK', {
										month: 'short',
										year: 'numeric',
									})
									.toUpperCase()}
						</p>
					</div>
					<div className='calender-selection-header-arrow-container'>
						<img
							src='/arrow-left.svg'
							className='calender-selection-arrow calender-selection-arrow-right'
							onClick={() => nextMonth()}
							alt='next month'
						/>
					</div>
				</div>
				<div className='calender-selection-days-container'>
					{nameOfDays.map((name, i) => {
						return (
							<div
								className='calender-selection-day-item'
								key={i}
							>
								<p className='calender-selection-day-text'>
									{name}
								</p>
							</div>
						);
					})}
				</div>

				{error === true ? (
					<div className='calender-item-date-error-container'>
						<span className='calender-item-date-error'>
							Noget gik galt...
						</span>
					</div>
				) : (
					<div className='calender-selection-items-container'>
						{dateItems.map((day, i) => {
							return (
								<CalenderItem
									day={day}
									key={i}
									conditions={{
										today: today,
										min: isMin ? selected : min,
										max: isMin ? max : selected,
									}}
									setDate={setDate}
									selected={selected}
									other={other}
									isMin={isMin}
									resetOther={resetOther}
									availableDays={availableDays}
									isLoading={isLoading}
									closestBooking={closestBooking}
								/>
							);
						})}
					</div>
				)}
			</div>
		</OutsideAlerter>
	);
};

export default Calender;
