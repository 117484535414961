import React, { Component } from 'react';
import DefaultLayout from '../../containers/layout/defaultlayout/defaultlayout';
import TimeSelection from '../../TimeSelection/timeselection';
import './styles.css';
import TimeObject from '../../../utils/timeobject';
import RequestObject from '../../../utils/requestobject';
import axios from 'axios';
import AutocamperList from '../../autocamper/autocamperlist';
import { Helmet } from 'react-helmet-async';

class HomePage extends Component {
	state = {
		selectedStart: new TimeObject(null),
		selectedEnd: new TimeObject(null),
		popular: new RequestObject(null),
	};

	frontImage = {
		backgroundImage: `url("/front.jpg")`,
	};

	componentDidMount = async () => {
		const popular = new RequestObject(this.getPopular);
		if (popular.getStatus() === 0) {
			await popular.sendRequest();
			const newPopular = popular.clone();
			this.setState({ popular: newPopular });
		}
	};

	setStartTime = (day) => {
		if (day instanceof Date) {
			this.setState({ selectedStart: new TimeObject(day, 32) });
		}
	};

	setStartClock = (clock) => {
		if (Number.isInteger(clock)) {
			this.setState({
				selectedStart: new TimeObject(
					this.state.selectedStart.getDate,
					clock
				),
			});
		}
	};

	setEndTime = (day) => {
		if (day instanceof Date) {
			this.setState({ selectedEnd: new TimeObject(day, 20) });
		}
	};

	setEndClock = (clock) => {
		if (Number.isInteger(clock)) {
			this.setState({
				selectedEnd: new TimeObject(
					this.state.selectedEnd.getDate,
					clock
				),
			});
		}
	};

	getPopular = async () => {
		return await axios.get(
			process.env.REACT_APP_API_URL + '/popular-autocampers'
		);
	};

	searchAutocampers = () => {
		let parameters = '?';
		const { selectedStart, selectedEnd } = this.state;

		if (selectedStart.isSelected())
			parameters += `start=${selectedStart.getDate.getTime()}&startTime=${
				selectedStart.getTime
			}`;

		if (selectedEnd.isSelected()) {
			if (parameters !== '?') parameters += '&';
			parameters += `end=${selectedEnd.getDate.getTime()}&endTime=${
				selectedEnd.getTime
			}`;
		}

		window.location.href = '/autocamper' + parameters;
	};

	render() {
		const { selectedStart, selectedEnd } = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>Andreas Udlejning</title>
					<meta
						name='description'
						content='Vi udlejer kvalitetsdygtige autocampere til en pris all kan være med på. Se vores udvalg eller kontakt os for nærmere infomration'
					/>
					<meta
						name='keywords'
						content='autocamper, autocampere, udlejning, andreasudlejning, andreas udlejning, autocamperleje, fyn, sydfyn, stenstrup'
					/>
					<script type='application/ld+json'>
						{JSON.stringify({
							'@context': 'https://schema.org',
							'@type': 'Organization',
							url: 'https://andreasudlejning.dk',
							logo: 'https://andreasudlejning.dk/small-logo.png',
						})}
					</script>
				</Helmet>
				<DefaultLayout>
					<section
						className='frontpage-top-container'
						style={this.frontImage}
					>
						<div className='frontpage-search-container'>
							<div className='frontpage-search-input-container'>
								<TimeSelection
									value={selectedStart}
									setValue={this.setStartTime}
									setClock={this.setStartClock}
									text={'Startdato'}
									other={selectedEnd}
									max={selectedEnd}
									isMin={true}
									resetOther={() =>
										this.setState({
											selectedEnd: new TimeObject(null),
										})
									}
								/>
								<div className='frontpage-search-divider'></div>
								<TimeSelection
									value={selectedEnd}
									setValue={this.setEndTime}
									setClock={this.setEndClock}
									text={'Slutdato'}
									other={selectedStart}
									min={selectedStart}
									isMin={false}
									resetOther={() =>
										this.setState({
											selectedStart: new TimeObject(null),
										})
									}
								/>
							</div>
							<div className='frontpage-search-divider'></div>
							<div className='frontpage-search-item-search-container'>
								<div className='frontpage-search-button-item'>
									<div className='frontpage-search-item-button-container'>
										<button
											className='frontpage-search-item-button'
											onClick={this.searchAutocampers}
										>
											<img
												src='/search.svg'
												className='frontpage-search-button-icon'
												alt='search'
											/>
										</button>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className='popular-autocampers-section'>
						<div className='responsive-container'>
							<div className='popular-autocamper-header'>
								<h1 className='popular-autocamper-title'>
									POPULÆRE
								</h1>
								<h1 className='popular-autocamper-subtitle'>
									AUTOCAMPERE
								</h1>
							</div>
							<div className='popular-autocampers-container'>
								<AutocamperList
									autocampers={this.state.popular}
								/>
							</div>
						</div>
					</section>
					<section className='news-article-section responsive-container'>
						<div className='news-article-centering'>
							<div className='news-article-images-container'>
								<div className='news-article-news-papers-container'>
									<img
										src='/ugeavisen-svendborg.jpg'
										alt='Ugeavisen Svendborg'
										className='news-article-news-paper-image'
									/>
								</div>
								<img
									src='/article.jpg'
									alt='Article about Andreas Udlejning'
									className='news-article-image'
								/>
							</div>
							<div className='news-article-text-outer-container'>
								<div className='news-article-text-container'>
									<h1>Gør feriedrømmene til virkelighed</h1>
									<p>
										“Vores mission er at gøre det muligt for
										alle at opleve Danmark og resten af
										Europa fra en Autocamper. Vi mener at
										autocamper livet skal være for alle, og
										vi vil gøre vores bedste for at gøre
										dette muligt for alle”
									</p>
									<p className='news-article-writters'>
										- Andreas Udlejning
									</p>
									<div>
										<a href='https://ugeavisen.dk/ugeavisensvendborg/artikel/to-21-%C3%A5rige-med-fart-p%C3%A5-andreas-og-andreas-har-k%C3%B8bt-tre-autocampere-og-det-er-kun-begyndelsen?fbclid=IwAR1F1dwN3bsIG0FZDRnUJFJQUameuPAyK6UoGVT1RUEdQd1e4FJur9tNge0'>
											<button className='news-article-button'>
												Læs artiklen
											</button>
										</a>
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className='review-section responsive-container'>
						<div className='review-header-container'>
							<h1 className='review-title'>Anmeldelser</h1>
							<div className='review-social-media-container'>
								<a href='https://www.facebook.com/AndreasUdlejning'>
									<button className='review-social-media-button'>
										<img
											src='/facebook.svg'
											alt='facebook'
										/>
									</button>
								</a>
								<a href='https://www.instagram.com/andreasudlejning/'>
									<button className='review-social-media-button'>
										<img
											src='/instagram.svg'
											alt='instagram'
										/>
									</button>
								</a>
								<a href='https://www.youtube.com/channel/UCD73tRG18tcspXvaD1b7nYQ'>
									<button className='review-social-media-button'>
										<img src='/youtube.svg' alt='youtube' />
									</button>
								</a>
							</div>
						</div>
						<div className='review-items-container'>
							<div className='review-item'>
								<img
									src='/user-avatar.svg'
									alt='User avatar'
									className='review-item-avatar'
								/>
								<p className='review-item-name'>
									Susanne Winther Jensen
								</p>
								<p className='review-item-media'>Facebook</p>
								<p className='review-item-text'>
									"Dejlig oplevelse på vores debut tur med
									autocamper og med god service fra Andreas
									Udlejning 👍😊. Vi prøver helt sikkert igen
									en anden gang 😀👍"
								</p>
							</div>
							<div className='review-item'>
								<img
									src='/user-avatar.svg'
									alt='User avatar'
									className='review-item-avatar'
								/>
								<p className='review-item-name'>
									Maria Fonfara
								</p>
								<p className='review-item-media'>Facebook</p>
								<p className='review-item-text'>
									"Skønne unge mennesker, så kyndig vejledning
									og råd under turen ❤️"
								</p>
							</div>
							<div className='review-item'>
								<img
									src='/user-avatar.svg'
									alt='User avatar'
									className='review-item-avatar'
								/>
								<p className='review-item-name'>Martin Loeb</p>
								<p className='review-item-media'>Facebook</p>
								<p className='review-item-text'>
									"Ubetinget venlige og reelle udlejere.
									Priserne er gode. Autocamperne er i orden.
									Kan varmt anbefales...😀"
								</p>
							</div>
							<div className='review-item'>
								<img
									src='/user-avatar.svg'
									alt='User avatar'
									className='review-item-avatar'
								/>
								<p className='review-item-name'>John Larsen</p>
								<p className='review-item-media'>Facebook</p>
								<p className='review-item-text'>
									"Super fin autocamper - alt virkede som det
									skulle - og vi vil helt sikkert leje hos dem
									igen. ...og så er det jo faktisk ret
									billigt."
								</p>
							</div>
						</div>
					</section>
				</DefaultLayout>
			</React.Fragment>
		);
	}
}

export default HomePage;
