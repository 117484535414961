import axios from 'axios';
import { useParams } from 'react-router-dom';
import auth from '../../../utils/auth';
import React, { useState, useEffect } from 'react';
import RequestObject from '../../../utils/requestobject';
import DefaultLayout from '../../containers/layout/defaultlayout/defaultlayout';
import LoadingContainer from '../../containers/loadingcontainer/loadingcontainer';
import utility from '../../../utils/utility';

import './styles.css';
import { Helmet } from 'react-helmet-async';
import DateUtility from '../../../utils/dateutiltity';

export default function BookingPage() {
	const { id } = useParams();

	const getBooking = async () => {
		return await axios.get(
			process.env.REACT_APP_API_URL + '/user/booking/' + id,
			{
				headers: {
					Authorization: `Bearer ${auth.getToken()}`,
					'Content-Type': 'application/json',
				},
			}
		);
	};

	const [booking, setBooking] = useState(new RequestObject(getBooking));

	useEffect(() => {
		let isCancelled = false;

		async function requesting(requestObject, id) {
			if (requestObject.getStatus() === 0) {
				const req = requestObject;
				await req.sendRequest(id);
				if (!isCancelled) {
					const newRequestObject = req.clone();
					setBooking(newRequestObject);
				}
			}
		}

		requesting(booking, id);
		return () => {
			isCancelled = true;
		};
	}, [booking, id]);

	const finalBooking = booking.getObject();
	let addonPrice = 0;
	let start;
	let end;
	let creation;
	let paymentDay1;
	let paymentDay2;
	let paymentDay3;
	const status = {
		unpaid: {
			text: 'Ikke betalt',
			class: 'booking-page-payment-status-unpaid',
		},
		partly: {
			text: 'Delvist betalt',
			class: 'booking-page-payment-status-partly',
		},
		paid: { text: 'Betalt', class: 'booking-page-payment-status-paid' },
	};
	if (finalBooking !== null) {
		for (var i = 0; i < finalBooking.payment_addons.length; i++) {
			addonPrice += finalBooking.payment_addons[i].price;
		}
		start = new Date(parseInt(finalBooking.calender_object.start));
		end = new Date(parseInt(finalBooking.calender_object.end));

		creation = new Date(finalBooking.calender_object.created_at);
		paymentDay1 = utility.latestPaymentDateOfRate(creation, start, 1, 1);
		paymentDay2 = utility.latestPaymentDateOfRate(creation, start, 2, 1);
		paymentDay3 = utility.latestPaymentDateOfRate(creation, start, 2, 2);
	}
	const totalPrice =
		finalBooking?.startPrice +
		finalBooking?.autocamperPrice +
		addonPrice +
		finalBooking?.serviceFee;

	return (
		<>
			<Helmet>
				<title>Booking</title>
				<meta name='robots' content='noindex' />
			</Helmet>
			<DefaultLayout>
				<div className='booking-page-layout'>
					<div className='booking-page-layout-list'>
						<div className='booking-page-autocamper-container booking-page-layout-container'>
							<LoadingContainer requestObject={booking}>
								{finalBooking !== null && (
									<React.Fragment>
										<div className='booking-page-autocamper-header-container'>
											<div className='booking-page-autocamper-image-container'>
												<img
													src={utility.getAutocamperImageSize(
														finalBooking
															.calender_object
															.autocamper
															.avatar[0],
														'small'
													)}
													alt='autocamper'
													className='booking-page-autocamper-image'
												/>
											</div>
											<div className='booking-page-autocamper-detail-container'>
												<div className='booking-page-autocamper-detail-inner-container'>
													<h1>
														{
															finalBooking
																.calender_object
																.autocamper.name
														}
													</h1>
													<p>
														{
															finalBooking
																.calender_object
																.autocamper
																.organization
																.name
														}
													</p>
													<p>
														Booking:{' '}
														{utility.getIdFormat(
															finalBooking.id
														)}
													</p>
												</div>
											</div>
										</div>
										<div className='booking-page-autocamper-period-container'>
											<h2 className='booking-page-section-title'>
												Lejeperioden
											</h2>
											<div className='booking-page-section-table'>
												<div className='booking-page-period-inner-container'>
													<p>Start:</p>
													<p>Slut:</p>
													<p className='booking-page-period-time'>
														{DateUtility.getClock(
															start
														)}{' '}
														{start.getUTCDate()}/
														{start.getUTCMonth() +
															1}
														-
														{start.getUTCFullYear()}
													</p>
													<p className='booking-page-period-time'>
														{DateUtility.getClock(
															end
														)}{' '}
														{end.getUTCDate()}/
														{end.getUTCMonth() + 1}-
														{end.getUTCFullYear()}
													</p>
												</div>
											</div>
										</div>
										<div className='booking-page-autocamper-booking-container'>
											<h2 className='booking-page-section-title'>
												Oprettelsestidspunkt
											</h2>
											<p className='booking-page-booking-time'>
												{DateUtility.getClock(creation)}{' '}
												{creation.getUTCDate()}/
												{creation.getUTCMonth() + 1}-
												{creation.getUTCFullYear()}
											</p>
										</div>
									</React.Fragment>
								)}
							</LoadingContainer>
						</div>
						<div className='booking-page-addon-container booking-page-layout-container'>
							<LoadingContainer requestObject={booking}>
								{finalBooking !== null && (
									<div className='booking-page-addon-inner-container'>
										<h2 className='booking-page-section-title'>
											Tilkøb
										</h2>
										<div className='booking-page-section-table'>
											{finalBooking.payment_addons
												.length === 0 ? (
												<div className='booking-page-section-table-row'>
													<p>Ingen tilkøb</p>
												</div>
											) : (
												finalBooking.payment_addons.map(
													(addon, i) => {
														return (
															<div
																className='booking-page-section-table-row'
																key={i}
															>
																<div className='booking-page-section-row-name-container'>
																	<p className='booking-page-section-row-name booking-page-section-row-name-highlighted'>
																		{
																			addon
																				.addon
																				.name
																		}
																	</p>
																	<p className='booking-page-section-row-description'>
																		{
																			addon
																				.addon
																				.description
																		}
																	</p>
																</div>
																<p className='booking-page-section-row-value'>
																	{addon.price.toFixed(
																		2
																	)}{' '}
																	DKK
																</p>
															</div>
														);
													}
												)
											)}
										</div>
									</div>
								)}
							</LoadingContainer>
						</div>
					</div>

					<div className='booking-page-payment'>
						<LoadingContainer requestObject={booking}>
							{finalBooking !== null && (
								<React.Fragment>
									<div className='booking-page-payment-container booking-page-layout-container'>
										<h2 className='booking-page-section-title'>
											Beløb
										</h2>
										<div className='booking-page-section-table'>
											<div className='booking-page-section-table-row'>
												<p className='booking-page-section-row-name'>
													Depositum
												</p>
												<p className='booking-page-section-row-value'>
													{finalBooking.deposit.toFixed(
														2
													)}{' '}
													DKK
												</p>
											</div>
											<div className='booking-page-section-table-row'>
												<p className='booking-page-section-row-name'>
													Startpris
												</p>
												<p className='booking-page-section-row-value'>
													{finalBooking.startPrice.toFixed(
														2
													)}{' '}
													DKK
												</p>
											</div>
											<div className='booking-page-section-table-row'>
												<p className='booking-page-section-row-name'>
													Leje
												</p>
												<p className='booking-page-section-row-value'>
													{finalBooking.autocamperPrice.toFixed(
														2
													)}{' '}
													DKK
												</p>
											</div>
											<div className='booking-page-section-table-row'>
												<p className='booking-page-section-row-name'>
													Servicegebyr
												</p>
												<p className='booking-page-section-row-value'>
													{finalBooking.serviceFee.toFixed(
														2
													)}{' '}
													DKK
												</p>
											</div>
											<div className='booking-page-section-table-row'>
												<p className='booking-page-section-row-name'>
													Tilkøb
												</p>
												<p className='booking-page-section-row-value'>
													{addonPrice.toFixed(2)} DKK
												</p>
											</div>
											<div className='booking-page-section-table-row'>
												<p className='booking-page-section-row-name booking-page-section-row-name-highlighted'>
													Total
												</p>
												<p className='booking-page-section-row-value booking-page-section-row-value-highlighted'>
													{(
														totalPrice +
														finalBooking?.deposit
													).toFixed(2)}{' '}
													DKK
												</p>
											</div>
										</div>
										<h2 className='booking-page-section-title'>
											Betaling
										</h2>
										<div className='booking-page-section-table'>
											<div className='booking-page-section-table-row'>
												<p className='booking-page-section-row-name'>
													Betalingsstatus
												</p>
												<p
													className={
														'booking-page-section-row-value ' +
														status[
															finalBooking.status
														].class
													}
												>
													{
														status[
															finalBooking.status
														].text
													}
												</p>
											</div>
											<div className='booking-page-section-table-row'>
												<p className='booking-page-section-row-name'>
													Betalingsmetode
												</p>
												<p className='booking-page-section-row-value'>
													{finalBooking.paymentMethod}
												</p>
											</div>
											<div className='booking-page-section-table-row'>
												<p className='booking-page-section-row-name'>
													Betalingsrate
												</p>
												<p className='booking-page-section-row-value'>
													{finalBooking.rate === 1
														? '1 gangs'
														: '2 gange'}
												</p>
											</div>
										</div>
										{finalBooking.rate === 1 && (
											<React.Fragment>
												<div className='booking-page-section-table'>
													<div className='booking-page-section-table-row'>
														<p className='booking-page-section-row-name'>
															Senest betaling
														</p>
														<p className='booking-page-section-row-value'>
															{paymentDay1.getUTCDate()}
															/
															{paymentDay1.getUTCMonth() +
																1}
															-
															{paymentDay1.getUTCFullYear()}
														</p>
													</div>
													<div className='booking-page-section-table-row'>
														<p className='booking-page-section-row-name'>
															Beløb
														</p>
														<p className='booking-page-section-row-value'>
															{(
																totalPrice +
																finalBooking?.deposit
															).toFixed(2)}{' '}
															DKK
														</p>
													</div>
												</div>
											</React.Fragment>
										)}
										{finalBooking.rate === 2 && (
											<React.Fragment>
												<h2 className='booking-page-section-title'>
													Første rate
												</h2>
												<div className='booking-page-section-table'>
													<div className='booking-page-section-table-row'>
														<p className='booking-page-section-row-name'>
															Senest betaling
														</p>
														<p className='booking-page-section-row-value'>
															{paymentDay2.getUTCDate()}
															/
															{paymentDay2.getUTCMonth() +
																1}
															-
															{paymentDay2.getUTCFullYear()}
														</p>
													</div>
													<div className='booking-page-section-table-row'>
														<p className='booking-page-section-row-name'>
															Beløb
														</p>
														<p className='booking-page-section-row-value'>
															{finalBooking.deposit.toFixed(
																2
															)}{' '}
															DKK
														</p>
													</div>
												</div>
												<h2 className='booking-page-section-title'>
													Anden rate
												</h2>
												<div className='booking-page-section-table'>
													<div className='booking-page-section-table-row'>
														<p className='booking-page-section-row-name'>
															Senest betaling
														</p>
														<p className='booking-page-section-row-value'>
															{paymentDay3.getUTCDate()}
															/
															{paymentDay3.getUTCMonth() +
																1}
															-
															{paymentDay3.getUTCFullYear()}
														</p>
													</div>
													<div className='booking-page-section-table-row'>
														<p className='booking-page-section-row-name'>
															Beløb
														</p>
														<p className='booking-page-section-row-value'>
															{totalPrice.toFixed(
																2
															)}{' '}
															DKK
														</p>
													</div>
												</div>
											</React.Fragment>
										)}
										<h2 className='booking-page-section-title'>
											Depositumrefundering
										</h2>
										<div className='booking-page-section-table'>
											<div className='booking-page-section-table-row'>
												<p className='booking-page-section-row-name'>
													Kontonr.
												</p>
												<p className='booking-page-section-row-value'>
													{finalBooking?.userAccountNumber
														? finalBooking?.userAccountNumber
														: 'Ikke angivet'}
												</p>
											</div>
											<div className='booking-page-section-table-row'>
												<p className='booking-page-section-row-name'>
													Reg.nr.
												</p>
												<p className='booking-page-section-row-value'>
													{finalBooking?.userRegistrationNumber
														? finalBooking?.userRegistrationNumber
														: 'Ikke angivet'}
												</p>
											</div>

											{finalBooking?.deposit_refund ===
											null ? (
												<div className='booking-page-section-table-row'>
													<p className='booking-page-section-row-name'>
														Refundering
													</p>
													<p className='booking-page-section-row-value'>
														Ikke færdig
													</p>
												</div>
											) : (
												<>
													<div className='booking-page-section-table-row'>
														<p className='booking-page-section-row-name'>
															Refundering
														</p>
														<p className='booking-page-section-row-value'>
															{finalBooking?.deposit_refund?.userFund.toFixed(
																2
															)}{' '}
															DKK
														</p>
													</div>
													<div className='booking-page-section-table-row'>
														<p className='booking-page-section-row-name'>
															Beskrivelse
														</p>
														<p className='booking-page-section-row-value'>
															{
																finalBooking
																	?.deposit_refund
																	?.depositDescription
															}
														</p>
													</div>
													<div className='booking-page-section-table-row'>
														<p className='booking-page-section-row-name'>
															Udbetalt
														</p>
														<p className='booking-page-section-row-value'>
															{finalBooking
																?.deposit_refund
																?.userPaid ===
															true
																? 'Ja'
																: 'Nej'}
														</p>
													</div>
												</>
											)}
										</div>
									</div>
								</React.Fragment>
							)}
						</LoadingContainer>
					</div>
				</div>
			</DefaultLayout>
		</>
	);
}
