import React, { useState, useEffect } from 'react';
import LoadingContainer from '../containers/loadingcontainer/loadingcontainer';
import Autocamper from './autocamper';
import useResize from '../../hooks/useResize';
import { useCallback } from 'react';

const AutocamperList = ({ autocampers }) => {
	const [autocamperIndex, setAutocamperIndex] = useState(0);
	const [displayAutocampers, setDisplayAutocampers] = useState([]);
	const [autocamperDisplayCount, setAutocamperDisplayCount] =
		useState(undefined);
	const [listRef, { width }] = useResize();
	const [maxWidth] = useState(400);

	const updateAutocamperList = useCallback(() => {
		const newDisplayAutocampers = [];
		const autocampersList = autocampers.getObject();
		if (autocampersList !== null) {
			for (var i = 0; i < autocamperDisplayCount; i++) {
				newDisplayAutocampers.push(
					autocampersList[
						(autocamperIndex + i) % autocampersList.length
					]
				);
			}
			setDisplayAutocampers(newDisplayAutocampers);
		}
	}, [autocamperIndex, autocamperDisplayCount, autocampers]);

	useEffect(() => {
		const list = autocampers.getObject();
		if (list !== null) {
			const res = Math.floor(width / maxWidth) + 1;
			setAutocamperDisplayCount(res < list.length ? res : list.length);
			updateAutocamperList(autocamperIndex, res);
		}
	}, [width, autocampers, autocamperIndex, maxWidth, updateAutocamperList]);

	const goBackwards = () => {
		const autocamperList = autocampers.getObject();
		if (autocamperList !== null) {
			var index = autocamperIndex - autocamperDisplayCount;
			index = index > 0 ? index : autocamperList.length - index - 1;

			setAutocamperIndex(index);
			updateAutocamperList(index, autocamperDisplayCount);
		}
	};

	const goForwards = () => {
		const autocamperList = autocampers.getObject();
		if (autocamperList !== null) {
			var index = autocamperIndex + autocamperDisplayCount;
			index =
				index < autocamperList.length
					? index
					: index - autocamperList.length;

			setAutocamperIndex(index);
			updateAutocamperList(index, autocamperDisplayCount);
		}
	};

	return (
		<LoadingContainer requestObject={autocampers}>
			<div className='autocamper-list-container' ref={listRef}>
				<div className='autocamper-list-button-container'>
					<button
						onClick={() => goBackwards()}
						className='autocamper-list-button'
					>
						<img
							src='/arrow-left.svg'
							alt='forward arrow'
							className='autocamper-list-button-icon'
						/>
					</button>
				</div>
				<div
					className='autocamper-list'
					style={{
						gridTemplateColumns: `repeat(${autocamperDisplayCount}, 1fr)`,
						maxWidth: autocamperDisplayCount
							? `${maxWidth * autocamperDisplayCount}px`
							: '1920px',
					}}
				>
					{autocampers.getObject() !== null &&
						displayAutocampers.map((item) => {
							return (
								<Autocamper
									key={item.autocamper.id}
									autocamper={item.autocamper}
								/>
							);
						})}
				</div>
				<div className='autocamper-list-button-container'>
					<button
						onClick={() => goForwards()}
						className='autocamper-list-button'
					>
						<img
							src='/arrow-left.svg'
							alt='forward arrow'
							className='autocamper-list-button-icon autocamper-list-button-icon-right'
						/>
					</button>
				</div>
			</div>
		</LoadingContainer>
	);
};

export default AutocamperList;
