import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import DefaultLayout from '../../containers/layout/defaultlayout/defaultlayout';
import './styles.css';

export default class RentalConditionPage extends Component {
	render() {
		return (
			<>
				<Helmet>
					<title>Lejebetingelser</title>
					<meta
						name='description'
						content='Lejebetingelser for Andreas Udlejning'
					/>
				</Helmet>
				<DefaultLayout>
					<div className='policy-layout-container responsive-container'>
						<div className='policy-title-container'>
							<h1 className='policy-title'>
								Lejebetingelser hos Andreas Udlejning ApS
							</h1>
							<h2 className='policy-subtitle'>Autocampere</h2>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Priser</h1>
							<p className='policy-section-text'>
								Alle priser er oplyst i danske kroner (DKK) og
								inkl. moms.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Bestilling, betaling og depositum
							</h1>
							<p className='policy-section-text'>
								Ved reservation af autocamper får lejer tilsendt
								bekræftelse samt lejebetingelser. I forbindelse
								med leje af autocamper indbetales et depositum
								på 5.000 DKK. Man kan enten vælge at betale både
								depositummet og lejeprisen samtidig hvor dette
								betales straks, eller kan man vælge at opdele
								betallingen i to rater. Hvor første rate
								(depositum) betales straks. Anden rate (den
								samlede lejepris) betales senest 30 dage fra
								lejeperiodens start. Hvis booking sker med under
								30 dage til lejeperiodens start, betales hele
								beløbet samlet (depositum + den samlede
								lejepris). I vores absolutte højsæson skal hele
								betallingen ske med det samme, både depositummet
								og lejeprisen(Uge 26 til uge 34). Lejer hæfter
								for alle krav, der måtte opstå som følge af
								lejers brug af autocamperen, herunder bøder,
								afgifter, skader, m.v. Depositum refunderes
								senest 14 dage efter aflevering af autocamperen.{' '}
								<span className='policy-section-highlight-text'>
									OBS: Ved kørsel i Sverige og Norge kan der
									gå op til 120 dage før udlejer modtager
									opkrævninger for vejafgifter. Lejer kan
									derfor med fordel gemme kvitteringer for
									betaling af vejafgifter, da udlejer ellers
									ser sig nødsaget til at tilbageholde en del
									af depositum i op til 120 dage til
									eventuelle ubetalte vejafgifter.
								</span>
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Afbestilling og forkortelse af lejeperioden
							</h1>
							<p className='policy-section-text'>
								Ved afbestilling indtil 8 mdr. før
								udleveringsdagen refunderes det eventuelt
								indbetalte depositum samt lejebeløbet på nær
								1.000 DKK pr. uge Ved afbestilling indtil 3 mdr.
								før udleveringsdagen refunderes det eventuelt
								indbetalte depositum samt lejebeløbet på nær
								2.000 DKK pr. uge. Ved afbestilling 2 mdr. før
								udleveringsdagen hæfter Lejer for den fulde
								leje, såfremt autocamperen ikke kan udlejes til
								anden lejer i den reserverede periode. Ved
								udlejning til anden lejer refunderes det
								eventuelt indbetalte depositum samt lejebeløbet
								på nær 4.000 DKK pr. uge. Det er her muligt at
								tilkøbe sig en afbestillingsforsikring, som gør
								det muligt at få både depositum og den fulde
								lejepris tilbage helt op til en uge før
								lejeperioden, til en pris på 8% af den samlede
								lejepris.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Lejeperiode
							</h1>
							<p className='policy-section-text'>
								Medmindre andet er aftalt direkte med Andras
								Udlejning vil lejeperioden være i hele uger.
								Udlevering foregår i tidsrummet som er valgt ved
								bestilling af autocamperen, men kan rykkes til
								tidligere/senere, hvis logistikken tillader det.
								Aflevering foregår på den aftalte dag, på det
								valgte tidspunkt. Lejer kan med fordel aftale et
								mere præcist tidspunkt for udlevering og
								aflevering med udlejer, da der således
								forhindres mest mulig ventetid. I højsæsonen kan
								der forekomme ekstraordinær ventetid ved
								udlevering. Fra d. 24/06 (Uge 26) til d. 26/08
								(Uge 34) er det kun muligt at booke minimum en
								uge af gangen, og dette er fra fredag til
								fredag, med afhentning kl. 16 og aflevering kl.
								10.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Kørselsbetingelser
							</h1>
							<p className='policy-section-text'>
								Autocamperen må af forsikringsmæssige årsager
								kun føres af personer over 18 år, der på
								udleveringsdagen har et gyldigt kørekort B.
								Autocamperen må kun føres af personer, som har
								kørekort, og er skrevet på kontrakten til at
								måtte føre autocamperen. Det er lejers ansvar,
								at der under kørsel kun transporteres det antal
								personer, som autocamperen har seler og
								lasteevne til, og alle personer skal til enhver
								tid under kørsel have selen fastspændt.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Udlevering</h1>
							<p className='policy-section-text'>
								Ved afhentning af autocamperen, skal lejer tage
								billleder af diverse skader, for at dokumentere
								at disse skader ikke er sket under lejeperioden.
								Der laves sammen med lejer en gennemgang samt
								instruktion i brugen af autocamperen, hvorefter
								lejekontrakten underskrives af såvel lejer som
								udlejer. Skader der ikke er dokumenteret via
								billeder før lejen, vil lejer blive opkrævet for
								efterfølgende.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Parkering af privatbil
							</h1>
							<p className='policy-section-text'>
								I lejeperioden er der fri parkering af din
								private bil hos os på eget ansvar.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Standardudstyr
							</h1>
							<p className='policy-section-text'>
								Dette inkluderer nødvendigheder til optimalt
								brug af autocamperen, som eksempelvis
								strømkabel, vandslange, advarselstrekant samt
								instruktionsmanualer. Alle autocampere er
								udstyret med ferskvandstank på minimum 70 liter,
								bruser, kassettetoilet, 2 – 3 gaskogeblus,
								køleskab og fryser, varmeanlæg i
								beboelseskabinen. I størstedelen af alle
								autocampere er der cykelholder med plads til
								minimum 2 cykler. Se beskrivelse i udlejers
								katalog og/eller på hjemmeside.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Under rejsen
							</h1>
							<p className='policy-section-text'>
								Lejer er forpligtet til på daglig basis at
								kontrollere vand, luft, olie, diesel og andet,
								der kan have betydning for autocamperens
								fortsatte funktion. Lejer hæfter for skader, der
								er en direkte følge af forsømmelser ved ikke at
								varetage vedligehold og eftersyn på autocamperen
								i lejeperioden. Lejer hæfter for slidskader, som
								skyldes uansvarlig behandling af autocamperen.
								Selvrisikoen dækker ikke ved sådanne
								forsømmelser. Læs selv mere om selvrisiko under
								særskilt afsnit.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Hvis uheldet er ude
							</h1>
							<p className='policy-section-text'>
								Vi anbefaler, at du altid tager billeder af
								skaderne på bilerne med din mobil, så omfanget
								og involvering kan dokumenteres.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Er der sket personskade
							</h1>
							<p className='policy-section-text'>
								Ring straks 112.
								<br /> Bed eventuelle vidner om at vente på
								politiet eller noter som minimum, navn, adresse
								og telefonnummer på vidnerne.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Er der udelukkende sket skade på materiel
							</h1>
							<p className='policy-section-text'>
								Lejer skal skriftligt beskrive skaden i noterne
								på sin smartphone. Sørg for at få så mange
								oplysninger som muligt om eventuel modpart og
								vidner inkl. navn, adresse og telefonnummer og
								lav en skriftlig beskrivelse af forløbet. Lejer
								skal overfor modpart oplyse, at bilen er lejet
								via Andreas Udlejning, og at bilen i
								udlejningsperioden er forsikret hos Topdanmark.
								Er skaden alvorlig, skal lejer straks kontakte
								udelejer. Ved større skader skal Andreas
								Udlejning altid kontaktes med det samme på
								telefon +45 32 42 24 64. Hvis skaden er sket
								udenfor telefonens åbningstider, kan man altid
								få direkte kontakt til os ved at taste 9.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Kan bilen ikke køre efter skade eller får bilen
								driftstop
							</h1>
							<p className='policy-section-text'>
								Topdanmark Vejhjælp dækker i Danmark samt i
								Europa. Topdanmark Vejhjælp yder assistance ved
								uheld eller uforudset driftsstop, fx ved
								starthjælp, skift af hjul, oplukning af bildør,
								fritrækning ved fastkørsel eller udbringning af
								brændstof på nedbrudstedet. Kan bilen ikke
								fortsætte ved egen kraft, yder Topdanmark
								Vejhjælp i form af bjærgning eller transport.
								Transporten af bilen sker altid til nærmeste
								værksted, der kan reparere bilen. Ved kørsel i
								DK: Kontakt Topdanmark Vejhjælp på +45 44 74 73
								72 og oplys registreringsnummer på bilen samt at
								bilen er lejet via Andreas Udlejning. Lejer skal
								altid kontakte udlejer og informere om hvad der
								er sket, samt hvor bilen transporteres hen. Ved
								kørsel i udlandet: Kontakt hurtigst muligt
								udlejer med henblik på beslutning om reparation.
								Herefter kan Topdanmark Vejhjælp Europa
								kontaktes på +45 38 48 89 82 for bugsering til
								nærmeste egnede værksted. Oplys
								registreringsnummer på bilen samt at bilen er
								lejet via Andreas Udlejning. <br />
								<span className='policy-section-highlight-text'>
									Kilometerforbrug
								</span>
								<br /> Ved leje i en uge medfølger 1.500 km.
								Øvrigt forbrug beregnes med 2 DKK pr. km. Ved
								mindre leje end en uge medfølger 250 km pr. dag.
								Øvrigt forbrug beregnes med 2 DKK pr. km.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Elkedler</h1>
							<p className='policy-section-text'>
								Kogning af vand, m.v., bør foregå i gryde eller
								kedel på komfurets gasblus. De fleste
								campingpladser i Europa kan ikke håndtere det
								høje strømforbrug en elkedel kræver, og det kan
								derfor resultere i strømsvigt på hele pladsen.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Opvask</h1>
							<p className='policy-section-text'>
								Det anbefales at afvask af service m.m. klares
								udendørs, så afløb ikke tilstoppes i camper.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Rygning</h1>
							<p className='policy-section-text'>
								Det er ikke tilladt at ryge i autocamperen pga.
								allergi, overholder lejer ikke dette, vil
								camperen blive rengjort på lejers regning, pris:
								fra kr. 5.000, afhængig af omfanget.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Hund</h1>
							<p className='policy-section-text'>
								Hund er tilladt i autocamperen ved aftale med
								udlejer, dette koster dog 59 kr. pr. dag. Hvis
								man ikke meddeler til udlejer at man har hund
								med, og der alligevel findes hundehår i
								autocamperen, vil lejer blive opkrævet et beløb
								på kr. 3000 til rengøring af hele autocamperen.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Trafikforseelser
							</h1>
							<p className='policy-section-text'>
								Udlejer opfordrer lejer til at sætte sig
								grundigt ind i de besøgte landes trafikregler,
								idet lejer hæfter for alle politimæssige
								anliggender og andre krav, der skyldes lejers
								brug af køretøjet. Udlejer kan således ikke
								gøres ansvarlig herfor. Dette inkluderer
								ligeledes samtlige trafikforseelser, herunder
								fartbøder, parkeringsbøder, manglende betaling
								for vejafgifter, m.v. samt andre krav, der kan
								relateres til lejeforholdet. Udlejer forbeholder
								sig retten til at opkræve lejer for bøder
								hvilket først og fremmest dækkes af det betalte
								depositum.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Trafikforseelser
							</h1>
							<p className='policy-section-text'>
								Udlejer opfordrer lejer til at sætte sig
								grundigt ind i de besøgte landes trafikregler,
								idet lejer hæfter for alle politimæssige
								anliggender og andre krav, der skyldes lejers
								brug af køretøjet. Udlejer kan således ikke
								gøres ansvarlig herfor. Dette inkluderer
								ligeledes samtlige trafikforseelser, herunder
								fartbøder, parkeringsbøder, manglende betaling
								for vejafgifter, m.v. samt andre krav, der kan
								relateres til lejeforholdet. Udlejer forbeholder
								sig retten til at opkræve lejer for bøder
								hvilket først og fremmest dækkes af det betalte
								depositum.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Kørsel i Sverige og Norge
							</h1>
							<p className='policy-section-text'>
								Hvis turen går nordpå, kan lejer med fordel
								forud registrere sig for hos enten
								Autopass/Norge (
								<a
									href='https://www.epcplc.com/register'
									className='policy-section-link'
								>
									https://www.epcplc.com/register
								</a>
								) eller EPASS24/Sverige (
								<a
									href='https://www.epass24.com/register'
									className='policy-section-link'
								>
									https://www.epass24.com/register
								</a>
								). Nogle steder kan der også betales med
								BroBizz. Hvis lejer fremviser kvitteringer for
								passager, anser udlejer det ikke for værende
								nødvendigt med tilbageholdelse af en del af
								depositum, jf. afsnit om depositum. Det er en
								fordel at bestille en BroBizz.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Småskader</h1>
							<p className='policy-section-text'>
								Hvis der under lejemålet springer en sikring, en
								vandpumpe holder op med at fungere, eller der
								sker andre mindre tekniske fejl, er det lejers
								pligt at få udbedret disse ting. Efter aftale
								med udlejer kan lejer indkøbe nye og mindre
								reservedele for op til 1.000 DKK som refunderes
								mod aflevering af kvitteringer.{' '}
								<span className='policy-section-highlight-text'>
									Kasko dækker ikke indvendige skader på
									interiør og løsedele. Til information er der
									en selvrisiko ved hver skade udvendig på
									autocamper på kr. 6000; som betales til
									kostpris + montering. Mindre skader anmeldes
									ikke til forsikringen.
								</span>
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Alvorlige skader
							</h1>
							<p className='policy-section-text'>
								Er der tale om en skade eller lignende af så
								alvorlig karakter at autocamperen ikke kan køre
								videre, skal lejere efter forudgående aftale med
								udlejer kontakte SOS, som derfra vil sørge for
								assistance og evt. bugsering. Eventuelle
								mekaniske fejl, som ikke skyldes lejers
								forsømmelser eller adfærd i øvrigt, dækkes af
								udlejer, men kan ikke udføres uden forudgående
								aftale med udlejer. Enhver udbedring skal
								foretages af og på et autoriseret værksted.
								Lejer er ansvarlig for skader, m.v., der er
								undtaget i normal ansvars- og kaskoforsikring,
								herunder grov uagtsomhed, beruselse,
								krigshandlinger, m.v. SOS yder hjemtransport,
								hvis reparationen varer mere end 3 dage, men
								giver ikke ydelse til kost og logi ved
								reparationer under 3 døgn. Udover SOS-ydelsen er
								lejer ikke berettiget til erstatning. Skader for
								mere end 1.000 DKK skal aftales med udlejer før
								reparationen. Regningen betales af udlejer ved
								hjemkomst, såfremt skaden ikke er forskyldt af
								lejers forsømmelser eller adfærd i øvrigt. Fejl
								på lille højtaler, køleskab, fryser, navigation
								eller andet indvendigt udstyr betragtes ikke som
								værende alvorlige skader.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Alvorlige skader
							</h1>
							<p className='policy-section-text'>
								Fejlagtig påfyldning af diesel i
								ferskvandstanken indebærer udskiftning af denne
								tank, de dertilhørende slanger og
								varmtvandsbeholderen. Denne udskiftning er ikke
								omfattet af forsikringen, og skal derfor betales
								af lejeren. Fejlagtig påfyldning af brændstof-
								noget andet end diesel – kan resultere i ødelagt
								motor, der dermed skal udskiftes. Denne
								udskiftning er ikke omfattet af forsikringen, og
								skal derfor betales af lejeren. Glemmer lejer at
								lukke beboelsesdelens sidevinduer og tagvinduer
								under kørsel, og disse beskadiges af træer, m.v.
								eller rives af i vinden, står udbedringen for
								lejers regning. Punktering af dæk betales af
								lejer.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Stenslag</h1>
							<p className='policy-section-text'>
								Stenslag er sjældent selvforskyldt, men kender
								lejer årsagen til stenslaget bør information
								herfor fremlægges, så udlejer kan få dækket
								skaden af skadevoldere. Det kan eksempelvis
								være, hvis en vognmand taber sten fra sit læs,
								eller vejen er belagt med sten som følge af
								vejarbejde, m.v. Lejer bør i sådanne tilfælde
								fremvise billeder samt information.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Tyveri</h1>
							<p className='policy-section-text'>
								Autocamperens forsikring dækker for skader på
								inventar, såfremt der foreligger en
								politirapport. Udlejer har intet ansvar for
								tyveri af personlige ejendele eller lignende.
								Lejer kan derfor med fordel tjekke sin egen
								indboforsikring, der ofte dækker for alt lejers
								gods, såfremt indbruddet politianmeldes. Det er
								lejers ansvar, at der optages politirapport, og
								at en kopi medbringes til udlejer.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Forsikring</h1>
							<p className='policy-section-text'>
								I lejen er der inkluderet fuld kasko- samt
								SOS-forsikring. Kaskoforsikringen dækker alene
								skader på autocamperens karosseri. Skader på
								installationer, møbler og andet inventar er ikke
								omfattet af kaskoforsikringen, og skal derfor
								betales af lejer. Lejers egen indboforsikring
								dækker ofte for personlige genstande efter samme
								regler som ved opbevaring i parkeret bil.
								Forsikringen er dækkende i de fleste europæiske
								lande, dog er der ikke gældende forsikring og
								derfor ikke indkørselstilladelse i Tyrkiet,
								Marokko, Israel, Rumænien og det tidligere
								Sovjetunionen.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Selvrisiko</h1>
							<p className='policy-section-text'>
								I forbindelse med hver kaskoskade,
								parkeringsskade, trafik skade og andre skader,
								er der en selvrisiko for lejer på 6.000 DKK pr.
								skade. Det er helt forsikringsbranchens regler,
								der er gældende. Skader efter fejlagtig
								betjening af autocamperen eller fejlagtig
								betjening af installationer i autocamperen er
								ikke dækket af selvrisikoforsikringen og skal
								derfor fuldt ud erstattes af lejer.
								Forsikringsselskabet betragter hver enkelt skade
								separat med individuel selvrisiko. Hvis
								føreren/lejeren i en trepunktsvending først
								bakker ind i et træ for derefter at køre
								frontalt ind i en lygtepæl, betragtes det som
								værende 2 adskilte skader med hver sin
								selvrisiko.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Aflevering</h1>
							<p className='policy-section-text'>
								Af hensyn til næste lejer er det vigtigt med
								rettidig aflevering. Der kan naturligvis opstå
								situationer, hvor lejer bliver forhindret i at
								aflevere på det aftalte tidspunkt. I så fald
								kontaktes udlejer med det samme. Ved forsinket
								aflevering i forhold til den i kontrakten
								aftalte tid, opkræves 1.000 DKK pr. påbegyndt
								time. Ved påbegyndt 4. time hæfter lejer for
								udgiften til en erstatningsvogn til
								efterfølgende lejer, svarende til den periode
								autocamperen er udlejet i. Ved aflevering før
								aftalt tid refunderes lejen for den resterende
								periode ikke. Efter aflevering laver udlejer en
								stikprøvegennemgang af autocamperen inden for to
								dage. Alle fejl, mangler og skader noteres ned
								og sendes til udlejer. Udlejer forbeholder sig
								retten til at opkræve lejer for ikke
								registrerede fejl, mangler og skader, såfremt
								der ikke er oplyst om disse ved aflevering.
								Efter endt leje sendes reg- og kontonummer til
								Andreas Udlejning på følgende mail:
								kontakt@andreasudlejning.dk. Dette er til brug
								for returnering af depositum.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Tjekliste ved aflevering
							</h1>
							<p className='policy-section-text'>
								Autocamperen udleveres rengjort, og skal
								afleveres i samme stand, hvis ikke der er
								tilkøbt slutrengøring.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Huskeliste, hvis man har bestilt rengøring
							</h1>
							<ul>
								<li>
									Tjek at I ikke har efterladt noget i skabe,
									handskerum, under madrasser, m.v.
								</li>
								<li>
									Toilet Kassetten tømmes helt. Der skal altså
									ikke være sanitets tabs, vand eller andet i
									toilet kassetten (Kun kassette cleaner).
								</li>
								<li>Køleskab og fryser tømmes.</li>
								<li>Spildevandstanken tømmes.</li>
								<li>Skrald smides ud.</li>
								<li>Dieseltank afleveres med fuld tank.</li>
								<li>Vandtank afleveres med fuld tank.</li>
							</ul>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Huskeliste, hvis man ikke har bestilt rengøring
							</h1>
							<ul>
								<li>
									Tjek at I ikke har efterladt noget i skabe,
									handskerum, under madrasser, m.v.
								</li>
								<li>
									Køkkenudstyr, service og tilbehør afleveres
									på samme vis, som det er udleveret.
								</li>
								<li>Bad og toilet skal være rengjort.</li>
								<li>
									Toiletkassetten tømmes helt. Der skal altså
									ikke være sanitetstabs, vand eller andet i
									toiletkassetten (Kun kassettecleaner).
								</li>
								<li>Køleskab og fryser tømmes og rengøres.</li>
								<li>Komfuret rengøres.</li>
								<li>
									Alle overflader, herunder borde, skabe og
									gulve, rengøres.
								</li>
								<li>
									Er der ovn i autocamperen, skal denne
									ligeledes være rengjort.
								</li>
								<li>Spildevandstanken tømmes.</li>
								<li>Skrald smides ud.</li>
								<li>Sprinklervæske fyldes op.</li>
								<li>Dieseltank afleveres med fuldtank.</li>
								<li>Vandtank afleveres med fuld tank.</li>
								<li>
									Lejer skal være ekstra opmærksom på
									rengøringen, såfremt der konstateres
									hundehår i autocamper ekstraregning på
									rengøring til kr. 3000, hvis ikke at der er
									tilkøbt dyr i bilen.
								</li>
							</ul>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Rengøring</h1>
							<p className='policy-section-text'>
								Der er mulighed for at tilkøbe slutrengøring som
								koster 499 DKK. Slutrengøring består af
								indvendig aftørring, støvsugning og vask.
								Såfremt rengøring og vask, m.v. fra ovenstående
								tjekliste fremstår som værende mangelfuld,
								opkræves dette i forbindelse med udbetaling af
								depositum. Ved ekstraordinær indvendig rengøring
								opkræves 250 DKK pr. påbegyndt halve time, (Eks.
								pletter på hynder og sæder)
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Forbehold og garanti fra udlejer
							</h1>
							<p className='policy-section-text'>
								Udlejeren tager generelt forbehold for ændringer
								i bilmodeller, udstyr, priser, beskrivelser og
								trykfejl. Udlejer forbeholder sig retten til at
								foretage ændringer til en lignende eller bedre
								autocamper uden forudgående notifikation. Kan
								den lejede autocamper ikke udleveres på grund af
								skade, tekniske fejl eller forsinket levering
								fra tidligere lejer, vil udlejer forsøge at
								finde en tilsvarende autocamper til lejemålet.
								Erstatningskøretøjet kan variere i størrelse og
								model, og lejedifferencen refunderes
								selvfølgeligt. En opgradering vil ske uden
								omkostninger for lejeren. Er lejer ikke tilfreds
								med erstatningskøretøjet, må denne være
								indforstået med at lejemålet udsættes eller
								ophæves. Ved ophævelse refunderes lejebeløbet.
								Lejer kan ikke kræve erstatning for tabt ferie
								forårsaget af uheld med den lejede autocamper.
								Ej heller som følge af mekaniske fejl eller ved
								fejl i autocamperens tekniske installationer,
								herunder mekanisk trappe, vandhane, toilet, m.v.
								Udlejer forbeholder sig retten til at ændre
								disse betingelser løbende.
							</p>
						</div>
					</div>
				</DefaultLayout>
			</>
		);
	}
}
