import axios from 'axios';
import React, { Component } from 'react';
import auth from '../../../utils/auth';
import DefaultLayout from '../../containers/layout/defaultlayout/defaultlayout';
import utility from '../../../utils/utility';
import './styles.css';
import { Helmet } from 'react-helmet-async';
import DateUtility from '../../../utils/dateutiltity';

class ProfilePage extends Component {
	state = {
		page: '',
		firstname: '',
		lastname: '',
		email: '',
		username: '',
		birthday: '',
		street: '',
		streetNumber: '',
		city: '',
		postalCode: '',
		phoneNumber: '',
		bookings: [],
	};

	componentDidMount = async () => {
		const user = auth.getUserInfo();

		this.setState({
			firstname: user.firstname,
			lastname: user.lastname,
			email: user.email,
			username: user.username,
			birthday: user.birthday,
			street: user.address !== null ? user.address.street : '',
			streetNumber:
				user.address !== null ? user.address.streetNumber : '',
			city: user.address !== null ? user.address.city : '',
			postalCode: user.address !== null ? user.address.postalCode : '',
			phoneNumber: user.phoneNumber,
			page: 'profile',
		});

		try {
			const response = await axios.get(
				process.env.REACT_APP_API_URL + '/user/booking',
				{
					headers: {
						Authorization: `Bearer ${auth.getToken()}`,
						'Content-Type': 'application/json',
					},
				}
			);

			if (response.status === 200) {
				response.data.forEach((element) => {
					element.calender_object.start = new Date(
						parseInt(element.calender_object.start)
					);
					element.calender_object.end = new Date(
						parseInt(element.calender_object.end)
					);
					element.calender_object.created_at = new Date(
						parseInt(element.created_at)
					);
				});
				this.setState({ bookings: response.data });
			}
		} catch (error) {}
	};

	setPage = (value) => {
		this.setState({ page: value });
	};

	render() {
		const {
			firstname,
			lastname,
			username,
			birthday,
			email,
			street,
			streetNumber,
			city,
			postalCode,
			phoneNumber,
		} = this.state;

		return (
			<React.Fragment>
				<Helmet>
					<title>Min Profil</title>
					<meta name='robots' content='noindex' />
				</Helmet>
				<DefaultLayout>
					<div className='profile-page-layout-container'>
						<div className='profile-page-main-container'>
							<div className='profile-page-sidebar-container'>
								<span
									className='profile-page-sidebar-item'
									onClick={() => this.setPage('profile')}
								>
									Profil
								</span>
								<span
									className='profile-page-sidebar-item'
									onClick={() => this.setPage('booking')}
								>
									Leje
								</span>
							</div>
							<div className='profile-page-content-container'>
								{this.state.page === 'profile' && (
									<div className='profile-page-profile-content-container'>
										<div className='profile-page-profile-row'>
											<div className='profile-page-field-container'>
												<p className='profile-page-field-title'>
													Fornavn
												</p>
												<div className='profile-page-input-field-container'>
													<input
														type='text'
														className='profile-page-input-field'
														value={firstname}
														disabled={true}
														onChange={(e) => {
															this.setState({
																firstname:
																	e.target
																		.value,
															});
														}}
													/>
													{/*
												<button className='profile-page-input-field-update-button'>
													Opdater
												</button>
												*/}
												</div>
											</div>
											<div className='profile-page-field-container'>
												<p className='profile-page-field-title'>
													Efternavn
												</p>
												<div className='profile-page-input-field-container'>
													<input
														type='text'
														className='profile-page-input-field'
														disabled={true}
														value={lastname}
													/>
													{/*
												<button className='profile-page-input-field-update-button'>
													Opdater
												</button>
												*/}
												</div>
											</div>
										</div>
										<div className='profile-page-profile-row'>
											<div className='profile-page-field-container'>
												<p className='profile-page-field-title'>
													Email
												</p>
												<div className='profile-page-input-field-container'>
													<input
														type='text'
														className='profile-page-input-field'
														disabled={true}
														value={email}
													/>
												</div>
											</div>
										</div>

										<div className='profile-page-profile-row'>
											<div className='profile-page-field-container'>
												<p className='profile-page-field-title'>
													Telefonnummer
												</p>
												<div className='profile-page-input-field-container'>
													<input
														type='number'
														className='profile-page-input-field'
														disabled={true}
														value={phoneNumber}
													/>
												</div>
											</div>
										</div>

										<div className='profile-page-profile-row'>
											<div className='profile-page-field-container'>
												<p className='profile-page-field-title'>
													Brugernavn
												</p>
												<div className='profile-page-input-field-container'>
													<input
														type='text'
														className='profile-page-input-field'
														value={username}
														disabled={true}
													/>
												</div>
											</div>
										</div>
										<div className='profile-page-profile-row'>
											<div className='profile-page-field-container'>
												<p className='profile-page-field-title'>
													Fødselsdato
												</p>
												<div className='profile-page-input-field-container'>
													<input
														type='date'
														className='profile-page-input-field'
														value={birthday}
														disabled={true}
													/>
													{/*
												<button className='profile-page-input-field-update-button'>
													Opdater
												</button>
												*/}
												</div>
											</div>
										</div>
										<div className='profile-page-profile-row'>
											<div className='profile-page-field-container'>
												<p className='profile-page-field-title'>
													Vej
												</p>
												<div className='profile-page-input-field-container'>
													<input
														type='text'
														className='profile-page-input-field'
														value={street}
														disabled={true}
													/>
													{/*
												<button className='profile-page-input-field-update-button'>
													Opdater
												</button>
												*/}
												</div>
											</div>
											<div className='profile-page-field-container'>
												<p className='profile-page-field-title'>
													Husnummer
												</p>
												<div className='profile-page-input-field-container'>
													<input
														type='text'
														className='profile-page-input-field'
														value={streetNumber}
														disabled={true}
													/>
													{/*
												<button className='profile-page-input-field-update-button'>
													Opdater
												</button>
												*/}
												</div>
											</div>
										</div>
										<div className='profile-page-profile-row'>
											<div className='profile-page-field-container'>
												<p className='profile-page-field-title'>
													Postnummer
												</p>
												<div className='profile-page-input-field-container'>
													<input
														type='text'
														className='profile-page-input-field'
														value={postalCode}
														disabled={true}
													/>
													{/*
												<button className='profile-page-input-field-update-button'>
													Opdater
												</button>
												*/}
												</div>
											</div>
											<div className='profile-page-field-container'>
												<p className='profile-page-field-title'>
													By
												</p>
												<div className='profile-page-input-field-container'>
													<input
														type='text'
														className='profile-page-input-field'
														value={city}
														disabled={true}
													/>
													{/*
												<button className='profile-page-input-field-update-button'>
													Opdater
												</button>
												*/}
												</div>
											</div>
										</div>
									</div>
								)}
								{this.state.page === 'booking' && (
									<div>
										{this.state.bookings.map(
											(booking, i) => {
												return (
													<div
														key={i}
														className='profile-page-booking-row'
														onClick={() => {
															window.location.href =
																'/booking/' +
																booking.id;
														}}
													>
														<img
															src={utility.getAutocamperImageSize(
																booking
																	.calender_object
																	.autocamper
																	.avatar[0],
																'thumbnail'
															)}
															className='profile-page-booking-autocamper-image'
															alt='Avatar'
														/>
														<p className='profile-page-booking-row-start'>
															Start:{' '}
															{DateUtility.getClock(
																booking
																	.calender_object
																	.start
															)}{' '}
															{booking.calender_object.start.getDate()}
															/
															{booking.calender_object.start.getMonth() +
																1}
															-
															{booking.calender_object.start.getFullYear()}
														</p>
														<p className='profile-page-booking-row-end'>
															Slut:{' '}
															{DateUtility.getClock(
																booking
																	.calender_object
																	.end
															)}{' '}
															{booking.calender_object.end.getDate()}
															/
															{booking.calender_object.end.getMonth() +
																1}
															-
															{booking.calender_object.end.getFullYear()}
														</p>
														<p className='profile-page-booking-row-created'>
															Oprettet:{' '}
															{DateUtility.getClock(
																booking
																	.calender_object
																	.end
															)}{' '}
															{booking.calender_object.end.getDate()}
															/
															{booking.calender_object.end.getMonth() +
																1}
															-
															{booking.calender_object.end.getFullYear()}
														</p>
													</div>
												);
											}
										)}
									</div>
								)}
							</div>
						</div>
					</div>
				</DefaultLayout>
			</React.Fragment>
		);
	}
}

export default ProfilePage;
