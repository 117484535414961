import { useEffect, useState } from 'react';
import OutsideAlerter from '../containers/outsidealerter/outsidealerter';

const ClockSelection = ({
	setClock,
	hide,
	time,
	availableDays,
	isLoading,
	error,
}) => {
	const [index, setIndex] = useState(null);

	useEffect(() => {
		let isMounted = true;
		if (isMounted) setIndex(time.getDate.getDate() - 1);

		return () => {
			isMounted = false;
		};
	}, [time]);

	return (
		<OutsideAlerter clickHandler={hide}>
			<div className='clock-selection-container'>
				{error === true ? (
					<p>Noget gik galt</p>
				) : isLoading ? (
					<>
						<div
							className='clock-select-inner-container'
							id='clock-select-scroll-container'
						></div>
					</>
				) : (
					<div
						className='clock-select-inner-container'
						id='clock-select-scroll-container'
					>
						{index !== null &&
							availableDays[index].map((slot, i) => {
								return (
									<p
										className={
											'clock-selection-time' +
											(time.getTime === slot
												? ' clock-selection-time-selected'
												: '')
										}
										onClick={() => setClock(slot)}
										key={i}
									>
										{`${parseInt(slot / 2)}:${
											slot % 2 === 1 ? `30` : `00`
										}`}
									</p>
								);
							})}
					</div>
				)}
			</div>
		</OutsideAlerter>
	);
};

export default ClockSelection;
