import React, { Component } from 'react';
import './styles.css';

export default class LoadingButton extends Component {
	render() {
		const { text, isLoading, clickHandler, classes } = this.props;

		const svg = 'loading-white.svg';

		const defaultClasses =
			'loading-button ' +
			(isLoading ? ' loading-button-is-loading ' : '');

		return (
			<button
				disabled={isLoading}
				onClick={clickHandler}
				className={defaultClasses + classes}
			>
				{isLoading ? (
					<img
						src={svg}
						className='loading-button-icon'
						alt='loading'
					/>
				) : (
					text
				)}
			</button>
		);
	}
}
