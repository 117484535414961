import React from 'react';
import utility from '../../../utils/utility';
import LoadingContainer from '../../containers/loadingcontainer/loadingcontainer';

const BookAutocamper = ({
	autocamper,
	startTime,
	endTime,
	totalAutocamperPrice,
}) => {
	const ac = autocamper.getObject();
	return (
		<div className='book-autocamper-container'>
			<LoadingContainer requestObject={autocamper}>
				{ac !== null && (
					<div className='book-autocamper-autocamper-container'>
						<div className='book-autocamper-autocamper-details-container'>
							<img
								src={utility.getAutocamperImageSize(
									ac.avatar[0],
									'thumbnail'
								)}
								alt='autocamper'
								className='book-autocamper-autocamper-image'
							/>
							<div>
								<h1 className='book-autocamper-autocamper-name'>
									{ac.name}
								</h1>
								<h2 className='book-autocamper-autocamper-organization'>
									{ac.organization.name}
								</h2>
							</div>
						</div>
						<div className='book-autocamper-date-price-container'>
							<div className='book-autocamper-autocamper-time-interval'>
								<div className='book-autocamper-autocamper-time'>
									<p className='book-autocamper-autocamper-time-title'>
										Fra:{' '}
										<span className='book-autocamper-autocamper-time-value'>
											{startTime.stringClock() +
												' ' +
												startTime.stringDate()}
										</span>
									</p>
									<p className='book-autocamper-autocamper-time-title'>
										Til:{' '}
										<span className='book-autocamper-autocamper-time-value'>
											{endTime.stringClock() +
												' ' +
												endTime.stringDate()}
										</span>
									</p>
								</div>
							</div>
							<div className='book-autocamper-autocamper-time-price-container'>
								<span className='book-autocamper-autocamper-time-price'>
									{(
										ac?.startPrice + totalAutocamperPrice
									).toFixed(2)}{' '}
									DKK
								</span>
							</div>
						</div>
					</div>
				)}
			</LoadingContainer>
		</div>
	);
};

export default BookAutocamper;
