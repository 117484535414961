import React, { useState, useEffect } from 'react';
import Autocamper from '../../autocamper/autocamper';
import DefaultLayout from '../../containers/layout/defaultlayout/defaultlayout';
import { createTheme, Slider } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

import './styles.css';
import TimeSelection from '../../TimeSelection/timeselection';
import TimeObject from '../../../utils/timeobject';
import utility from '../../../utils/utility';
import { Helmet } from 'react-helmet-async';

function AutocampersPage() {
	const [min, setMin] = useState(1);
	const [max, setMax] = useState(2500);
	const [start, setStart] = useState(new TimeObject(null));
	const [end, setEnd] = useState(new TimeObject(null));

	// const [filterParameters, setFilterParameters] = useState({
	// 	min: 1,
	// 	max: 2500,
	// 	start: new TimeObject(null),
	// 	end: new TimeObject(null),
	// });
	const [autocampers, setAutocampers] = useState(null);

	const navigate = useNavigate();
	const search = useLocation().search;

	useEffect(() => {
		let didCancel = false;

		function getQueryParameters() {
			const { start, end } = utility.getParameterTime(search);

			const maxParam = new URLSearchParams(search).get('max');
			const minParam = new URLSearchParams(search).get('min');

			let max = 2500;
			if (
				maxParam !== null &&
				parseInt(maxParam) > 0 &&
				parseInt(maxParam) < 2500
			)
				max = parseInt(maxParam);

			let min = 0;
			if (
				min !== null &&
				parseInt(minParam) <= parseInt(maxParam) &&
				parseInt(minParam) > 0 &&
				parseInt(minParam) <= 2500
			)
				min = parseInt(minParam);

			const parameters = {
				start,
				end,
				min,
				max,
			};

			setStart(start);
			setEnd(end);
			setMin(min);
			setMax(max);
			return parameters;
		}

		async function fetchAutocamper() {
			// Fetching autocamper.
			try {
				// Retrieving get paramters and setting filterParameters.
				const parameters = getQueryParameters();
				let param = {
					min: parameters.min,
					max: parameters.max,
				};

				if (parameters.start.isSelected()) {
					param.start = parameters.start.unix;
				}
				if (parameters.end.isSelected()) {
					param.end = parameters.end.unix;
				}

				const qs = require('qs');
				const query = qs.stringify(param, {
					encodeValuesOnly: true,
				});

				const response = await axios.get(
					process.env.REACT_APP_API_URL +
						`/search/autocampers?${query}`
				);

				if (!didCancel) {
					if (response.status === 200) {
						setAutocampers(response.data);
					}
				}
			} catch (error) {}
		}

		fetchAutocamper();
		return () => {
			didCancel = true;
		};
	}, [search]);

	function updateStartTime(day) {
		if (day instanceof Date) {
			setStart(new TimeObject(day, 20));
		}
	}

	function updateStartClock(clock) {
		if (Number.isInteger(clock)) {
			setStart(new TimeObject(start.getDate, clock));
		}
	}

	function updateEndTime(day) {
		if (day instanceof Date) {
			setEnd(new TimeObject(day, 20));
		}
	}

	function updateEndClock(clock) {
		if (Number.isInteger(clock)) {
			setEnd(new TimeObject(end.getDate, clock));
		}
	}

	function resetStart() {
		setStart(new TimeObject(null));
	}

	function resetEnd() {
		setEnd(new TimeObject(null));
	}

	/**
	 * Redirect to same page with new parameters.
	 */
	function searchAutocampers() {
		let parameters = `?max=${max}&min=${min}`;

		if (start.isSelected()) {
			parameters += `&start=${start.getDate.getTime()}&startTime=${
				start.getTime
			}`;
		}

		if (end.isSelected()) {
			parameters += `&end=${end.getDate.getTime()}&endTime=${
				end.getTime
			}`;
		}

		navigate('/autocamper' + parameters);
	}

	function setPriceRange(values) {
		setMin(values[0]);
		setMax(values[1]);
	}

	const theme = createTheme({
		palette: {
			primary: {
				main: '#2d7163',
			},
			secondary: {
				main: '#99cea3',
			},
		},
	});

	return (
		<React.Fragment>
			<Helmet>
				<title>Autocampere</title>
				<meta
					name='description'
					content='Find den perfekte autocamper til dig, så du kan komme afsted'
				/>
			</Helmet>

			<meta
				name='description'
				content='Vil du på autocampere ferie, så kom ind og tjek vores fine udvalg af autocampere, og leje dem lige hvornår du har lyst'
			/>
			<DefaultLayout>
				<div className='responsive-container'>
					<div className='autocampers-layout-container'>
						<div className='autocampers-search-container'>
							<div className='autocampers-search-item-container'>
								<div className='autocampers-search-filter-container'>
									<div className='autocamper-search-time-start-container'>
										<TimeSelection
											setValue={updateStartTime}
											setClock={updateStartClock}
											text={'Startdato'}
											value={start}
											max={end}
											other={end}
											isMin={true}
											resetOther={resetEnd}
										/>
									</div>
									<div className='filter-divider'></div>
									<div className='autocamper-search-time-end-container'>
										<TimeSelection
											setValue={updateEndTime}
											setClock={updateEndClock}
											text={'Slutdato'}
											value={end}
											min={start}
											other={start}
											isMin={false}
											resetOther={resetStart}
										/>
									</div>
									<div className='filter-divider'></div>
									<div className='price-range-container'>
										<p className='price-value price-value-min'>
											{min}
											<br />
											<span className='price-value-unit'>
												DKK
											</span>
										</p>
										<Slider
											getAriaLabel={() => 'Pris'}
											max={2500}
											min={1}
											value={[min, max]}
											theme={theme}
											onChange={(e) => {
												setPriceRange(e.target.value);
											}}
										/>
										<p className='price-value price-value-max'>
											{max}
											<br />
											<span className='price-value-unit'>
												DKK
											</span>
										</p>
									</div>
									<div className='filter-divider'></div>
									<div className='autocampers-search-button-container'>
										<button
											className='autocampers-search-button'
											onClick={searchAutocampers}
										>
											SØG
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className='autocampers-container'>
							<div className='autocampers-items-container autocampers-items-container-top'>
								{autocampers &&
									autocampers.valid &&
									autocampers.valid.map((autocamper) => (
										<Autocamper
											key={autocamper.id}
											autocamper={autocamper}
											start={start}
											end={end}
										/>
									))}
							</div>
							{autocampers !== null &&
								autocampers.invalid.length > 0 && (
									<React.Fragment>
										<div className='other-autocampers-items-container'>
											<h1>Andre Autocampere</h1>
											<p>
												Autocampere der ikke ramte din
												søgning
											</p>
										</div>
										<div className='autocampers-items-container'>
											{autocampers &&
												autocampers.invalid &&
												autocampers.invalid.map(
													(autocamper) => (
														<Autocamper
															key={autocamper.id}
															autocamper={
																autocamper
															}
														/>
													)
												)}
										</div>
									</React.Fragment>
								)}
						</div>
					</div>
				</div>
			</DefaultLayout>
		</React.Fragment>
	);
}

export default AutocampersPage;
