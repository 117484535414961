import LoadingContainer from '../../containers/loadingcontainer/loadingcontainer';
import AddonItem from './addonitem';

const AddonList = ({ autocamper, addons, toggleAddonState }) => {
	return (
		<div className='book-addon-container'>
			<LoadingContainer requestObject={autocamper}>
				<div className='book-addon-title-container'>
					<h1 className='book-addon-title'>Tilkøb</h1>
				</div>
				<div className='book-addon-layout-container'>
					{addons !== null && addons.length > 0 ? (
						addons.map((addon, i) => {
							return (
								<AddonItem
									addon={addon}
									index={i}
									key={i}
									toggleState={toggleAddonState}
								/>
							);
						})
					) : (
						<p>Ingen tilkøb til denne autocamper</p>
					)}
				</div>
			</LoadingContainer>
		</div>
	);
};

export default AddonList;
