import { useState } from 'react';
import utility from '../../../utils/utility';
import LoadingContainer from '../../containers/loadingcontainer/loadingcontainer';

const BookContainer = ({
	autocamper,
	totalAutocamperPrice,
	addons,
	paymentMethod,
	stateErrors,
	setPaymentMethod,
	daysTillBooking,
	paymentRate,
	setPaymentRate,
	termsOfTrade,
	policy,
	setPolicy,
	startTime,
	endTime,
	bookingError,
	setTermsOfTrade,
	validateForBooking,
	showStateErrors,
	bookAutocamper,
	accountNumber,
	setAccountNumber,
	registrationNumber,
	setRegistrationNumber,
}) => {
	const [displayAccountInformation, setDisplayAccountInformation] =
		useState(false);

	const ac = autocamper.getObject();
	const servicePrice =
		autocamper.getStatus() === 1
			? (totalAutocamperPrice + ac.startPrice) * 0.15
			: 0;

	const calcTotalPrice = () => {
		let price = parseFloat(totalAutocamperPrice);
		price += ac.organization.depositPrice; // Deposit.
		price += ac.startPrice;
		price += servicePrice;

		if (addons !== null) {
			addons.forEach((addon) => {
				if (addon.state === true) {
					price +=
						addon.addon.perDay === true
							? addon.addon.price *
							  utility.calcNumberOfDays(startTime, endTime)
							: addon.addon.price;
				}
			});
		}

		price.toFixed(2);
		return price;
	};

	return (
		<div className='book-payment-inner-container'>
			<div className='book-payment-items-container'>
				<LoadingContainer requestObject={autocamper}>
					<div className='book-payment-item'>
						<div className='book-payment-item-field'>
							<p className='book-payment-item-name'>Depositum</p>
						</div>
						<div className='book-payment-item-field'>
							<p className='book-payment-item-price'>
								{autocamper.getStatus() === 1 &&
									ac.organization.depositPrice}
								{' DKK'}
							</p>
						</div>
					</div>
					<div className='book-payment-item'>
						<div className='book-payment-item-field'>
							<p className='book-payment-item-name'>
								Leje af autocamper
							</p>
						</div>
						<div className='book-payment-item-field'>
							<p className='book-payment-item-price'>
								{autocamper.getStatus() === 1 &&
									(
										ac.startPrice + totalAutocamperPrice
									).toFixed(2)}{' '}
								DKK
							</p>
						</div>
					</div>
					<div className='book-payment-item'>
						<div className='book-payment-item-field'>
							<p className='book-payment-item-name'>
								Servicegebyrer
							</p>
						</div>
						<div className='book-payment-item-field'>
							<p className='book-payment-item-price'>
								{autocamper.getStatus() === 1 &&
									servicePrice.toFixed(2)}{' '}
								DKK
							</p>
						</div>
					</div>
					{addons !== null &&
						addons.map((addon, i) => {
							return (
								addon.state === true && (
									<div className='book-payment-item' key={i}>
										<div className='book-payment-item-field'>
											<p className='book-payment-item-name'>
												{addon.addon.name}
											</p>
										</div>
										<div className='book-payment-item-field'>
											<p className='book-payment-item-price'>
												{addon.addon.perDay === true
													? addon.addon.price *
													  utility.calcNumberOfDays(
															startTime,
															endTime
													  )
													: addon.addon.price}
												{' DKK'}
											</p>
										</div>
									</div>
								)
							);
						})}
				</LoadingContainer>
			</div>
			<div className='book-payment-method-container'>
				<div
					className={
						'book-payment-method-item' +
						(paymentMethod === 'banktransfer'
							? ' book-payment-method-item-active'
							: '') +
						(stateErrors !== null && stateErrors.payment !== true
							? ' book-payment-method-item-error'
							: '')
					}
					onClick={() => setPaymentMethod('banktransfer')}
				>
					<img
						src='/banktransfer.svg'
						className='book-payment-method-icon'
						alt='banktransfer'
					/>
					<p className='book-payment-method-text'>Bankoverførsel</p>
				</div>
			</div>
			<div
				className={
					'book-payment-select-payment-container' +
					(stateErrors !== null && stateErrors.rate !== true
						? ' book-payment-select-payment-container-error'
						: '')
				}
			>
				<div
					className='book-payment-select-payment-item'
					onClick={() => setPaymentRate(1)}
				>
					<div
						className={
							'book-payment-select-payment-indicator' +
							(paymentRate === 1
								? ' book-payment-select-payment-indicator-selected'
								: '')
						}
					></div>
					<p className='book-payment-select-payment-text'>
						Betal hele beløbet samlet.
					</p>
				</div>
				{daysTillBooking >= 55 && (
					<div
						className='book-payment-select-payment-item'
						onClick={() => setPaymentRate(2)}
					>
						<div
							className={
								'book-payment-select-payment-indicator' +
								(paymentRate === 2
									? ' book-payment-select-payment-indicator-selected'
									: '')
							}
						></div>
						<p className='book-payment-select-payment-text'>
							Betal beløbet over 2 rater.
						</p>
					</div>
				)}
			</div>
			<div className='book-payment-account-container'>
				<LoadingContainer requestObject={autocamper} showErrors={false}>
					<div className='book-payment-account-inner-container'>
						<div className='book-payment-account-list'>
							<div className='book-payment-account-item'>
								<label htmlFor='book-payment-account-number'>
									Kontonr.
								</label>
								<input
									id='book-payment-account-number'
									type='number'
									className={
										stateErrors !== null &&
										stateErrors.accountNumber !== true
											? 'book-payment-account-input book-payment-account-input-error'
											: 'book-payment-account-input'
									}
									onWheel={(e) => e.target.blur()}
									placeholder={'Kontonummer'}
									value={accountNumber}
									onChange={(e) =>
										setAccountNumber(
											e.target.value === ''
												? ''
												: parseInt(e.target.value)
										)
									}
								/>
							</div>
							<div className='book-payment-account-item'>
								<label htmlFor='book-payment-account-number'>
									Reg.nr.
								</label>
								<input
									id='book-payment-account-number'
									type='number'
									className={
										stateErrors !== null &&
										stateErrors.registrationNumber !== true
											? 'book-payment-account-input book-payment-account-input-error'
											: 'book-payment-account-input'
									}
									onWheel={(e) => e.target.blur()}
									placeholder={'Registreringsnummer'}
									value={registrationNumber}
									onChange={(e) =>
										setRegistrationNumber(
											e.target.value === ''
												? ''
												: parseInt(e.target.value)
										)
									}
								/>
							</div>
						</div>
						<div className='book-payment-account-information-container'>
							<div className='book-payment-account-information-relative'>
								<button
									className='book-payment-account-information-button'
									onClick={() =>
										setDisplayAccountInformation(
											!displayAccountInformation
										)
									}
								>
									?
								</button>
								{displayAccountInformation && (
									<div className='book-payment-account-information'>
										<p>
											Kontonr. og reg.nr bruges til
											tilbagebetale af resten af
											depositummet.
										</p>
									</div>
								)}
							</div>
						</div>
					</div>
				</LoadingContainer>
			</div>
			<div className='book-payment-footer'>
				<div className='book-payment-total-price-container'>
					<p className='book-payment-total-price-text'>Total:</p>
					<div className='book-payment-price-container'>
						<LoadingContainer
							requestObject={autocamper}
							showErrors={false}
						>
							<span className='book-payment-total-price'>
								{autocamper.getStatus() === 1 &&
									calcTotalPrice().toFixed(2)}
								{' DKK'}
							</span>
						</LoadingContainer>
					</div>
				</div>
				<div className='book-payment-button-container'>
					<LoadingContainer
						requestObject={autocamper}
						showErrors={false}
					>
						<button
							className={
								validateForBooking() === true
									? 'book-payment-button-styling book-payment-button'
									: 'book-payment-button-styling book-payment-disabled-button'
							}
							onClick={(e) => {
								e.preventDefault();
								showStateErrors();
								if (validateForBooking() === true) {
									bookAutocamper();
								}
							}}
						>
							Book
						</button>
					</LoadingContainer>
				</div>
			</div>
			{bookingError !== null && (
				<div className='book-error-container'>
					<p className='book-error-text'>
						Noget gik galt med bookingen.
					</p>
				</div>
			)}
			<div className='book-payment-requirements-container'>
				<div
					className={
						'book-payment-requirement' +
						(stateErrors !== null &&
						stateErrors.termsOfTrade !== true
							? ' book-payment-requirement-error'
							: '')
					}
					onClick={() => setTermsOfTrade(!termsOfTrade)}
				>
					<div
						className={
							termsOfTrade === true
								? ' book-payment-requirement-checkbox-active'
								: 'book-payment-requirement-checkbox'
						}
					></div>
					<div className='book-payment-requirement-text'>
						Jeg har læst og accepterer handelsbetingelserne.
					</div>
				</div>
				<div
					className={
						'book-payment-requirement' +
						(stateErrors !== null && stateErrors.policy !== true
							? ' book-payment-requirement-error'
							: '')
					}
					onClick={() => setPolicy(!policy)}
				>
					<div
						className={
							policy === true
								? ' book-payment-requirement-checkbox-active'
								: 'book-payment-requirement-checkbox'
						}
					></div>
					<div className='book-payment-requirement-text'>
						Jeg bekræfter, at mine oplysninger er korrekt, at jeg er
						mindst 18 år og har læst og accepteret
						lejebetingelserne.
					</div>
				</div>
			</div>
		</div>
	);
};

export default BookContainer;
