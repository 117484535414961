import axios from 'axios';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import auth from '../../../utils/auth';
import DefaultLayout from '../../containers/layout/defaultlayout/defaultlayout';
import LoginContainer from './logincontainer';
import './styles.css';

const LoginPage = () => {
	const [loginUsername, setLoginUsername] = useState('');
	const [loginPassword, setLoginPassword] = useState('');
	const [signingIn, setSigningIn] = useState(false);
	const [loginError, setLoginError] = useState('');

	const navigate = useNavigate();
	const location = useLocation();

	const returnToPreviousPage = () => {
		let state = { ...location?.state };
		navigate(location?.state?.from ? location?.state?.from : '/', {
			state: state,
		});
	};

	const signIn = async () => {
		if (signingIn === false) {
			setSigningIn(true);
			try {
				const response = await axios.post(
					process.env.REACT_APP_API_URL + '/auth/local',
					{
						identifier: loginUsername,
						password: loginPassword,
					}
				);

				if (response?.status === 200) {
					auth.setToken(response.data.jwt, false);
					auth.setUserInfo(response.data.user, false);
					returnToPreviousPage();
				}
			} catch (error) {
				setSigningIn(false);
				if (error?.response?.status === 400) {
					setLoginError(
						'Brugernavnet/email eller adgangskoden er forkert.'
					);
				} else {
					setLoginError('Noget gik galt.');
				}
			}
		}
	};

	const signInValues = {
		loginUsername,
		setLoginUsername,
		loginPassword,
		setLoginPassword,
		signingIn,
		setSigningIn,
		signIn,
		loginError,
		setLoginError,
	};

	return (
		<>
			<Helmet>
				<title>Log ind</title>
				<meta
					name='description'
					content='Log ind på Andreas Udlejning'
				/>
				<meta
					name='keywords'
					content='Log ind, sign in, sign-in, profil, konto, leje, udlejning'
				/>
			</Helmet>
			<DefaultLayout>
				<LoginContainer signIn={signInValues} />
			</DefaultLayout>
		</>
	);
};

export default LoginPage;
