import { useEffect } from 'react';
import { useRef } from 'react';
import { Link } from 'react-router-dom';

const AcDetail = ({ autocamper, scrollToCalender, setButtonRef }) => {
	const { organization } = autocamper;

	const buttonRef = useRef();

	useEffect(() => {
		setButtonRef(buttonRef);
	}, [setButtonRef, buttonRef]);

	return (
		<section className='ac-page-detail-container ac-page-container'>
			<div className='ac-page-detail-header'>
				<h1 className='ac-page-autocamper-title'>
					{autocamper?.name}
					{autocamper?.year && (
						<>
							-{' '}
							<span className='ac-page-autocamper-year'>
								{autocamper?.year}
							</span>
						</>
					)}
				</h1>
				<div className='ac-page-subtitle'>
					{autocamper?.rating && (
						<>
							<img
								src='/rating.svg'
								className='ac-page-subtitle-rating'
								alt=''
							/>
							<span>|</span>
						</>
					)}

					<Link to='' className='ac-page-subtitle-organization'>
						{organization?.name}
					</Link>
				</div>
				<div className='ac-page-detail-book'>
					<div className='ac-page-detail-book-price-container'>
						<span className='ac-page-detail-book-price'>
							{autocamper?.price}
						</span>
						<span>DKK/DAG</span>
					</div>
					<button
						className='ac-page-detail-book-button'
						onClick={() => scrollToCalender()}
						ref={buttonRef}
					>
						Book nu
					</button>
				</div>
			</div>
			<div className='ac-page-detail-line' />
			<div className='ac-page-detail-body'>
				<div>
					<h2 className='ac-page-detail-title'>Beskrivelse</h2>
					<p className='ac-page-detail-description'>
						{autocamper?.description}
					</p>
				</div>
				<div>
					<h2 className='ac-page-detail-title'>Detaljer</h2>
					<div className='ac-page-detail-tags-overflow'>
						{autocamper?.autocamper_tags?.length === 0 ? (
							<div>
								<p>Ingen detaljer.</p>
							</div>
						) : (
							<div className='ac-page-detail-tags-container'>
								{autocamper?.autocamper_tags?.map((tag, i) => {
									return (
										<div
											className='ac-page-detail-tag-wrapper'
											key={i}
										>
											<div className='ac-page-detail-tag'>
												<p className='ac-page-detail-tag-title'>
													{tag?.name}
												</p>
												<p>{tag?.value}</p>
											</div>
										</div>
									);
								})}
							</div>
						)}
					</div>
				</div>
			</div>
		</section>
	);
};

export default AcDetail;
