import React from 'react';
import OutsideAlerter from '../../containers/outsidealerter/outsidealerter';

const SignupInput = ({
	input,
	errors,
	signupValues,
	updateSignupValues,
	updateDisplayHelp,
	displayHelp,
}) => {
	const updateSignupValue = (value) => {
		const newSignupValues = { ...signupValues };
		newSignupValues[input.parameter] = value;
		updateSignupValues(newSignupValues);
	};

	return (
		<div className='signup-input-container'>
			<div className='signup-input-field-container'>
				<label
					htmlFor={`signup-input-${input.label}`}
					className='signup-input-label'
				>
					{input.label}
				</label>
				<div className='signup-input-field-alignment-container'>
					<input
						type={input.type === 'password' ? 'password' : 'text'}
						id={`signup-input-${input.label}`}
						className='signup-input-field'
						value={signupValues[input.parameter]}
						onChange={(e) => updateSignupValue(e.target.value)}
						autoComplete='off'
						placeholder={input?.placeholder}
					/>
					<div className='signup-input-help-button-container'>
						<div
							className='signup-input-help-button'
							onClick={() => updateDisplayHelp(input.parameter)}
						>
							<p>?</p>
						</div>
						{displayHelp === input.parameter && (
							<div className='signup-input-help-container'>
								<OutsideAlerter
									clickHandler={() =>
										updateDisplayHelp(input.parameter)
									}
								>
									<p className='signup-input-help-text'>
										{input.help}
									</p>
								</OutsideAlerter>
							</div>
						)}
					</div>
				</div>
				<div>
					<p className='signup-input-field-error-text'>
						{errors[input.parameter]}
					</p>
				</div>
			</div>
		</div>
	);
};

export default SignupInput;
