import React, { useState, useRef } from 'react';

import './styles.css';
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from './dropdown';

function Header() {
	const [profileDropdown, setProfileDropdown] = useState(false);

	function hideProfileDropdown(event) {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setProfileDropdown(false);
		}
	}

	const wrapperRef = useRef(null);
	const navigate = useNavigate();

	const headerStyle = {
		backgroundImage: `url("/wave2.svg")`,
	};

	return (
		<div className='layout-header-container' style={headerStyle}>
			<div className='layout-header-grid responsive-container'>
				<div className='layout-header-logo-container'>
					<Link to='/' className='header-logo-href'>
						<img
							src='/small-logo.png'
							className='header-logo'
							alt='Andreas Udlejning'
						/>
					</Link>
				</div>
				<div className='layout-header-links-container'>
					<div className='layout-header-links'>
						<Link to='/autocamper' className='header-link-href'>
							<span className='header-link-span'>
								AUTOCAMPERE
							</span>
						</Link>
					</div>
				</div>
				<div className='layout-header-functions-container'>
					<div className='layout-header-functions-right'>
						<Link
							to='/udlejer'
							className='landlord-header-button-href'
						>
							<span className='landlord-header-button'>
								Bliv udlejer
							</span>
						</Link>
						<Link to='/autocamper' className='header-link-href'>
							<span className='autocamper-header-button'>
								Autocampere
							</span>
						</Link>
						<div
							className='profile-header-button'
							onClick={() => {
								if (!profileDropdown) setProfileDropdown(true);
							}}
						>
							<img
								src='/burger-black.svg'
								className='burger-header-icon'
								alt='burger'
							/>
							<img
								src='/profile-black.svg'
								className='profile-header-icon'
								alt='profile'
							/>
						</div>
						{profileDropdown && (
							<Dropdown
								hideProfileDropdown={hideProfileDropdown}
								wrapperRef={wrapperRef}
								navigate={navigate}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Header;
