import React, { Component } from 'react';
import Footer from './footer';
import Header from './header';

class DefaultLayout extends Component {
	state = {};

	render() {
		const { children } = this.props;

		return (
			<React.Fragment>
				<div className='layout-header'>
					<Header />
				</div>
				<div className='layout-body'>{children}</div>
				<div className='layout-footer'>
					<Footer />
				</div>

				<title>Andreas Udlejning</title>
			</React.Fragment>
		);
	}
}

export default DefaultLayout;
