import TimeObject from './timeobject';

const DateUtility = {
	/**
	 * @param {Date} date
	 * @returns The first day in the month.
	 */
	startOfMonthDay(date) {
		return new Date(
			Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)
		);
	},

	/**
	 * @param {*} date1
	 * @param {*} date2
	 * @returns True if the two dates given are on the same UTC date.
	 */
	isSameDate(date1, date2) {
		return (
			date1.getUTCFullYear() === date2.getUTCFullYear() &&
			date1.getUTCMonth() === date2.getUTCMonth() &&
			date1.getUTCDate() === date2.getUTCDate()
		);
	},

	dateLessThan(date1, date2) {
		const d1 = new Date(
			Date.UTC(
				date1.getUTCFullYear(),
				date1.getUTCMonth(),
				date1.getUTCDate()
			)
		);
		const d2 = new Date(
			Date.UTC(
				date2.getUTCFullYear(),
				date2.getUTCMonth(),
				date2.getUTCDate()
			)
		);
		return d1 < d2;
	},

	/**
	 * Creates array of Dates containing all of the days in the month in ascending order.
	 * The selected month is the date's month in UTC.
	 * @param {Date} date
	 * @returns Array[Date] for all the days in the month with UTC time at 00:00.
	 */
	getAllDaysInMonth(date) {
		const startOfMonth = this.startOfMonthDay(date);
		const daysInMonth = new Date(
			Date.UTC(
				startOfMonth.getUTCFullYear(),
				startOfMonth.getUTCMonth() + 1,
				0
			)
		).getUTCDate();

		const days = [];

		for (let i = 1; i <= daysInMonth; i++) {
			days.push(
				new Date(
					Date.UTC(
						startOfMonth.getUTCFullYear(),
						startOfMonth.getUTCMonth(),
						i
					)
				)
			);
		}

		return days;
	},

	/**
	 * Returns the week number of the date.
	 *
	 * @param {Date} d
	 */
	getWeekNumber(d) {
		d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
		d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
		var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
		var weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
		return weekNo;
	},

	getWeekday(d) {
		return (d.getDay() + 6) % 7;
	},

	/**
	 * Returns the clock of date. Precondiation that d is a valid Date.
	 *
	 * @param {Date} d
	 */
	getClock(d) {
		const hour =
			d.getUTCHours() < 10
				? '0' + d.getUTCHours().toString()
				: d.getUTCHours();
		const minutes =
			Math.floor(d.getUTCMinutes() / 30) * 30 === 30 ? '30' : '00';

		return hour + ':' + minutes;
	},

	calculateNumberOfDays(start, end) {
		return Math.floor((end.getTime() - start.getTime()) / 86400000) + 1;
	},

	createTimeObjectFromResponse(string) {
		const date = new Date(parseInt(string));

		return new TimeObject(
			new Date(date.getFullYear(), date.getMonth(), date.getDate()),
			date.getHours() * 2 + (date.getMinutes() === 30 ? 1 : 0)
		);
	},
};

export default DateUtility;
