import { Link } from 'react-router-dom';

const AcPolicy = () => {
	return (
		<section className='ac-page-policy-container'>
			<Link to='/lejebetingelser' className='ac-page-policy-link'>
				Lejebetingelser
			</Link>
		</section>
	);
};

export default AcPolicy;
