import { useLoadScript } from '@react-google-maps/api';
import AcMap from './acmap';

const libraries = ['places'];

const AcLocation = ({ autocamper }) => {
	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GMAPS,
		libraries: libraries,
	});

	const { address } = autocamper?.organization;

	const stringAddress = `${address?.street} ${address?.streetNumber}, ${address?.postalCode} ${address?.city}`;

	return (
		address !== null &&
		address !== undefined && (
			<section className='ac-page-location-container ac-page-container'>
				<h2 className='ac-page-detail-title'>Lokation</h2>
				<p className='ac-page-location-text'>{stringAddress}</p>
				{isLoaded && <AcMap address={stringAddress} />}
			</section>
		)
	);
};

export default AcLocation;
