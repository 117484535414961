const AVAILABLE = 0;
const UNAVAILABLE = 1;
const ONLY_START = 2;
const ONLY_END = 3;
const START_AND_END = 4;
const START_OR_END = 5;
const BETWEEN = 6;
const BETWEEN_START = 7;
const BETWEEN_END = 8;

const getCalenderDayClass = (booked, isStartDay, style) => {
	let className = 'calender-booking-day';

	if (booked === UNAVAILABLE) {
		className += ' calender-booking-day-blocked';
	} else if (isStartDay) {
		className += ' calender-booking-day-start';
	}

	if (booked === ONLY_START) {
		style.backgroundImage = 'url("/calender/start.svg")';
	} else if (booked === ONLY_END) {
		style.backgroundImage = 'url("/calender/end.svg")';
	} else if (booked === START_AND_END) {
		style.backgroundImage = 'url("/calender/gap.svg")';
	} else if (booked === START_OR_END) {
		style.backgroundImage = 'url("/calender/split.svg")';
	} else if (booked === BETWEEN) {
		style.backgroundImage = 'url("/calender/between.svg")';
	} else if (booked === BETWEEN_START) {
		style.backgroundImage = 'url("/calender/between-start.svg")';
	} else if (booked === BETWEEN_END) {
		style.backgroundImage = 'url("/calender/between-end.svg")';
	} else if (booked === AVAILABLE) {
		className += ' calender-booking-day-available';
	}

	return className;
};

const CalenderDay = ({ date }) => {
	const { day, booked, isStartDay } = date;

	let style = {};
	if (day.getUTCDate() === 1) {
		style.gridColumn = day.getUTCDay() === 0 ? 7 : day.getUTCDay();
	}

	const className = getCalenderDayClass(booked, isStartDay, style);

	style.backgroundSize = 'cover';

	return (
		<div className={className} style={style}>
			{day.getDate()}
		</div>
	);
};

export default CalenderDay;
