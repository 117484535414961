class TimeObject {
	#date = null; // Date object of the selected date.
	#time = -1; // Number of half hours in the selected time of the date.

	/**
	 *
	 * @param {Date} date The date.
	 * @param {Number} time Time of the day where the value corresponds to amount of minutes in the day (Range: [0-47[).
	 */
	constructor(date, time = -1) {
		if (date instanceof Date) {
			this.#date = date;
		}

		if (Number.isInteger(time)) {
			this.#time = time;
		}
	}

	/**
	 * Sets the date.
	 *
	 * @param {Date} date
	 */
	setDate(date) {
		if (date instanceof Date) this.#date = date;
	}

	/**
	 * Sets the time of the date.
	 *
	 * @param {Number} time
	 */
	setTime(time) {
		if (Number.isInteger(time)) this.#time = time;
	}

	dateSelected() {
		return this.#date !== null;
	}

	/**
	 * Returns true if a date and time is selected.s
	 */
	isSelected() {
		return this.#date !== null && this.#time !== -1;
	}

	/**
	 * Returns the selected date object fromt the
	 *
	 * @return Date
	 */
	getSelectedDate() {
		// Should probably be replaced with getSelectedUTC().
		throw new Error('Not Implemented Correctly');
		// return this.#date !== null
		// 	? new Date(
		// 			this.#date.getFullYear(),
		// 			this.#date.getMonth(),
		// 			this.#date.getDate(),
		// 			0,
		// 			this.#time * 30
		// 	  )
		// 	: null;
	}

	/**
	 * Returns just the UTC Date without the clock.
	 *
	 * @returns
	 */
	getSelectedDateUTC() {
		return this.dateSelected()
			? new Date(
					Date.UTC(
						this.#date.getUTCFullYear(),
						this.#date.getUTCMonth(),
						this.#date.getUTCDate()
					)
			  )
			: null;
	}

	/**
	 * Returns the complete UTC time.
	 *
	 * @returns
	 */
	getSelectedUTC() {
		return this.isSelected()
			? new Date(
					Date.UTC(
						this.#date.getFullYear(),
						this.#date.getMonth(),
						this.#date.getDate(),
						0,
						this.#time * 30
					)
			  )
			: null;
	}

	stringDate() {
		return this.dateSelected()
			? this.#date.getDate() +
					'/' +
					(this.#date.getUTCMonth() + 1) +
					'-' +
					this.#date.getUTCFullYear()
			: 'Invalid date';
	}

	stringClock() {
		let hours = Math.floor(this.#time / 2).toString();
		if (hours.length < 2) hours = '0' + hours;
		const minutes = this.#time % 2 === 0 ? '00' : '30';
		return hours + ':' + minutes;
	}

	/**
	 * Returns the selected date.
	 *
	 * @return Date
	 */
	get getDate() {
		return this.#date;
	}

	/**
	 * Returns the number of half hours used within the date.
	 *
	 * @return Number
	 */
	get getTime() {
		return this.#time;
	}

	/**
	 * Should return the unix time in UTC.
	 */
	get unix() {
		return this.getSelectedUTC().getTime();
	}
}

export default TimeObject;
