import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import DefaultLayout from '../../containers/layout/defaultlayout/defaultlayout';
import './../rentelconditionpage/styles.css';

export default class PrivacyPolicyPage extends Component {
	render() {
		return (
			<>
				<Helmet>
					<title>Politik</title>
					<meta name='robots' content='noindex' />
				</Helmet>
				<DefaultLayout>
					<div className='policy-layout-container responsive-container'>
						<div className='policy-title-container'>
							<h1 className='policy-title'>
								Persondatapolitik, Cookie- og privatlivspolitik
							</h1>
							<h2 className='policy-subtitle'>
								Herunder kan du læse vores politik på siden
							</h2>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Persondatapolitik hos Andreas Udlejning ApS
							</h1>
							<p className='policy-section-text'>
								Vi indhenter kun persondata i de tilfælde, hvor
								dette skulle være relevant for os, og vi vil kun
								indhente persondata, hvis det er relevant for
								din aktivitet hos Andreas Udlejning ApS. Ved
								indsamling, behandling og anvendelse af dine
								persondata overholder vi altid alle relevante
								lovbestemmelser. Vi vil kun opbevare dine
								persondata, så længe vi enten er pålagt en
								juridisk forpligtelse hertil, eller så længe det
								er relevant for den hensigt, hvormed de blev
								indsamlet.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Oplysninger vi indsamler
							</h1>
							<p className='policy-section-text'>
								Hvis du ønsker at købe og modtage et produkt
								eller en ydelse fra os, har vi brug for at
								indsamle visse persondata for at kunne
								gennemføre handlen og for at kunne tilbyde dig
								vores services. Vi kan bruge cookies til at
								holde styr på indholdet i din indkøbskurv, mens
								du bruger vores webshop. Vi kan indhente
								oplysninger som f.eks. navn, e-mailadresse,
								postadresse, leveringsadresse (hvis den varierer
								fra postadresse), telefonnummer og
								betalingsoplysninger. Andreas Udlejning ApS
								indsamler og behandler dine persondata, når du
								foretager dig følgende: <br />– Besøger vores
								hjemmeside <br />– Gennemfører et køb af vores
								produkter <br />– Tilmelder dig vores nyhedsbrev{' '}
								<br />– Sender os spørgsmål, reklamationer eller
								feedback
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Den dataansvarlige
							</h1>
							<p className='policy-section-text'>
								Den dataansvarlige for indsamling, behandling og
								anvendelse af dine personoplysninger på
								https://andreasudlejning.dk/ er Andreas
								Udlejning ApS, Hundstrupvej 60, 5771 Stenstrup,
								cvr nr. 41929553
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Behandlingsgrundlag og formål
							</h1>
							<p className='policy-section-text'>
								Dine almindelige kontaktoplysninger som navn og
								adresse indhenter vi for at kunne levere det
								produkt eller den ydelse, som du har købt hos
								os. Din e-mailadresse indhenter vi for at kunne
								sende dig en ordrebekræftelse samt en
								leveringsbekræftelse. Når du betaler for dit
								produkt eller din ydelse indsamler vi dit navn,
								dine kortdata og IP-adresse. De oplysninger, der
								indsamles i forbindelse med
								betalingstransaktionen, anvendes og gemmes kun
								til betalingsafvikling og opfyldelse af den
								indgåede aftale. Ved gennemførelse af
								betalinger, vil nogle af dine data blive
								videregivet til Pensopay, inklusiv information
								der er nødvendig for at gennemføre eller
								understøtte betalingen, såsom det totale
								købsbeløb og faktureringsinformation. Hvis du
								under registreringsprocessen eller på vores
								hjemmeside har givet os dit samtykke hertil,
								bruger vi din e-mailadresse til udsendelse af et
								nyhedsbrev. Du kan til enhver tid til- og
								afmelde dig nyhedsbrevet. Alle nyhedsbreve
								indeholder også et link til afmelding. Hvis
								behandling af persondata er baseret på dit
								samtykke, har du til enhver tid ret til at
								tilbagekalde samtykket. Hvis du ikke ønsker at
								opgive de persondata, som er påkrævet for at
								gennemføre et køb af et produkt, vil du desværre
								ikke have mulighed for at købe produkter af os
								på vores hjemmeside. Dine oplysninger (undtagen
								din e-mailadresse, hvis du har givet samtykke
								til behandling heraf med henblik på modtagelse
								af tilbud) vil blive slettet 5 år efter, at du
								har modtaget din vare eller ydelse.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Andre modtagere af personoplysninger
							</h1>
							<p className='policy-section-text'>
								Vi sælger ikke dine persondata til tredjemand,
								og vi overfører ikke dine persondata til
								tredjelande. <br />
								Vi anvender eksterne virksomheder som
								leverandører for at levere vores ydelser bedst
								muligt. Disse eksterne leverandører er
								databehandlere og behandler i visse tilfælde
								personoplysninger i forbindelse med deres
								levering af ydelser til os. Vores databehandlere
								behandler kun personoplysninger efter vores
								instruks og i overensstemmelse med lovgivningens
								krav til databehandlere. Vi har indgået
								databehandleraftaler med vores databehandlere,
								hvilket er vores garanti for, at de overholder
								gældende regler om beskyttelse af dine
								personoplysninger.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Dine rettigheder
							</h1>
							<p className='policy-section-text'>
								Som den registrerede har du en række
								rettigheder, som vi til enhver tid skal sikre
								opfyldelse af. Du har ret til at anmode os om
								følgende:
								<br /> • At få adgang til og få rettet/ændret
								dine persondata
								<br /> • At få slettet persondata
								<br />
								<br /> Du har derudover ret til at protestere
								over behandlingen af dine personlige data, og du
								har ret til at indgive klage til en
								databeskyttelsesmyndighed. Ønsker du ikke
								længere, at vi skal behandle dine
								personoplysninger, eller at vi skal begrænse
								behandlingen af dine personoplysninger, kan du
								også sende os en anmodning herom til vores
								e-mailadresse andreasudlejningaps@gmail.com.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Cookie- og privatlivspolitik hos Andreas
								Udlejning ApS
							</h1>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Introduktion
							</h1>
							<p className='policy-section-text'>
								Når du besøger vores website indsamles der
								oplysninger om dig, som bruges til at tilpasse
								og forbedre vores indhold og til at øge værdien
								af de annoncer, der vises på siden. Hvis du ikke
								ønsker, at der indsamles oplysninger, bør du
								slette dine cookies (se vejledning) og undlade
								videre brug af websitet. Nedenfor har vi
								uddybet, hvilke informationer der indsamles,
								deres formål og hvilke tredjeparter, der har
								adgang til dem.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Cookies</h1>
							<p className='policy-section-text'>
								Websitet anvender “cookies”, der er en tekstfil,
								som gemmes på din computer, mobil el.
								tilsvarende med det formål at genkende den,
								huske indstillinger, udføre statistik og
								målrette annoncer. Cookies kan ikke indeholde
								skadelig kode som f.eks. virus. Det er muligt at
								slette eller blokere for cookies. Se vejledning:{' '}
								<a
									href='http://minecookies.org/cookiehandtering'
									className='policy-section-link'
								>
									http://minecookies.org/cookiehandtering
								</a>
								Hvis du sletter eller blokerer cookies vil
								annoncer kunne blive mindre relevante for dig og
								optræde hyppigere. Du kan desuden risikere at
								websitet ikke fungerer optimalt samt at der er
								indhold, du ikke kan få adgang til.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Personoplysninger
							</h1>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Generelt</h1>
							<p className='policy-section-text'>
								Personoplysninger er alle slags informationer,
								der i et eller andet omfang kan henføres til
								dig. Når du benytter vores website indsamler og
								behandler vi en række sådanne informationer. Det
								sker f.eks. ved alm. tilgang af indhold, hvis du
								tilmelder dig vores nyhedsbrev, deltager i
								konkurrencer eller undersøgelser, registrerer
								dig som bruger eller abonnent, øvrig brug af
								services eller foretager køb via websitet.
								<br />
								<br />
								Vi indsamler og behandler typisk følgende typer
								af oplysninger: Et unikt ID og tekniske
								oplysninger om din computer, tablet eller
								mobiltelefon, dit IP-nummer, geografisk
								placering, samt hvilke sider du klikker på
								(interesser). I det omfang du selv giver
								eksplicit samtykke hertil og selv indtaster
								informationerne behandles desuden: Navn,
								telefonnummer, e-mail, adresse og
								betalingsoplysninger. Det vil typisk være i
								forbindelse med oprettelse af login eller ved
								køb.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Sikkerhed</h1>
							<p className='policy-section-text'>
								Vi behandler dine personoplysninger sikkert og
								fortroligt i overensstemmelse med gældende
								lovgivning, herunder persondataforordningen og
								databeskyttelsesloven. Dine oplysninger vil
								alene blive anvendt til det formål, de er
								indsamlet til, og vil blive slettet, når dette
								formål er opfyldt eller ikke længere relevant.
								Vi har truffet tekniske og organisatoriske
								foranstaltninger mod, at dine oplysninger
								hændeligt eller ulovligt bliver slettet,
								offentliggjort, fortabt, forringet eller kommer
								til uvedkommendes kendskab, misbruges eller i
								øvrigt behandles i strid med lovgivningen.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Formål</h1>
							<p className='policy-section-text'>
								Oplysningerne bruges til at identificere dig som
								bruger og vise dig de annoncer, som vil have
								størst sandsynlighed for at være relevante for
								dig, at registrere dine køb og betalinger, samt
								at kunne levere de services, du har efterspurgt,
								som f.eks. at fremsende et nyhedsbrev. Herudover
								anvender vi oplysningerne til at optimere vores
								services og indhold.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Periode for opbevaring
							</h1>
							<p className='policy-section-text'>
								Oplysningerne opbevares i det tidsrum, der er
								tilladt i henhold til lovgivningen, og vi
								sletter dem, når de ikke længere er nødvendige.
								Perioden afhænger af karakteren af oplysningen
								og baggrunden for opbevaring. Det er derfor ikke
								muligt at angive en generel tidsramme for,
								hvornår informationer slettes.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Videregivelse af oplysninger
							</h1>
							<p className='policy-section-text'>
								Data om din brug af websitet, hvilke annoncer,
								du modtager og evt. klikker på, geografisk
								placering, køn og alderssegment m.v. videregives
								til tredjeparter i det omfang disse oplysninger
								er kendt. Du kan se hvilke tredjeparter, der er
								tale om, i afsnittet om “Cookies” ovenfor.
								Oplysningerne anvendes til målretning af
								annoncering.
								<br />
								Vi benytter herudover en række tredjeparter til
								opbevaring og behandling af data. Disse
								behandler udelukkende oplysninger på vores vegne
								og må ikke anvende dem til egne formål.
								<br />
								Videregivelse af personoplysninger som navn og
								e-mail m.v. vil kun ske, hvis du giver samtykke
								til det. Vi anvender kun databehandlere i EU
								eller i lande, der kan give dine oplysninger en
								tilstrækkelig beskyttelse.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>
								Indsigt og klager
							</h1>
							<p className='policy-section-text'>
								Du har ret til at få oplyst, hvilke
								personoplysninger, vi behandler om dig i et
								almindeligt format (dataportabilitet). Du kan
								desuden til enhver tid gøre indsigelse mod, at
								oplysninger anvendes. Du kan også tilbagekalde
								dit samtykke til, at der bliver behandlet
								oplysninger om dig. Hvis de oplysninger, der
								behandles om dig, er forkerte har du ret til at
								de bliver rettet eller slettet. Henvendelse
								herom kan ske til:
								andreasudlejningaps@gmail.com. Hvis du vil klage
								over vores behandling af dine personoplysninger,
								har du også mulighed for at tage kontakt til
								Datatilsynet. Ønsker du ikke længere, at vi skal
								behandle dine personoplysninger, eller at vi
								skal begrænse behandlingen af dine
								personoplysninger, kan du også sende os en
								anmodning herom til ovennævnte e-mailadresse.
							</p>
						</div>
						<div className='policy-container'>
							<h1 className='policy-section-title'>Udgiver</h1>
							<p className='policy-section-text'>
								Websitet ejes og publiceres af: Andreas
								Udlejning ApS, Hundstrupvej 60 5771 Stenstrup,
								Telefon: +4532422464, Email:
								andreasudlejningaps@gmail.com
							</p>
						</div>
					</div>
				</DefaultLayout>
			</>
		);
	}
}
