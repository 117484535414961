import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import auth from '../../../utils/auth';
import TimeObject from '../../../utils/timeobject';
import utility from '../../../utils/utility';
import DefaultLayout from '../../containers/layout/defaultlayout/defaultlayout';
import './styles.css';

export default function CompletePage() {
	const [id, setId] = useState(null);
	const [start, setStart] = useState(new TimeObject());
	const [end, setEnd] = useState(new TimeObject());

	const search = useLocation().search;
	const navigate = useNavigate();

	useEffect(() => {
		let isCancelled = false;
		const searchParams = new URLSearchParams(search);
		const id = searchParams.get('id');
		const { start, end } = utility.getParameterTime(search);

		if (id !== null && start.isSelected() && end.isSelected()) {
			if (!isCancelled) {
				setId(id);
				setStart(start);
				setEnd(end);
			}
		} else {
			if (!isCancelled) {
				navigate('/');
			}
		}

		return () => {
			isCancelled = true;
		};
	}, [search, navigate]);

	return (
		<React.Fragment>
			<Helmet>
				<title>Gennemført</title>
				<meta name='robots' content='noindex' />
			</Helmet>
			<DefaultLayout>
				<div className='booking-complete-outer-container'>
					<div className='booking-complete-container'>
						<img
							src='./complete.svg'
							alt='Booking gennemført'
							className='booking-complete-icon'
						/>
						<h1 className='booking-complete-title'>
							Booking gennemført!
						</h1>
						<p className='booking-complete-text'>
							Tak for du har booket en autocamper hos os!
							<br />
							Din booking (bookingId: {utility.getIdFormat(id)}),
							fra{' '}
							<span className='booking-complete-highlight-text'>
								{start.stringClock()} {start.stringDate()}
							</span>{' '}
							til{' '}
							<span className='booking-complete-highlight-text'>
								{end.stringClock()} {end.stringDate()}
							</span>{' '}
							er gennemført. Vi har sendt dig en bekræftelsesmail
							med mere information til{' '}
							{auth.getUserInfo() !== null &&
							auth.getUserInfo().email !== null
								? auth.getUserInfo().email
								: 'dig'}
							.
						</p>
						<Link to={'/booking/' + id}>
							<button className='booking-complete-button'>
								Se booking
							</button>
						</Link>
					</div>
				</div>
			</DefaultLayout>
		</React.Fragment>
	);
}
