import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import auth from './../../../../utils/auth';

export default class dropdown extends Component {
	componentDidMount() {
		document.addEventListener('mouseup', this.props.hideProfileDropdown);
	}

	componentWillUnmount() {
		document.removeEventListener('mouseup', this.props.hideProfileDropdown);
	}

	logout = () => {
		auth.clearToken();
		auth.clearUserInfo();
		this.props.navigate('/');
	};

	render() {
		return (
			<div
				className='profile-header-dropdown'
				ref={this.props.wrapperRef}
			>
				{auth.getToken() ? (
					<React.Fragment>
						<div className='profile-header-dropdown-item'>
							<Link to='/profil' className='profile-header-href'>
								<p>Profil</p>
							</Link>
						</div>
					</React.Fragment>
				) : (
					<React.Fragment>
						<div className='profile-header-dropdown-item'>
							<Link to='/logind' className='profile-header-href'>
								<p>Log ind</p>
							</Link>
						</div>
						<div className='profile-header-dropdown-item'>
							<Link to='/opret' className='profile-header-href'>
								<p>Opret</p>
							</Link>
						</div>
					</React.Fragment>
				)}
				<div className='profile-header-dropdown-item'>
					<Link to='/udlejer' className='profile-header-href'>
						<p>Udlej Autocamper</p>
					</Link>
				</div>
				<div className='profile-header-dropdown-item'>
					<Link to='/autocamper' className='profile-header-href'>
						<p>Autocampere</p>
					</Link>
				</div>
				{auth.getToken() && (
					<div
						className='profile-header-dropdown-item'
						onClick={() => this.logout()}
					>
						<p>Log ud</p>
					</div>
				)}
			</div>
		);
	}
}
