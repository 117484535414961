import React from 'react';
import { Navigate } from 'react-router-dom';

// Utils
import auth from '../../utils/auth.js';

function UnAuthRoute({ children }) {
	return auth.getToken() === null ? children : <Navigate replace to='/' />;
}

export default UnAuthRoute;
