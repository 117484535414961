const AcAddons = ({ autocamper }) => {
	return (
		<section className='ac-page-addons-container ac-page-container'>
			<h2 className='ac-page-detail-title'>Tilkøb</h2>
			<div className='ac-page-addons-list-wrapper'>
				<div className='ac-page-addons-list'>
					{autocamper?.addons?.length === 0 ? (
						<div>
							<p>Ingen tilkøb</p>
						</div>
					) : (
						autocamper?.addons?.map((addon, i) => {
							return (
								<div className='ac-page-addon-item' key={i}>
									<div className='ac-page-addon-detail-container'>
										<p className='ac-page-addon-name'>
											{addon?.name}
										</p>
										<p className='ac-page-addon-description'>
											{addon?.description}
										</p>
									</div>
									<div className='ac-page-addon-price-container'>
										<p className='ac-page-addon-price'>
											{addon?.price}
											<span>
												{addon?.perDay === true
													? ' DKK/DAG'
													: ' DKK'}
											</span>
										</p>
									</div>
								</div>
							);
						})
					)}
				</div>
			</div>
		</section>
	);
};

export default AcAddons;
