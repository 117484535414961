import axios from 'axios';
import { useRef } from 'react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import RequestObject from '../../../utils/requestobject';
import utility from '../../../utils/utility';
import DefaultLayout from '../../containers/layout/defaultlayout/defaultlayout';
import Slides from '../../Slides/slides';
import AcCalender from './accalender';
import AcLayout from './aclayout';
import './styles.css';

const maxMobileSize = 700;
const getDisplaySize = () => {
	const { outerWidth, innerWidth } = window;
	const width = outerWidth < innerWidth ? outerWidth : innerWidth;
	return width < maxMobileSize ? 'mobile' : 'desktop';
};

const AcPage = () => {
	const [displaySize, setDisplaySize] = useState(getDisplaySize());
	const [autocamper, setAutocamper] = useState(new RequestObject(null));
	const [calenderObjects, setCalenderObjects] = useState(
		new RequestObject(null)
	);
	const [calenderDays, setCalenderDays] = useState(new RequestObject(null));
	const [imageIndex, setImageIndex] = useState(null);
	const [images, setImages] = useState([]);
	const [calenderRef, setCalenderRef] = useState(useRef(null));
	const [buttonRef, setButtonRef] = useState(useRef(null));
	const [displayHeaderButton, setDisplayHeaderButton] = useState(false);

	const { id } = useParams();

	useEffect(() => {
		const handleWindowResize = () => {
			const size = getDisplaySize();
			setDisplaySize(size);
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	useEffect(() => {
		const handleWindowScroll = (e) => {
			const buttonOffsetTop = buttonRef?.current?.offsetTop;
			const calenderOffsetTop = calenderRef?.current?.offsetTop;
			const newDisplayHeaderButton =
				window.scrollY > buttonOffsetTop + 32 &&
				window.scrollY < calenderOffsetTop - 200;
			setDisplayHeaderButton(newDisplayHeaderButton);
		};

		window.addEventListener('scroll', handleWindowScroll);

		return () => {
			window.removeEventListener('scroll', handleWindowScroll);
		};
	}, [buttonRef, calenderRef]);

	useEffect(() => {
		let isMounted = true;

		const fetchAutocamper = async () => {
			const newAutocamper = new RequestObject(async () => {
				return await axios.get(
					process.env.REACT_APP_API_URL + `/autocampers/${id}`
				);
			});
			if (newAutocamper.getStatus() === 0) {
				await newAutocamper.sendRequest();
				const autocamperResult = newAutocamper.clone();

				if (isMounted) setAutocamper(autocamperResult);

				const autocamperResponse = autocamperResult.getObject();

				let images = [
					utility.getAutocamperImageSize(
						autocamperResponse?.avatar[0],
						'large'
					),
				];

				for (let i = 0; i < autocamperResponse?.images.length; i++) {
					images.push(
						utility.getAutocamperImageSize(
							autocamperResponse?.images[i],
							'large'
						)
					);
				}

				if (isMounted) setImages(images);

				if (autocamperResult.getStatus() === 1)
					fetchCalenderDays(autocamperResponse?.organization?.id);
			}
		};

		const fecthCalenderObjects = async () => {
			const newCalenderObjects = new RequestObject(async () => {
				return await axios.get(
					process.env.REACT_APP_API_URL +
						`/calender-objects/autocamper/${id}/upcomming`
				);
			});

			if (newCalenderObjects.getStatus() === 0) {
				await newCalenderObjects.sendRequest();
				const calenderObjectsResult = newCalenderObjects.clone();

				if (isMounted) setCalenderObjects(calenderObjectsResult);
			}
		};

		const fetchCalenderDays = async (organizationId) => {
			const newCalenderDays = new RequestObject(async () => {
				return await axios.get(
					process.env.REACT_APP_API_URL +
						`/calender-days/${organizationId}/all`
				);
			});

			if (newCalenderDays.getStatus() === 0) {
				await newCalenderDays.sendRequest();
				const calenderDaysResult = newCalenderDays.clone();

				if (isMounted) setCalenderDays(calenderDaysResult);
			}
		};

		fecthCalenderObjects();
		fetchAutocamper();

		return () => {
			isMounted = false;
		};
	}, [id]);

	const scrollToCalender = () => {
		let { offsetTop } = calenderRef.current;
		const headerHeight = 116;
		const top = offsetTop - headerHeight;
		window.scrollTo({
			top: top,
			behavior: 'smooth',
		});
	};

	const ac = autocamper.getObject();

	const structuredData = {
		'@context': 'https://schema.org',
		'@type': 'Product',
		name: ac?.name,
		description: ac?.description,
		image: ac?.avatar ? utility.getAllAutocamperImages(ac?.avatar) : [],
	};

	return (
		<DefaultLayout>
			{autocamper !== null && (
				<>
					<Helmet>
						<title>
							{autocamper.getStatus() === 1
								? ac?.name
								: 'Autocamper'}{' '}
							| Andreas Udlejning
						</title>
						<meta name='description' content={ac?.description} />
						<meta
							name='robots'
							content={
								autocamper?.getStatus() === 1 ? '' : 'noindex'
							}
						/>
						<script type='application/ld+json'>
							{JSON.stringify(structuredData)}
						</script>
					</Helmet>
					<div className='ac-page-layout'>
						<AcLayout
							displaySize={displaySize}
							autocamper={autocamper}
							scrollToCalender={scrollToCalender}
							setButtonRef={setButtonRef}
							displayHeaderButton={displayHeaderButton}
							setImageIndex={setImageIndex}
						/>
						<AcCalender
							startPrice={autocamper.getObject()?.startPrice}
							calenderObjects={calenderObjects}
							calenderDays={calenderDays}
							autocamperId={ac?.id}
							setCalenderRef={setCalenderRef}
						/>
						<div className='ac-page-like-autocamper-container responsive-container'></div>
					</div>
					<Slides
						slides={{
							images: images,
							close: () => setImageIndex(null),
							open: imageIndex !== null,
							index: imageIndex,
							previous: () =>
								setImageIndex(
									imageIndex === 0
										? images.length - 1
										: imageIndex - 1
								),
							next: () =>
								setImageIndex((imageIndex + 1) % images.length),
						}}
					/>
				</>
			)}
		</DefaultLayout>
	);
};

export default AcPage;
