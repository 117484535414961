import React from 'react';

const SignupProgress = ({ progress, item, index }) => {
	return (
		<div className='signup-progress-item'>
			<div
				className={
					'signup-progress-indicator' +
					(progress > index
						? ' signup-progress-indicator-completed'
						: progress === index
						? ' signup-progress-indicator-reached'
						: '')
				}
			>
				<p className='signup-progress-indicator-text'>{index + 1}</p>
			</div>
			<p className='signup-progress-description'>{item.name}</p>
		</div>
	);
};

export default SignupProgress;
