import React from 'react';
import { Link } from 'react-router-dom';
import utility from '../../utils/utility';

import './styles.css';

const Autocamper = (props) => {
	const redirectToPage = (id) => {
		let url = '/autocamper/' + id + '?';

		const { start, end } = props;

		if (start !== null && start !== undefined) {
			if (start.isSelected()) {
				url += `start=${start.getDate.getTime()}&startTime=${
					start.getTime
				}`;
			}
		}

		if (end !== null && end !== undefined) {
			if (end.isSelected()) {
				if (url.slice(-1) !== '?') url += '&';
				url += `end=${end.getDate.getTime()}&endTime=${end.getTime}`;
			}
		}
		return url;
	};

	const { autocamper } = props;
	const image = utility.getAutocamperImage(autocamper.avatar[0]);

	return (
		<Link className='autocamper-item' to={redirectToPage(autocamper.id)}>
			<div className='autocamper-image-container'>
				<div
					className='autocamper-image'
					style={{
						backgroundImage: `url(${image})`,
					}}
				></div>
			</div>
			<div className='autocamper-details-container'>
				<div>
					<p className='autocamper-name'>{autocamper.name}</p>
				</div>
				<div>
					<p className='autocamper-organzition-name'>
						{autocamper.organization.name}
					</p>
				</div>
			</div>
			<div className='autocamper-price-container'>
				<span className='autocamper-price-span'>
					<p className='autocamper-price'>
						<span className='autocamper-price-from'>Fra</span>
						{Math.floor(autocamper.price)}
					</p>
					<p className='autocamper-price-unit'>DKK/dag</p>
				</span>
			</div>
		</Link>
	);
};

const areEqual = (prevProps, nextProps) => {
	return prevProps?.autocamper?.id === nextProps?.autocamper?.id;
};

const memoizedAutocamper = React.memo(Autocamper, areEqual);

export default memoizedAutocamper;
