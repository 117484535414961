import { useState } from 'react';
import validateSignup from '../../../utils/validateSignup';
import DefaultLayout from '../../containers/layout/defaultlayout/defaultlayout';
import SignupInput from './signupinput';
import SignupProgress from './signupprogess';
import axios from 'axios';

import './styles.css';
import LoadingButton from '../../loadingbutton/loadingbutton';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

const SignupPage = () => {
	const [progress, setProgress] = useState(0);
	const [signupValues, setSignupValues] = useState({
		firstname: '',
		lastname: '',
		phoneNumber: '',
		birthdate: '',
		street: '',
		streetNumber: '',
		city: '',
		postalCode: '',
		email: '',
		username: '',
		password: '',
		passwordRepeat: '',
	});
	const [errors, setErrors] = useState({});
	const [displayHelp, setDisplayHelp] = useState(null);
	const [signupSent, setSignupSent] = useState(false);
	const [signupError, setSignupError] = useState(null);

	const progressItems = [
		{
			name: 'Personlig',
			inputs: [
				{
					parameter: 'firstname',
					label: 'Fornavn',
					type: 'text',
					help: 'Fornavnet skal være udfylgt og må bestå af alle bogstaverne fra A til Å, både i stort og småt. Mellemrum og "-" må også bruges.',
				},
				{
					parameter: 'lastname',
					label: 'Efternavn',
					type: 'text',
					help: 'Efternavnet skal være udfylgt og må bestå af alle bogstaverne fra A til Å, både i stort og småt. Mellemrum og "-" må også bruges.',
				},
				{
					parameter: 'birthdate',
					label: 'Fødselsdato',
					type: 'date',
					help: 'Fødselsdato skal være i formattet DD-MM-YYYY, og man skal være fyldt 18 år.',
					placeholder: 'DD-MM-YYYY',
				},
				{
					parameter: 'phoneNumber',
					label: 'Telefonnummer',
					type: 'number',
					help: 'Skal være et dansk telefon nummer UDEN +45.',
				},
			],
		},
		{
			name: 'Adresse',
			inputs: [
				{
					parameter: 'street',
					label: 'Vej',
					type: 'text',
					help: 'Navnet på vejen på din adresse, og må bestå af alle bogstaverne fra A til Å, både i stort og småt.',
				},
				{
					parameter: 'streetNumber',
					label: 'Husnummer',
					type: 'text',
					help: 'Husnummeret skal bestå af et tal og så efterfulgt af bogstaver.',
				},
				{
					parameter: 'postalCode',
					label: 'Postnummer',
					type: 'number',
					help: 'Skal være et dansk postnummer som svarer til byen givet nedenunder. Postnummeret består af 4 tal.',
				},
				{
					parameter: 'city',
					label: 'By',
					type: 'text',
					help: 'Byen skal være den by som passer til det givet postnummer, og det kan kun bestå af bogstaverne A-Å i både stort og småt, og mellemrum, "-", og ".".',
				},
			],
		},
		{
			name: 'Profil',
			inputs: [
				{
					parameter: 'email',
					label: 'Email',
					type: 'email',
					help: 'Emailen skal være unikt, og være en gyldig email adresse.',
				},
				{
					parameter: 'username',
					label: 'Brugernavn',
					type: 'text',
					help: 'Brugernavnet skal være unikt. Det kan kun bestå af A-Å i stort og småt, og symbolerne "-" og ".".',
				},
				{
					parameter: 'password',
					label: 'Adgangskode',
					type: 'password',
					help: 'Adgangskoden skal have en længde på minimum 8 tegn. For øget sikkerhed, brug en helt ny adgangskode som ikke er brugt før og som er langt.',
				},
				{
					parameter: 'passwordRepeat',
					label: 'Gentag Adgangskode',
					type: 'password',
					help: 'Gentag adgangskoden fra oven over.',
				},
			],
		},
	];

	const updateDisplayHelp = (parameter) => {
		if (parameter === displayHelp) {
			setDisplayHelp(null);
		} else {
			setDisplayHelp(parameter);
		}
	};

	const signupBackwards = () => {
		setProgress(progress - 1);
	};

	const signupProgress = () => {
		// Validate.
		var validation = false;
		const errors = {};
		if (progress === 0) {
			const firstnameValid = validateSignup.validateFirstname(
				signupValues.firstname
			);
			if (!firstnameValid) errors.firstname = 'Ugyldigt fornavn.';
			const lastnameValid = validateSignup.validateLastname(
				signupValues.lastname
			);
			if (!lastnameValid) errors.lastname = 'Ugyldigt efternavn.';
			const birthdateValid = validateSignup.validateBirthdate(
				signupValues.birthdate
			);
			if (!birthdateValid)
				errors.birthdate =
					'Ugyldigt fødselsdato, minimum 18 år gammel (DD-MM-YYYY).';
			const phonenumberValid = validateSignup.validatePhoneNumber(
				signupValues.phoneNumber
			);
			if (!phonenumberValid)
				errors.phoneNumber = 'Ugyldigt telefonnummer.';
			validation =
				firstnameValid &&
				lastnameValid &&
				birthdateValid &&
				phonenumberValid;
		} else if (progress === 1) {
			const streetValid = validateSignup.validateStreet(
				signupValues.street
			);
			if (!streetValid) errors.street = 'Ugyldigt vejnavn.';
			const streetnumberValid = validateSignup.validateStreetNumber(
				signupValues.streetNumber
			);
			if (!streetnumberValid) errors.streetNumber = 'Ugyldigt husnummer.';
			const postalcodeValid = validateSignup.validatePostalCode(
				signupValues.postalCode
			);
			if (!postalcodeValid) errors.postalCode = 'Ugyldigt postnummer.';
			const cityValid = validateSignup.validateCity(signupValues.city);
			if (!cityValid) errors.city = 'Ugyldigt navn på by.';
			validation =
				streetValid &&
				streetnumberValid &&
				postalcodeValid &&
				cityValid;
		}
		if (validation === true) setProgress(progress + 1);
		setErrors(errors);
	};

	const signupUser = async () => {
		setSignupError(null);

		if (progress === 2 && signupSent === false) {
			const errors = {};
			const emailValid = validateSignup.validateEmail(signupValues.email);
			if (!emailValid) errors.email = 'Ugyldig email.';
			const usernameValid = validateSignup.validateUsername(
				signupValues.username
			);
			if (!usernameValid) errors.username = 'Ugyldig username.';
			const passwordValid = validateSignup.validatePassword(
				signupValues.password,
				signupValues.passwordRepeat
			);
			if (!passwordValid) errors.password = 'Ugyldig password givet';

			if (emailValid && usernameValid && passwordValid) {
				setSignupSent(true);

				const birthdateSplit = signupValues.birthdate.split('-');
				const birthdate = `${birthdateSplit[2]}-${birthdateSplit[1]}-${birthdateSplit[0]}`;

				try {
					const response = await axios.post(
						process.env.REACT_APP_API_URL + '/auth/local/register',
						{
							username: signupValues.username,
							email: signupValues.email,
							password: signupValues.password,
							firstname: signupValues.firstname,
							lastname: signupValues.lastname,
							address: {
								street: signupValues.street,
								streetNumber: signupValues.streetNumber,
								postalCode: signupValues.postalCode,
								city: signupValues.city,
							},
							birthdate: birthdate,
							phoneNumber: signupValues.phoneNumber,
						},
						{
							headers: {
								'Content-Type': 'application/json',
							},
						}
					);

					if (response?.status === 200) {
						setProgress(3);
					} else {
						setSignupError(JSON.stringify(response));
					}
				} catch (error) {
					if (error?.response?.status === 400) {
						setSignupError(
							'Noget gik galt. Måske er emailen eller brugernavnet allerede brugt.'
						);
					} else {
						setSignupError('Server fejl.');
					}
				}
				setSignupSent(false);
			} else {
				setErrors(errors);
			}
		}
	};

	return (
		<>
			<Helmet>
				<title>Opret</title>
				<meta
					name='description'
					content='Opret en profil hos Andreas Udlejning, og begynde at booke i dag.'
				/>
				<meta
					name='keywords'
					content='Opret, sign up, sign-up, profil, konto'
				/>
			</Helmet>
			<DefaultLayout>
				<div className='signup-page-layout-container'>
					<section className='signup-container'>
						<div className='signup-container-header'>
							<h1 className='signup-container-header-title'>
								OPRET
							</h1>
						</div>
						<div className='signup-container-body'>
							{progress < progressItems.length ? (
								<>
									<div className='signup-progress-container'>
										{progressItems.map((item, i) => {
											return (
												<SignupProgress
													item={item}
													progress={progress}
													index={i}
													key={i}
												/>
											);
										})}
									</div>
									<div className='signup-inputs-container'>
										{progressItems[progress].inputs.map(
											(input, i) => {
												return (
													<SignupInput
														input={input}
														key={i}
														signupValues={
															signupValues
														}
														updateSignupValues={
															setSignupValues
														}
														errors={errors}
														displayHelp={
															displayHelp
														}
														updateDisplayHelp={
															updateDisplayHelp
														}
													/>
												);
											}
										)}
									</div>
									<div className='signup-function-container'>
										{progress > 0 && (
											<button
												onClick={() =>
													signupBackwards()
												}
												className='signup-function-button signup-function-secondary-button'
											>
												Tilbage
											</button>
										)}
										{progress <
											progressItems.length - 1 && (
											<button
												onClick={() => signupProgress()}
												className='signup-function-button signup-function-primary-button'
											>
												Næste
											</button>
										)}
										{progress ===
											progressItems.length - 1 && (
											<LoadingButton
												text='Opret'
												clickHandler={() =>
													signupUser()
												}
												classes='signup-function-button signup-function-primary-button'
												isLoading={signupSent}
											/>
										)}
									</div>
									{signupError && (
										<div className='signup-error-container'>
											<p className='signup-error-text'>
												{signupError}
											</p>
										</div>
									)}
								</>
							) : (
								<div className='signup-complete-container'>
									<h1 className='signup-complete-title'>
										Email sendt
									</h1>
									<p className='signup-complete-text'>
										Din bruger er blevet oprettet! En email
										er blevet sendt til {signupValues.email}
										, hvori du skal bekræfte din oprettelse.
									</p>
									<Link to='/logind'>
										<button className='signup-complete-button'>
											Log ind
										</button>
									</Link>
								</div>
							)}
						</div>
						{progress < progressItems.length && (
							<div className='signup-existing-user-container'>
								<Link to='/logind'>
									<button className='signup-existing-user-text'>
										Jeg har allerede en bruger
									</button>
								</Link>
							</div>
						)}
					</section>
				</div>
			</DefaultLayout>
		</>
	);
};

export default SignupPage;
