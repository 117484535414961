import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import auth from '../../../utils/auth';
import RequestObject from '../../../utils/requestobject';
import TimeObject from '../../../utils/timeobject';
import DefaultLayout from '../../containers/layout/defaultlayout/defaultlayout';
import Popup from '../../containers/popup/popup';
import AddonList from './addonlist';
import BookAutocamper from './bookautocamper';
import BookContainer from './bookcontainer';
import './styles.css';

export default function BookPage() {
	const getAutocamper = async (id) => {
		return await axios.get(
			process.env.REACT_APP_API_URL + '/autocampers/' + id
		);
	};

	const [startTime, setStartTime] = useState(new TimeObject());
	const [endTime, setEndTime] = useState(new TimeObject());
	const [daysTillBooking, setDaysTillBooking] = useState(null);
	const [autocamperId, setAutocamperId] = useState(null);
	const [autocamper, setAutocamper] = useState(
		new RequestObject(getAutocamper)
	);

	const [totalAutocamperPrice, setTotalAutocamperPrice] = useState(0);
	const [addons, setAddons] = useState(null);
	const [selectedAddons, setSelectedAddons] = useState(null);
	const [termsOfTrade, setTermsOfTrade] = useState(false);
	const [policy, setPolicy] = useState(false);
	const [paymentMethod, setPaymentMethod] = useState(null);
	const [paymentRate, setPaymentRate] = useState(1);
	const [accountNumber, setAccountNumber] = useState('');
	const [registrationNumber, setRegistrationNumber] = useState('');
	const [bookingError, setBookingError] = useState(null);
	const [showLoginPopup, setShowLoginPopup] = useState(false);
	const [stateErrors, setStateErrors] = useState(null);
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		let isCancelled = false;

		async function setup() {
			function setGetParameters() {
				const {
					start,
					end,
					id,
					termsOfTrade,
					policy,
					paymentMethod,
					rate,
					accountNumber,
					registrationNumber,
					selectedAddons,
				} = location.state;

				setAutocamperId(id);
				const startDate = new Date(start);
				setStartTime(
					new TimeObject(
						startDate,
						startDate.getUTCHours() * 2 +
							(startDate.getUTCMinutes() >= 30 ? 1 : 0)
					)
				);
				const endDate = new Date(end);
				setEndTime(
					new TimeObject(
						endDate,
						endDate.getUTCHours() * 2 +
							(endDate.getUTCMinutes() >= 30 ? 1 : 0)
					)
				);
				if (policy) setPolicy(policy);
				if (termsOfTrade) setTermsOfTrade(termsOfTrade);
				if (paymentMethod) {
					const validPaymentMethods = ['banktransfer'];
					if (validPaymentMethods.includes(paymentMethod))
						setPaymentMethod(paymentMethod);
				}
				if (accountNumber) setAccountNumber(accountNumber);
				if (registrationNumber)
					setRegistrationNumber(registrationNumber);
				if (rate && (rate === 1 || rate === 2)) {
					setPaymentRate(rate);
				}
				if (selectedAddons) setSelectedAddons(selectedAddons);
				const today = new Date();
				setDaysTillBooking(
					Math.floor((start - today.getTime()) / 86400000)
				);
			}

			async function validateStartAndEnd() {
				const { start, end, id } = location.state;
				try {
					const response = await axios.get(
						process.env.REACT_APP_API_URL +
							`/calender-objects/${id}/validate?start=${start}&end=${end}`
					);

					if (!isCancelled) {
						if (
							response.data.start === true &&
							response.data.end === true
						) {
							setTotalAutocamperPrice(response.data.price);
							return true;
						}
					}
				} catch (error) {
					return false;
				}
				return false;
			}

			const isValid = await validateStartAndEnd();
			if (isValid) setGetParameters();
			else navigate('/autocamper', { replace: true });
		}

		setup();
		return () => {
			isCancelled = true;
		};
	}, [location, navigate]);

	useEffect(() => {
		let isCancelled = false;

		async function requesting(requestObject, value) {
			if (value !== null && requestObject.getStatus() === 0) {
				const req = requestObject;
				await req.sendRequest(value);
				if (!isCancelled) {
					const newRequestObject = req.clone();

					setAutocamper(newRequestObject);
				}
			}
		}

		requesting(autocamper, autocamperId);
		return () => {
			isCancelled = true;
		};
	}, [autocamperId, autocamper]);

	useEffect(() => {
		function createAddons() {
			if (addons === null) {
				const addons = [];
				autocamper.getObject().addons.forEach((addon) => {
					addons.push({
						addon: addon,
						state:
							selectedAddons !== null &&
							selectedAddons?.includes(addon?.id),
					});
				});

				setAddons(addons);
			}
		}

		if (autocamper.getObject() !== null) {
			createAddons();
		}
	}, [autocamper, addons, selectedAddons]);

	const validateForBooking = () => {
		return (
			autocamper.getStatus() === 1 &&
			paymentMethod !== null &&
			policy === true &&
			termsOfTrade === true &&
			(paymentRate === 1 || paymentRate === 2) &&
			Number.isInteger(accountNumber) &&
			!Number.isNaN(accountNumber) &&
			accountNumber > 0 &&
			Number.isInteger(registrationNumber) &&
			!Number.isNaN(registrationNumber) &&
			registrationNumber > 0
		);
	};

	const showStateErrors = () => {
		let result = {};
		result.payment = paymentMethod !== null;
		result.policy = policy === true;
		result.termsOfTrade = termsOfTrade === true;
		result.rate = paymentRate === 1 || paymentRate === 2;
		result.accountNumber =
			Number.isInteger(accountNumber) &&
			!Number.isNaN(accountNumber) &&
			accountNumber > 0;
		result.registrationNumber =
			Number.isInteger(registrationNumber) &&
			!Number.isNaN(registrationNumber) &&
			registrationNumber > 0;
		setStateErrors(result);
	};

	const toggleAddonState = (index) => {
		let newAddons = [...addons];
		newAddons[index].state = !newAddons[index].state;
		setAddons(newAddons);
	};

	const redirectTo = (page) => {
		const selectedAddons = [];
		if (addons !== null) {
			addons.forEach((addon) => {
				if (addon.state === true) {
					selectedAddons.push(addon.addon.id);
				}
			});
		}

		navigate(page, {
			state: {
				from: '/book',
				start: startTime.unix,
				end: endTime.unix,
				id: autocamperId,
				registrationNumber: registrationNumber,
				accountNumber: accountNumber,
				termsOfTrade: termsOfTrade,
				policy: policy,
				paymentMethod: paymentMethod,
				selectedAddons: selectedAddons,
			},
		});
	};

	const bookAutocamper = async () => {
		if (auth.getToken()) {
			try {
				const selectedAddons = [];
				if (addons !== null) {
					addons.forEach((addon) => {
						if (addon.state === true) {
							selectedAddons.push(addon.addon.id);
						}
					});
				}

				const response = await axios({
					method: 'post',
					url: `${process.env.REACT_APP_API_URL}/autocamper/${autocamperId}/booking`,
					data: {
						rate: paymentRate,
						addons: selectedAddons,
						paymentMethod: paymentMethod,
						userAccountNumber: accountNumber,
						userRegistrationNumber: registrationNumber,
						start: startTime.unix,
						end: endTime.unix,
					},
					headers: {
						Authorization: `Bearer ${auth.getToken()}`,
						'Content-Type': 'application/json',
					},
				});

				// On success redirect to complete page.
				if (response.status === 200) {
					navigate(
						`/complete?id=${
							response.data.id
						}&start=${startTime.getDate.getTime()}&startTime=${
							startTime.getTime
						}&end=${endTime.getDate.getTime()}&endTime=${
							endTime.getTime
						}`
					);
				}
			} catch (error) {
				setBookingError('Noget gik galt...');
			}
		} else {
			setShowLoginPopup(true);
		}
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>Book</title>
				<meta name='robots' content='noindex' />
			</Helmet>
			<DefaultLayout>
				<div className='book-autocamper-centering-container'>
					<div className='book-autocamper-layout-container'>
						<div className='book-autocamper-left-container'>
							<BookAutocamper
								autocamper={autocamper}
								startTime={startTime}
								endTime={endTime}
								totalAutocamperPrice={totalAutocamperPrice}
							/>
							<AddonList
								autocamper={autocamper}
								addons={addons}
								toggleAddonState={toggleAddonState}
							/>
						</div>
						<div className='book-payment-container'>
							<BookContainer
								autocamper={autocamper}
								totalAutocamperPrice={totalAutocamperPrice}
								addons={addons}
								paymentMethod={paymentMethod}
								stateErrors={stateErrors}
								setPaymentMethod={setPaymentMethod}
								daysTillBooking={daysTillBooking}
								paymentRate={paymentRate}
								setPaymentRate={setPaymentRate}
								termsOfTrade={termsOfTrade}
								policy={policy}
								setPolicy={setPolicy}
								startTime={startTime}
								endTime={endTime}
								bookingError={bookingError}
								setTermsOfTrade={setTermsOfTrade}
								validateForBooking={validateForBooking}
								showStateErrors={showStateErrors}
								bookAutocamper={bookAutocamper}
								accountNumber={accountNumber}
								setAccountNumber={setAccountNumber}
								registrationNumber={registrationNumber}
								setRegistrationNumber={setRegistrationNumber}
							/>
							<div className='price-calculater-container'>
								<p>
									Hvordan udregner vi prisen?{' '}
									<a
										href='/pris'
										className='price-calculator-link'
									>
										Prisudregner
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
			</DefaultLayout>
			{showLoginPopup === true && (
				<Popup
					title={'Mangler logind'}
					close={() => {
						setShowLoginPopup(false);
					}}
				>
					<div className='book-please-login-container'>
						<p className='book-please-login-text'>
							For at lave en booking skal du være logget ind.
						</p>
						<div className='book-please-login-options-container'>
							<button
								className='book-please-login-button'
								onClick={() => redirectTo('/logind')}
							>
								Log ind
							</button>
							<span
								className='book-please-login-create-account'
								onClick={() => redirectTo('/opret')}
							>
								Opret konto
							</span>
						</div>
					</div>
				</Popup>
			)}
		</React.Fragment>
	);
}
