import React, { Component } from 'react';
import './styles.css';

export default class Slides extends Component {
	componentDidUpdate() {
		const { open } = this.props.slides;
		document.body.style.overflow = open === true ? 'hidden' : 'unset';
	}

	render() {
		const { images, close, open, index, previous, next } =
			this.props.slides;

		return (
			open === true && (
				<div className='slides-background-container'>
					<div className='slides-direction-container'>
						<button
							onClick={previous}
							className='slides-direction-button'
						>
							<img
								src='/white-caret.svg'
								alt='Previous'
								className='slides-direction-icon slides-direction-icon-flipped'
							/>
						</button>
					</div>
					<div className='slides-images-display-container'>
						<img
							src={images[index]}
							alt='Autocamper'
							className='slides-image-item'
						/>
					</div>
					<div className='slides-direction-container'>
						<button
							onClick={next}
							className='slides-direction-button'
						>
							<img
								src='/white-caret.svg'
								alt='Next'
								className='slides-direction-icon'
							/>
						</button>
					</div>
					<button className='slides-close-button' onClick={close}>
						<img src='/close.svg' alt='Close' />
					</button>
				</div>
			)
		);
	}
}
