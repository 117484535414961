import axios from 'axios';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';
import validateSignup from '../../../utils/validateSignup';
import DefaultLayout from '../../containers/layout/defaultlayout/defaultlayout';
import LoadingButton from '../../loadingbutton/loadingbutton';
import './styles.css';

class LessorPage extends Component {
	state = {
		name: '',
		email: '',
		message: '',
		sendingEmail: false,
		error: null,
		emailSent: false,
	};

	sendEmail = async () => {
		const { name, email, message } = this.state;

		this.setState({ sendingEmail: true });
		if (name !== '' && message !== '' && email !== '') {
			if (validateSignup.validateEmail(email)) {
				try {
					const response = await axios({
						url: process.env.REACT_APP_API_URL + '/email/send',
						method: 'POST',
						data: {
							email: email,
							name: name,
							message: message,
						},
					});

					if (response.status === 200) {
						this.setState({
							name: '',
							email: '',
							message: '',
							error: null,
							emailSent: true,
						});
					} else {
						this.setState({ error: 'Noget gik galt.' });
					}
				} catch (error) {
					if (error.message) {
						this.setState({ error: error.message });
					} else {
						this.setState({ error: 'Noget gik galt.' });
					}
				}
			} else {
				this.setState({ error: 'Ugyldig email' });
			}
		} else {
			this.setState({ error: 'Udfyld alle felterne.' });
		}

		this.setState({ sendingEmail: false });
	};

	render() {
		return (
			<React.Fragment>
				<Helmet>
					<title>Bliv Udlejer</title>
					<meta
						name='description'
						content='Bliv udlejer hos os, og tjen penge ved at udleje din autocamper'
					/>
					<meta
						name='keywords'
						content='autocamper, autocampere, udlej, leje, andreasudlejning, andreas udlejning, autocamperleje, fyn, sydfyn, stenstrup'
					/>
					<meta name='robots' content='' />
				</Helmet>
				<DefaultLayout>
					<div className='lessor-outer-container'>
						<div className='lessor-container'>
							<div className='lessor-title-container'>
								<h1 className='lessor-title'>
									Skal vi udleje din autocamper?
								</h1>
							</div>
							<div></div>
							<div className='lessor-information-container'>
								<div className='lessor-text-container'>
									<p>
										Vi hos Andreas Udlejning oplever en stor
										interesse i vores autocampere denne
										sæson og søger derfor private
										autocampister som ønsker at udleje deres
										egen autocamper.
									</p>
									<p>
										Hos Andreas Udlejning tilbyder vi at
										håndtere hele udlejningsprocessen for
										dig. Vi har lokationen, bookingsystemet,
										erfaring med udlevering, aflevering,
										kontrakt, lejebetingelser osv.
									</p>
									<p>
										Har du en autocamper der er tilgængelig
										denne sommer og ønsker du at høre mere.
										Så send os gerne en mail og vi vil vende
										tilbage med et uforpligtende tilbud.
									</p>
								</div>
								<div className='lessor-message-container'>
									<input
										placeholder='Navn'
										type='text'
										className='lessor-input-text'
										value={this.state.name}
										onChange={(e) =>
											this.setState({
												name: e.target.value,
											})
										}
									/>
									<input
										placeholder='Email'
										type='email'
										className='lessor-input-text'
										value={this.state.email}
										onChange={(e) =>
											this.setState({
												email: e.target.value,
											})
										}
									/>
									<textarea
										placeholder='Besked'
										className='lessor-input-textarea'
										rows='3'
										value={this.state.message}
										onChange={(e) =>
											this.setState({
												message: e.target.value,
											})
										}
									></textarea>
									<LoadingButton
										isLoading={this.state.sendingEmail}
										clickHandler={this.sendEmail}
										text='Send'
										classes='lessor-message-button'
									/>
									{this.state.error !== null && (
										<p className='lessor-message-error-text'>
											{this.state.error}
										</p>
									)}

									{this.state.emailSent === true && (
										<p className='lessor-message-sent-text'>
											Besked blev afsendt.
										</p>
									)}
								</div>
							</div>
						</div>
					</div>
				</DefaultLayout>
			</React.Fragment>
		);
	}
}

export default LessorPage;
