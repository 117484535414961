import TimeObject from './timeobject';

const utility = {
	/**
	 * Returns the biggest avatar image.
	 *
	 * @param {object} autocamper
	 */
	getAutocamperImage(autocamper) {
		var image = '';

		if (autocamper?.formats?.large) {
			image += autocamper?.formats?.large?.url;
		} else if (autocamper?.formats?.medium) {
			image += autocamper?.formats?.medium?.url;
		} else if (autocamper?.formats?.small) {
			image += autocamper?.formats?.small?.url;
		} else if (autocamper?.formats?.thumbnail) {
			image += autocamper?.formats?.thumbnail?.url;
		}

		return image;
	},

	getAutocamperImageSize(imageObject, size) {
		const sizes = { large: 3, medium: 2, small: 1, thumbnail: 0 };
		const imageSize = sizes[size];

		var image = '';
		if (imageSize >= sizes.large && imageObject?.formats?.large) {
			image = imageObject?.formats?.large?.url;
		} else if (imageSize >= sizes.medium && imageObject?.formats?.medium) {
			image = imageObject?.formats?.medium?.url;
		} else if (imageSize >= sizes.small && imageObject?.formats?.small) {
			image = imageObject?.formats?.small.url;
		} else if (imageObject?.formats?.thumbnail) {
			image += imageObject?.formats?.thumbnail.url;
		}
		return image;
	},

	getAllAutocamperImages(imageObject) {
		let urls = [];
		if (imageObject?.formats?.large)
			urls.push(imageObject?.formats?.large?.url);
		if (imageObject?.formats?.medium)
			urls.push(imageObject?.formats?.medium?.url);
		if (imageObject?.formats?.small)
			urls.push(imageObject?.formats?.small?.url);
		if (imageObject?.formats?.thumbnail)
			urls.push(imageObject?.formats?.thumbnail?.url);
		return urls;
	},

	/**
	 * Searching for GET time parameters.
	 *
	 * @param {Object} search uselocation().search()
	 *
	 * @return
	 */
	getParameterTime(search) {
		const searchParams = new URLSearchParams(search);
		const startParam = searchParams.get('start');
		const startTimeParam = searchParams.get('startTime');
		const endParam = searchParams.get('end');
		const endTimeParam = searchParams.get('endTime');

		// Finding the start.
		let startDate = null;
		let startTime = -1;
		if (startParam !== null && !isNaN(parseInt(startParam))) {
			startDate = new Date(parseInt(startParam));
		}
		if (startTimeParam !== null && !isNaN(parseInt(startTimeParam))) {
			startTime = parseInt(startTimeParam);
		}

		// Finding the end.
		let endDate = null;
		let endTime = -1;
		if (endParam !== null && !isNaN(parseInt(endParam))) {
			endDate = new Date(parseInt(endParam));
		}
		if (endTimeParam !== null && !isNaN(parseInt(endTimeParam))) {
			endTime = parseInt(endTimeParam);
		}

		return {
			start: new TimeObject(startDate, startTime),
			end: new TimeObject(endDate, endTime),
		};
	},

	getIdFormat(id) {
		var s = '00000000' + id;
		return '#' + s.substr(s.length - 8);
	},

	// TODO: TEST THIS FUNCTION.
	calcNumberOfDays(s, e) {
		const sd = s.getSelectedDateUTC();
		const startDate = new Date(
			Date.UTC(sd.getFullYear(), sd.getMonth(), sd.getDate())
		);
		const ed = e.getSelectedDateUTC();
		const endDate = new Date(
			Date.UTC(ed.getFullYear(), ed.getMonth(), ed.getDate())
		);
		return (
			Math.floor((endDate.getTime() - startDate.getTime()) / 86400000) + 1
		);
	},

	latestPaymentDateOfRate(created_at, booking_start, rate, payment_number) {
		if (rate === 1) {
			const paymentDate = new Date(
				Date.UTC(
					created_at.getFullYear(),
					created_at.getMonth(),
					created_at.getDate() + 2
				)
			);
			return paymentDate.getTime() > booking_start.getTime()
				? new Date(
						Date.UTC(
							booking_start.getFullYear(),
							booking_start.getMonth(),
							booking_start.getDate()
						)
				  )
				: paymentDate;
		} else {
			if (payment_number === 1) {
				const paymentDate = new Date(
					Date.UTC(
						created_at.getFullYear(),
						created_at.getMonth(),
						created_at.getDate() + 2
					)
				);
				return paymentDate.getTime() > booking_start.getTime()
					? new Date(
							Date.UTC(
								booking_start.getFullYear(),
								booking_start.getMonth(),
								booking_start.getDate()
							)
					  )
					: paymentDate;
			} else {
				return new Date(
					Date.UTC(
						booking_start.getFullYear(),
						booking_start.getMonth(),
						booking_start.getDate() - 30
					)
				);
			}
		}
	},
};

export default utility;
