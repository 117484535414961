import React from 'react';
import './styles.css';

export default function AddonItem(props) {
	const { index, toggleState, addon } = props;

	return (
		<div
			onClick={() => toggleState(index)}
			className='addon-item-container'
		>
			<div className='addon-item-detail-container'>
				<div
					className={
						'addon-item-indicator' +
						(addon.state === true
							? ' addon-item-indicator-active'
							: '')
					}
				></div>
				<div>
					<p className='addon-item-title'>{addon.addon.name}</p>
					<p className='addon-item-description'>
						{addon.addon.description}
					</p>
				</div>
			</div>
			<div className='addon-item-price-container'>
				<p className='addon-item-price'>
					{addon.addon.price} DKK{' '}
					{addon.addon.perDay ? '(per dag)' : ''}
				</p>
			</div>
		</div>
	);
}
