import React from 'react';
import { Link } from 'react-router-dom';
import LoadingButton from '../../loadingbutton/loadingbutton';

const LoginContainer = (props) => {
	const {
		setLoginUsername,
		setLoginPassword,
		signingIn,
		loginUsername,
		loginPassword,
		signIn,
		loginError,
	} = props.signIn;

	return (
		<div className='login-page-section'>
			<div className='login-container'>
				<h1 className='login-title'>LOG IND</h1>
				<div className='login-input-container'>
					<input
						type='text'
						className='login-input'
						placeholder='Brugernavn'
						id='login-username'
						value={loginUsername}
						onChange={(e) => setLoginUsername(e.target.value)}
					/>
					<label
						className='login-input-icon-container'
						htmlFor='login-username'
					>
						<img
							src='/login-profile.svg'
							className='login-input-icon'
							alt='username'
						/>
					</label>
				</div>
				<div className='login-input-container'>
					<input
						type='password'
						className='login-input'
						placeholder='Adgangskode'
						id='login-password'
						value={loginPassword}
						onChange={(e) => setLoginPassword(e.target.value)}
					/>
					<label
						className='login-input-icon-container'
						htmlFor='login-password'
					>
						<img
							src='/login-key.svg'
							className='login-input-icon'
							alt='password'
						/>
					</label>
				</div>

				<Link
					to='/glemt'
					replace={true}
					className='forgot-password-text'
				>
					Glemt adgangskode
				</Link>

				<LoadingButton
					classes='login-button'
					isLoading={signingIn}
					clickHandler={signIn}
					text='Log ind'
				/>
				{loginError !== '' && (
					<p className='login-error-text'>{loginError}</p>
				)}
				<Link to='/opret' replace={true}>
					<button className='login-signup-button'>Opret</button>
				</Link>
			</div>
		</div>
	);
};

export default LoginContainer;
