import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import TimeObject from '../../../utils/timeobject';
import TimeSelection from '../../TimeSelection/timeselection';
import Calender from './calender';
import { useNavigate } from 'react-router-dom';
import DateUtility from '../../../utils/dateutiltity';

const AcCalender = ({
	startPrice,
	calenderObjects,
	calenderDays,
	autocamperId,
	setCalenderRef,
}) => {
	const [month, setMonth] = useState(null);
	const [minDate, setMinDate] = useState(null);
	const [maxDate, setMaxDate] = useState(null);
	const [bookings1, setBookings1] = useState([]);
	const [bookings2, setBookings2] = useState([]);
	const [calenderDays1, setCalenderDays1] = useState([]);
	const [calenderDays2, setCalenderDays2] = useState([]);
	const [startTime, setStartTime] = useState(new TimeObject());
	const [endTime, setEndTime] = useState(new TimeObject());
	const [validate, setValidate] = useState(null);
	const [validateIsLoading, setValidateIsLoading] = useState(false);

	const calenderRef = useRef(null);
	const navigate = useNavigate();

	/**
	 * Sets the selected start date for the booking.
	 * Also updates the calender to the selected week.
	 * @param {Date} day
	 */
	const setStartDate = (day) => {
		if (day instanceof Date) {
			setStartTime(new TimeObject(day));
			// updateWeek(day);
		}
	};

	/**
	 * Sets the selected start time of the date for the booking.
	 * @param {*} clock
	 */
	const setStartClock = (clock) => {
		if (Number.isInteger(clock) && !Number.isNaN(clock)) {
			setStartTime((prevTime) => new TimeObject(prevTime.getDate, clock));
		}
	};

	/**
	 * Resets selected start.
	 */
	const resetStart = () => {
		setStartTime(new TimeObject());
	};

	/**
	 * Sets the selected start date for the booking.
	 * Also updates the calender to the selected week.
	 * @param {Date} day
	 */
	const setEndDate = (day) => {
		if (day instanceof Date) {
			setEndTime(new TimeObject(day));
			// updateWeek(day);
		}
	};

	/**
	 * Sets the selected start time of the date for the booking.
	 * @param {*} clock
	 */
	const setEndClock = (clock) => {
		if (Number.isInteger(clock) && !Number.isNaN(clock)) {
			setEndTime((prevTime) => new TimeObject(prevTime.getDate, clock));
		}
	};

	/**
	 * Resets selected end.
	 */
	const resetEnd = () => {
		setEndTime(new TimeObject());
	};

	const validateTime = useCallback(async () => {
		if (startTime.isSelected() && endTime.isSelected()) {
			setValidateIsLoading(true);
			let query = `start=${startTime.unix}&end=${endTime.unix}`;

			try {
				const response = await axios.get(
					process.env.REACT_APP_API_URL +
						`/calender-objects/${autocamperId}/validate?${query}`
				);

				setValidate(response?.data);
			} catch (error) {
				setValidate({ start: false, end: false });
			} finally {
				setValidateIsLoading(false);
			}
		} else {
			setValidate({ start: false, end: false });
		}
	}, [startTime, endTime, autocamperId]);

	useEffect(() => {
		setCalenderRef(calenderRef);
	}, [calenderRef, setCalenderRef]);

	useEffect(() => {
		validateTime(startTime, endTime);
	}, [startTime, endTime, validateTime]);

	useEffect(() => {
		const now = new Date();
		const newMonth = new Date(
			Date.UTC(now.getFullYear(), now.getMonth(), 1)
		);
		const today = new Date(
			Date.UTC(now.getFullYear(), now.getMonth(), now.getDate())
		);
		const nextYear = new Date(
			Date.UTC(now.getFullYear() + 1, now.getMonth(), now.getDate())
		);
		setMonth(newMonth);
		setMinDate(today);
		setMaxDate(nextYear);
	}, []);

	useEffect(() => {
		let isMounted = true;

		if (
			month !== null &&
			calenderObjects.getStatus() === 1 &&
			calenderDays.getStatus() === 1
		) {
			const startOfMonth = new Date(
				Date.UTC(month.getUTCFullYear(), month.getUTCMonth(), 1)
			);
			const endOfMonth = new Date(
				Date.UTC(month.getUTCFullYear(), month.getUTCMonth() + 1, 0)
			);
			const startOfNextMonth = new Date(
				Date.UTC(month.getUTCFullYear(), month.getUTCMonth() + 1, 1)
			);
			const endOfNextMonth = new Date(
				Date.UTC(month.getUTCFullYear(), month.getUTCMonth() + 2, 0)
			);

			const calenderDaysResult = calenderDays.getObject();
			let calenderDaysMonth1 = [];
			let calenderDaysMonth2 = [];

			let currentDate = startOfMonth;
			while (currentDate <= endOfNextMonth) {
				const dateWeekNumber = DateUtility.getWeekNumber(currentDate);
				const weekday = currentDate.getUTCDay();
				const index = (dateWeekNumber - 1) * 7 + weekday - 1;
				let calenderDayResult = calenderDaysResult[index];

				if (calenderDayResult?.swapday === true) {
					const pickupStart =
						calenderDayResult?.pickupStart.split(':');
					const pickupEnd = calenderDayResult?.pickupEnd.split(':');
					const deliveryStart =
						calenderDayResult?.deliveryStart.split(':');
					const deliveryEnd =
						calenderDayResult?.deliveryEnd.split(':');

					const calenderDay = {
						pickupStart: new Date(
							Date.UTC(
								currentDate.getUTCFullYear(),
								currentDate.getUTCMonth(),
								currentDate.getUTCDate(),
								pickupStart[0],
								pickupStart[1]
							)
						),
						pickupEnd: new Date(
							Date.UTC(
								currentDate.getUTCFullYear(),
								currentDate.getUTCMonth(),
								currentDate.getUTCDate(),
								pickupEnd[0],
								pickupEnd[1]
							)
						),
						deliveryStart: new Date(
							Date.UTC(
								currentDate.getUTCFullYear(),
								currentDate.getUTCMonth(),
								currentDate.getUTCDate(),
								deliveryStart[0],
								deliveryStart[1]
							)
						),
						deliveryEnd: new Date(
							Date.UTC(
								currentDate.getUTCFullYear(),
								currentDate.getUTCMonth(),
								currentDate.getUTCDate(),
								deliveryEnd[0],
								deliveryEnd[1]
							)
						),
					};

					if (currentDate < startOfNextMonth) {
						calenderDaysMonth1.push(calenderDay);
					} else {
						calenderDaysMonth2.push(calenderDay);
					}
				}

				currentDate = new Date(
					Date.UTC(
						currentDate.getUTCFullYear(),
						currentDate.getUTCMonth(),
						currentDate.getUTCDate() + 1
					)
				);
			}

			let calenderObjectsResult = calenderObjects
				.getObject()
				.map((calenderObject) => {
					return {
						start: new Date(calenderObject.start),
						end: new Date(calenderObject.end),
						startMargin: new Date(calenderObject.startMargin),
						endMargin: new Date(calenderObject.endMargin),
					};
				});
			let calenderObjects1 = [];
			let calenderObjects2 = [];

			for (let i = 0; i < calenderObjectsResult.length; i++) {
				const calenderObject = calenderObjectsResult[i];
				const { start, end } = calenderObject;
				if (
					(startOfMonth <= start && start <= endOfMonth) ||
					(startOfMonth <= end && end <= endOfMonth) ||
					(start < startOfMonth && endOfMonth < end)
				) {
					calenderObjects1.push(calenderObject);
				}
				if (
					(startOfNextMonth <= start && start <= endOfNextMonth) ||
					(startOfNextMonth <= end && end <= endOfNextMonth) ||
					(start < startOfNextMonth && endOfNextMonth < end)
				) {
					calenderObjects2.push(calenderObject);
				}
			}

			if (isMounted) {
				setCalenderDays1(calenderDaysMonth1);
				setCalenderDays2(calenderDaysMonth2);
				setBookings1(calenderObjects1);
				setBookings2(calenderObjects2);
			}
		}

		return () => {
			isMounted = false;
		};
	}, [month, calenderObjects, calenderDays]);

	const startBooking = () => {
		if (
			startTime.isSelected() &&
			endTime.isSelected() &&
			validate?.start === true &&
			validate?.end === true
		) {
			navigate('/book', {
				state: {
					start: startTime.unix,
					end: endTime.unix,
					id: autocamperId,
				},
			});
		} else {
			// let errorText;
			// if (!startTime.isSelected() || !endTime.isSelected()) {
			// 	errorText = 'Du skal vælge både en start- og slutdato.';
			// } else if (!validate.start && !validate.end) {
			// 	errorText =
			// 		'Dit valgte område er ugyldigt. Det kan være at det overlapper en anden booking, eller at start- eller sluttidspunktet ikke er muligt.';
			// } else if (!validate.start) {
			// 	errorText =
			// 		'Dit starttidspunkt på dagen er ugyldigt. Vælge et andet klokkesæt.';
			// } else {
			// 	errorText =
			// 		'Dit sluttidspunkt på dagen er ugyldigt. Vælge et andet klokkesæt.';
			// }
			// setDisplayError(true);
			// setErrorText(errorText);
		}
	};

	return (
		month !== null &&
		calenderObjects.getStatus() === 1 &&
		calenderDays.getStatus() === 1 && (
			<section className='overview-calenders-section' ref={calenderRef}>
				<div className='overview-calender-header'>
					<h1 className='overview-calender-title'>Book</h1>
				</div>
				<div className='overview-calender-time-selection-container'>
					<div className='overview-calender-time-selection-inner-container'>
						<TimeSelection
							value={startTime}
							setValue={setStartDate}
							setClock={setStartClock}
							other={endTime}
							max={endTime}
							resetOther={resetEnd}
							text='Startdato'
							isMin={true}
							id={autocamperId}
						/>
						<TimeSelection
							value={endTime}
							setValue={setEndDate}
							setClock={setEndClock}
							other={startTime}
							min={startTime}
							resetOther={resetStart}
							text='Slutdato'
							isMin={false}
							id={autocamperId}
						/>
					</div>
					<div className='overview-calender-time-selection-inner-container overview-calender-time-selection-inner-right-container'>
						{validateIsLoading ? (
							<p>Loading...</p>
						) : (
							<>
								{validate?.start === true &&
									validate?.end === true && (
										<p className='overview-calender-price-title'>
											Beløb:{' '}
											<span className='overview-calender-price'>
												{(
													(startPrice
														? startPrice
														: 0) + validate?.price
												).toFixed(2)}{' '}
												DKK
											</span>
										</p>
									)}
								{validate?.start === true &&
								validate?.end === true ? (
									<button
										className='overview-calender-book-button'
										onClick={() => startBooking()}
									>
										Book
									</button>
								) : (
									<button className='overview-calender-book-button overview-calender-book-button-disabled'>
										Book
									</button>
								)}
							</>
						)}
					</div>
				</div>
				<div className='overview-calenders'>
					<div className='overview-calenders-container'>
						<div className='overview-calenders-button-header'>
							<button
								onClick={() =>
									setMonth(
										new Date(
											Date.UTC(
												month.getUTCFullYear(),
												month.getUTCMonth() - 1,
												1
											)
										)
									)
								}
								className='overview-calenders-button overview-calenders-button-left '
							>
								<img src='/black-caret.svg' />
							</button>
							<button
								onClick={() =>
									setMonth(
										new Date(
											Date.UTC(
												month.getUTCFullYear(),
												month.getUTCMonth() + 1,
												1
											)
										)
									)
								}
								className='overview-calenders-button'
							>
								<img src='/black-caret.svg' />
							</button>
						</div>
						<Calender
							month={month}
							bookings={bookings1}
							startDays={calenderDays1}
							className='overview-calender-1'
							minDate={minDate}
							maxDate={maxDate}
						/>
						<Calender
							month={
								new Date(
									Date.UTC(
										month.getUTCFullYear(),
										month.getUTCMonth() + 1
									)
								)
							}
							bookings={bookings2}
							startDays={calenderDays2}
							className='overview-calender-2'
							minDate={minDate}
							maxDate={maxDate}
						/>
					</div>
				</div>
			</section>
		)
	);
};

export default AcCalender;
