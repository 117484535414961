import React from 'react';
import CalenderDay from './calenderday';
import DateUtility from '../../../utils/dateutiltity';

const AVAILABLE = 0; // Represents a date where there is no other bookings blocking.
const UNAVAILABLE = 1; // Represents a date where is blocking other bookings.
const ONLY_START = 2; // Represents a date where a booking has started another day, and ends on this date, with no other bookings that day. (A booking should still be able to be constructed this date, otherwise date is UNAVAILABLE)
const ONLY_END = 3; // Represents a date where a booking is already starting this date. (Should still be time able to finish a booking this day, otherwise UNAVAILABLE)
const START_AND_END = 4; // Represents a date where a booking is ending this date, and another booking starting this date. (The two bookings should be directly next to each other)
const START_OR_END = 5; // Represents a date where there already is booking(s) which starting and ending this day. Should still be able to end a booking, and start a booking on this date.
const BETWEEN = 6; // Represents a date where there is multiple bookings starting and ending this date. (Should be time between the bookings, be )
const BETWEEN_START = 7;
const BETWEEN_END = 8;

/**
 * @param {Date} day
 * @param {Array} bookings
 * @returns Array of bookings which is affecting the date.
 */
const getDayRelatedBookings = (day, bookings) => {
	let result = [];
	let bookingIndex = 0;
	let dateExceeded = false;
	while (bookingIndex < bookings.length && !dateExceeded) {
		let booking = bookings[bookingIndex];
		const { startMargin, endMargin } = booking;

		if (
			DateUtility.isSameDate(startMargin, day) ||
			DateUtility.isSameDate(endMargin, day) ||
			(DateUtility.dateLessThan(startMargin, day) &&
				DateUtility.dateLessThan(day, endMargin))
		) {
			result.push(booking);
		}

		if (DateUtility.dateLessThan(day, startMargin)) {
			dateExceeded = true;
		}

		bookingIndex++;
	}

	return result;
};

const getStatusOfDay = (day, bookings, startDay, minDate, maxDate) => {
	let status = AVAILABLE;

	if (minDate <= day && day < maxDate) {
		let dayBookings = getDayRelatedBookings(day, bookings);

		if (dayBookings.length > 0) {
			const bookingStartAvailableOnBeginningOfDay =
				DateUtility.isSameDate(day, dayBookings[0].startMargin) &&
				(startDay === null ||
					startDay.pickupStart < dayBookings[0].startMargin);
			const lastBookingEndsOnDay =
				DateUtility.isSameDate(
					day,
					dayBookings[dayBookings.length - 1].endMargin
				) &&
				(startDay === null ||
					startDay.deliveryEnd >
						dayBookings[dayBookings.length - 1].endMargin);

			const possibleOnlyThisDayBooking = dayBookings.reduce(
				(value, current) => {
					return {
						result:
							value.result ||
							(value.prev !== null &&
								value.prev.endMargin < current.startMargin),
						prev: current,
					};
				},
				{ result: false, prev: null }
			).result;

			if (
				bookingStartAvailableOnBeginningOfDay &&
				lastBookingEndsOnDay &&
				possibleOnlyThisDayBooking
			) {
				status = BETWEEN;
			} else if (
				bookingStartAvailableOnBeginningOfDay &&
				possibleOnlyThisDayBooking
			) {
				status = BETWEEN_END;
			} else if (lastBookingEndsOnDay && possibleOnlyThisDayBooking) {
				status = BETWEEN_START;
			} else if (
				bookingStartAvailableOnBeginningOfDay &&
				lastBookingEndsOnDay
			) {
				status = START_OR_END;
			} else if (bookingStartAvailableOnBeginningOfDay) {
				status = ONLY_END;
			} else if (lastBookingEndsOnDay) {
				status = ONLY_START;
			} else if (possibleOnlyThisDayBooking) {
				status = START_AND_END;
			} else {
				status = UNAVAILABLE;
			}
		}
	} else {
		status = UNAVAILABLE;
	}

	return status;
};

const isDayStartDay = (day, startDays) => {
	return startDays.reduce((value, current) => {
		return value || DateUtility.isSameDate(day, current.pickupStart);
	}, false);
};

const transformDates = (days, bookings, startDays, minDate, maxDate) => {
	let resultDays = [];

	let startDayIndex = 0;

	for (let dayNumber = 1; dayNumber <= days.length; dayNumber++) {
		let day = days[dayNumber - 1];

		let startDay = null;
		if (
			startDayIndex < startDays.length &&
			DateUtility.isSameDate(startDays[startDayIndex].pickupStart, day)
		) {
			startDay = startDays[startDayIndex];
			startDayIndex++;
		}

		const status = getStatusOfDay(
			day,
			bookings,
			startDay,
			minDate,
			maxDate
		);
		const isStartDay = isDayStartDay(day, startDays);
		resultDays.push({
			day,
			booked: status,
			isStartDay: isStartDay,
		});
	}

	return resultDays;
};

const monthNames = [
	'januar',
	'februar',
	'marts',
	'april',
	'maj',
	'juni',
	'juli',
	'august',
	'september',
	'oktober',
	'november',
	'december',
];
const getMonthName = (day) => {
	return monthNames[day.getUTCMonth()];
};

const Calender = ({ month, bookings, startDays, minDate, maxDate }) => {
	let days = DateUtility.getAllDaysInMonth(month);
	let dates = transformDates(days, bookings, startDays, minDate, maxDate);

	return (
		<div className='overview-calender-container'>
			<div className='overview-calender-month-header'>
				<span className='overview-calender-month-text'>
					{getMonthName(days[0])} {days[0].getUTCFullYear()}
				</span>
			</div>
			<div className='overview-calender-weekdays-header'>
				<span className='overview-calender-weekday-text'>ma</span>
				<span className='overview-calender-weekday-text'>ti</span>
				<span className='overview-calender-weekday-text'>on</span>
				<span className='overview-calender-weekday-text'>to</span>
				<span className='overview-calender-weekday-text'>fr</span>
				<span className='overview-calender-weekday-text'>lø</span>
				<span className='overview-calender-weekday-text'>sø</span>
			</div>
			<div
				style={{
					display: 'grid',
					gridTemplateColumns: 'repeat(7, 1fr)',
					gridTemplateRows: '1fr',
					gap: '10px',
				}}
			>
				{dates.map((date, i) => {
					return <CalenderDay date={date} key={i} />;
				})}
			</div>
		</div>
	);
};

export default Calender;
