import utility from '../../../utils/utility';

const AcImage = ({ displaySize, autocamper, setImageIndex }) => {
	const imageCount = autocamper?.images?.length;

	return (
		<>
			{displaySize === 'mobile' ? (
				<section className='ac-page-image-container ac-page-container'>
					<div className='ac-page-image-outer-container'>
						<img
							src={utility.getAutocamperImageSize(
								autocamper?.avatar[0],
								'small'
							)}
							alt='Autocamper'
							className='ac-page-image ac-page-main-image'
							onClick={() => setImageIndex(0)}
						/>
					</div>
				</section>
			) : (
				<section className='ac-page-image-container ac-page-container'>
					<div className='ac-page-image-outer-container ac-page-image-outer-main-container'>
						<img
							src={utility.getAutocamperImageSize(
								autocamper?.avatar[0],
								'medium'
							)}
							alt='Autocamper'
							className='ac-page-image ac-page-main-image'
							onClick={() => setImageIndex(0)}
						/>
					</div>
					{imageCount > 0 && (
						<>
							<div className='ac-page-image-outer-container ac-page-image-outer-secondary-container'>
								<img
									src={utility.getAutocamperImageSize(
										autocamper?.images[0],
										'small'
									)}
									alt='Autocamper'
									className='ac-page-image ac-page-image-second ac-page-secondary-image'
									onClick={() => setImageIndex(1)}
								/>
							</div>
							<div className='ac-page-image-outer-container ac-page-image-outer-more-container'>
								<img
									src={utility.getAutocamperImageSize(
										autocamper?.images[1 % imageCount],
										'small'
									)}
									alt='Autocamper'
									onClick={() =>
										setImageIndex(2 % imageCount)
									}
									className='ac-page-image ac-page-image-second ac-page-more-image'
								/>
								<div className='ac-page-image-overlay'>
									<p className='ac-page-image-overlay-text'>
										{autocamper?.images?.length + 1}{' '}
										billeder
									</p>
								</div>
							</div>
						</>
					)}
				</section>
			)}
		</>
	);
};

export default AcImage;
