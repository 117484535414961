import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/pages/homepage/homepage';
import ProfilePage from './components/pages/profilepage/profilepage';
import UnAuthRoute from './components/router/unauthroute';

import './App.css';
import LessorPage from './components/pages/lessorpage/lessorpage';
import AuthRoute from './components/router/authroute';
import AutocampersPage from './components/pages/autocamperspage/autocamperspage';
import BookPage from './components/pages/bookpage/bookpage';
import RentalConditionPage from './components/pages/rentelconditionpage/rentalconditionpage';
import TermsOfTradePage from './components/pages/termsoftradepage/termsoftradepage';
import PrivacyPolicyPage from './components/pages/privacypolicypage/privacypolicypage';
import CompletePage from './components/pages/completepage/completepage';
import ResetPage from './components/pages/resetpage/resetpage';
import BookingPage from './components/pages/bookingpage/bookingpage';
import PricePage from './components/pages/pricepage/pricepage';
import SignupPage from './components/pages/signuppage/signuppage';
import LoginPage from './components/pages/loginpage/loginpage';
import ForgotPage from './components/pages/forgotpage/forgotpage';
import AcPage from './components/pages/autocamperpage/acpage';

function App() {
	return (
		<>
			<Router>
				<Routes>
					<Route path='/' element={<HomePage />} />
					<Route
						path='/profil'
						element={
							<AuthRoute>
								<ProfilePage />
							</AuthRoute>
						}
					></Route>
					<Route
						path='/booking/:id'
						element={
							<AuthRoute>
								<BookingPage />
							</AuthRoute>
						}
					></Route>
					<Route path='/udlejer' element={<LessorPage />} />
					<Route path='/autocamper' element={<AutocampersPage />} />
					<Route path='/autocamper/:id' element={<AcPage />} />
					<Route path='/book' element={<BookPage />} />
					<Route
						path='/lejebetingelser'
						element={<RentalConditionPage />}
					/>
					<Route
						path='/handelsbetingelser'
						element={<TermsOfTradePage />}
					/>
					<Route
						path='/privatlivspolitik'
						element={<PrivacyPolicyPage />}
					/>
					<Route path='/pris' element={<PricePage />} />
					<Route path='/complete' element={<CompletePage />} />
					<Route path='/ny-adgangskode' element={<ResetPage />} />
					<Route
						path='/opret'
						element={
							<UnAuthRoute>
								<SignupPage />
							</UnAuthRoute>
						}
					/>
					<Route
						path='/logind'
						element={
							<UnAuthRoute>
								<LoginPage />
							</UnAuthRoute>
						}
					/>
					<Route
						path='/glemt'
						element={
							<UnAuthRoute>
								<ForgotPage />
							</UnAuthRoute>
						}
					/>
				</Routes>
			</Router>
		</>
	);
}

export default App;
