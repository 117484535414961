import React, { Component } from 'react';
import './styles.css';

export default class Popup extends Component {
	render() {
		return (
			<div className='popup-background'>
				<div className='popup-content-container'>
					<div className='popup-header-container'>
						<p className='popup-header-title'>{this.props.title}</p>
						<div
							className='popup-close-button'
							onClick={() => this.props.close()}
						>
							<img
								src='/close.svg'
								className='popup-close-icon'
								alt='close'
							/>
						</div>
					</div>
					<div className='popup-content'>{this.props.children}</div>
				</div>
			</div>
		);
	}
}
