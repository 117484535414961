import React from 'react';
import { Helmet } from 'react-helmet-async';
import DefaultLayout from '../../containers/layout/defaultlayout/defaultlayout';

import './styles.css';

const PricePage = () => {
	return (
		<>
			<Helmet>
				<title>Prisudregner</title>
				<meta
					name='description'
					content='Hvordan udregner vi priserne for bookinger.'
				/>
				<meta
					name='keywords'
					content='autocamper, pris, price, prisudregning'
				/>
				<meta name='robots' content='' />
			</Helmet>
			<DefaultLayout>
				<div className='responsive-container'>
					<div className='price-container'>
						<div>
							<h1 className='price-title'>Prisudregner</h1>
							<p>
								Det her er en oversigt over hvordan vi udregner
								prisen for en booking.
							</p>
						</div>
						<div>
							<h2 className='price-title'>Depositum</h2>
							<p>
								Depositummet er en fast pris på 5000 DKK, som
								bliver sat på alle bookinger. Det er en pris som
								er sat på bookingen som vil gå til reperation og
								ligende hvis noget går i stykker eller andet
								under rejsen på autocamperen. Efterfølgende vil
								det resterende beløb blive tilbagebetalt til
								lejeren.
							</p>
						</div>
						<div>
							<h2 className='price-title'>Startpris</h2>
							<p>
								En fast pris som alle bookinger har, ligegyldigt
								længden af bookingen. Denne pris variere
								afhængig hvilken autocamper som bliver booket.
							</p>
						</div>
						<div>
							<h2 className='price-title'>Lejepris</h2>
							<p>
								Det er prisen for hele lejeperioden. Hver dag
								kan have en forskellig pris på hvad det koster
								for at booke i denne periode, og det afhænger
								også af hvilken autocamper som bookes.
								<br />
								Når en periode er valgt til booking, så kigges
								det på hvor stor en procentdel af dagene
								autocamperen er booket og tager den procentdel
								af prisen for dagen. Så hvis man starter med at
								booke fra klokken 12 af midt på dagen, så skal
								man kun betale halvdelen af prisen for den dag.
								Det samme gælder så også for hvis man aflevere
								klokken 12, så skal man også kun betale
								halvdelen for dagen. Alle andre dage som ikke er
								afhentning eller afleveringsdage er fuld pris
								for dagene.
							</p>
						</div>
						<div>
							<h2 className='price-title'>Tilkøb</h2>
							<p>
								Der findes to forskellige slags tilkøb,
								engangsbeløb og per dag.
								<br /> Engangsbeløb er en pris der bliver betalt
								ligegyldigt længden af lejen.
								<br /> Per dag pris er en pris som betales hver
								dag for lejen, og til modsætning for lejeprisen
								så bliver prisen ikke sat ned for de dage hvor
								man ikke har booket hele dagen.
							</p>
						</div>
					</div>
				</div>
			</DefaultLayout>
		</>
	);
};

export default PricePage;
