import { useState, useEffect } from 'react';
import TimeObject from '../../../utils/timeobject';
import './styles.css';

const CalenderItem = ({
	conditions,
	setDate,
	day,
	selected,
	other,
	isMin,
	resetOther,
	availableDays,
	isLoading,
	closestBooking,
}) => {
	const [classes, setClasses] = useState();
	const [isValid, setIsValid] = useState(null);

	useEffect(() => {
		var isMounted = true;

		const setupClasses = () => {
			let classList = ['calender-selection-date-item'];
			// Finding the first day of the month.
			var firstDayInMonth = new Date(
				Date.UTC(day.getUTCFullYear(), day.getUTCMonth())
			).getDay();
			if (firstDayInMonth === 0) firstDayInMonth = 7;

			if (day.getUTCDate() === 1)
				classList.push(`column-${firstDayInMonth}`);

			// Checking if the day is a day that can be selected.
			let valid = conditions.today <= day;

			if (!isLoading) {
				if (valid === true && availableDays !== null) {
					if (day.getDate() - 1 < availableDays.length) {
						valid = availableDays[day.getDate() - 1].length > 0;
					} else {
						valid = false;
					}
				}

				if (valid) {
					if (closestBooking && closestBooking.length > 0) {
						const compareDate = new Date(
							Date.UTC(
								closestBooking[0].getUTCFullYear(),
								closestBooking[0].getUTCMonth(),
								closestBooking[0].getUTCDate()
							)
						);

						if (
							(isMin && compareDate > day) ||
							(!isMin && compareDate < day)
						) {
							classList.push(
								'calender-selection-date-item-resets-other'
							);
						}
					}
					classList.push('calender-selection-date-item-valid');
				}

				// Check that if it's the selected date.
				if (selected) {
					const selectedDate = selected.getSelectedDateUTC();
					if (
						selected.isSelected() &&
						day &&
						selectedDate.getTime() === day.getTime()
					)
						classList.push(
							'calender-selection-date-item-selected-day'
						);
				}

				// Check that if it's the other selected date.
				if (other) {
					const otherDate = other.getSelectedDateUTC();
					if (
						other.isSelected() &&
						day &&
						otherDate.getTime() === day.getTime()
					)
						classList.push(
							'calender-selection-date-item-selected-day'
						);
				}

				if (
					conditions.max instanceof TimeObject &&
					conditions.max.isSelected() &&
					conditions.min instanceof TimeObject &&
					conditions.min.isSelected()
				) {
					const minDate = conditions.min.getSelectedDateUTC();
					const maxDate = conditions.max.getSelectedDateUTC();
					if (minDate <= day && day <= maxDate)
						classList.push(
							'calender-selection-inbetween-date-item-selected-day'
						);
				}
			}

			if (isMounted) {
				setIsValid(valid);
				setClasses(classList.join(' '));
			}
		};

		setupClasses();

		return () => {
			isMounted = false;
		};
	}, [
		conditions,
		day,
		selected,
		other,
		isMin,
		availableDays,
		isValid,
		isLoading,
		closestBooking,
	]);

	const setNewDate = () => {
		if (isValid) {
			setDate(day);
		}
	};

	return isLoading === true ? (
		<div className={`${classes} calender-selection-date-item-loading`}>
			<div className='calender-selection-date-item-loading-inner loading-container'>
				<p>{day.getUTCDate()}</p>
			</div>
		</div>
	) : (
		<div className={classes} onClick={() => setNewDate()}>
			<p>{day.getUTCDate()}</p>
		</div>
	);
};

export default CalenderItem;
