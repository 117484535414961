class RequestObject {
	#Status = {
		Loading: 0,
		Success: 1,
		Failure: 2,
	};

	#func;
	#object = null;
	#status = this.#Status.Loading;
	#responseCode = '';
	#message = '';

	constructor(
		func,
		object = null,
		status = 0,
		responseCode = '',
		message = ''
	) {
		this.#func = func;
		this.#object = object;
		this.#status = status;
		this.#responseCode = responseCode;
		this.#message = message;
	}

	async sendRequest(value) {
		try {
			const response = await this.#func(value);

			this.#responseCode = response.status;
			if (response.status === 200) {
				this.#status = this.#Status.Success;
				this.#object = response.data;
			} else {
				this.#status = this.#Status.Failure;
			}
		} catch (error) {
			const err = error.toJSON();
			this.#status = this.#Status.Failure;
			this.#responseCode = err.status;
			this.#message = err.message;
		}
	}

	/**
	 * Returns the encapsulated object.
	 */
	getObject() {
		return this.#object;
	}

	/**
	 * Returns the status.
	 * 0: Loading
	 * 1: Success
	 * 2: Failure
	 */
	getStatus() {
		return this.#status;
	}

	/**
	 * Returns the response code for the request.
	 */
	getResponseCode() {
		return this.#responseCode;
	}

	/**
	 * Returns the message.
	 */
	getMessage() {
		return this.#message;
	}

	/**
	 * Making a deep copy of the object.
	 */
	clone() {
		return new RequestObject(
			this.#func,
			this.#object,
			this.#status,
			this.#responseCode,
			this.#message
		);
	}
}

export default RequestObject;
