import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';
import Calender from './calender/calender';
import ClockSelection from './clockselection';
import './styles.css';
import { useDebounce } from 'use-debounce';
import TimeObject from '../../utils/timeobject';
import DateUtility from '../../utils/dateutiltity';

const TimeSelection = ({
	value,
	setValue,
	setClock,
	text,
	other,
	max,
	min,
	isMin,
	resetOther,
	onChange,
	id,
}) => {
	const [display, setDisplay] = useState(false);
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [availableDays, setAvailableDays] = useState(null);
	const [isLoadingAvailableDays, setIsLoadingAvailableDays] = useState(true);
	const [isLoadingClosestBooking, setIsLoadingClosestBooking] =
		useState(false);
	const [error, setError] = useState(null);
	const [closestError, setClosestError] = useState(null);
	const [closestBooking, setClosestBooking] = useState(null);

	const [debounceSelectedMonth] = useDebounce(selectedMonth, 1000);

	const fetchAvailableDays = useCallback(async () => {
		try {
			let query = `time=${debounceSelectedMonth.getTime()}`;

			if (isMin) {
				query += `&pickup=${true}`;
			} else {
				query += `&delivery=${true}`;
			}

			if (other.isSelected())
				query += `&other=${other.getSelectedDateUTC().getTime()}`;

			const response = await axios.get(
				process.env.REACT_APP_API_URL +
					`/autocampers/${id}/freeslots?${query}`
			);

			if (response?.status === 200) {
				return response.data;
			}
		} catch (error) {
			setError(true);
		}
		return null;
	}, [debounceSelectedMonth, id, other, isMin]);

	useEffect(() => {
		let isMounted = true;

		const availableDaysFunction = async () => {
			if (debounceSelectedMonth !== null) {
				if (id !== undefined) {
					const response = await fetchAvailableDays();

					if (response !== null) {
						if (isMounted) {
							setIsLoadingAvailableDays(false);

							let freeslots = response.freeslots;
							const reserved = response.reserved.map(
								(booking) => {
									return {
										start: new Date(
											parseInt(booking.start) -
												response.swapMargin * 3600000
										),
										end: new Date(
											parseInt(booking.end) +
												response.swapMargin * 3600000
										),
									};
								}
							);

							reserved.forEach((booking) => {
								const startMonth = debounceSelectedMonth;
								const endMonth = new Date(
									Date.UTC(
										startMonth.getUTCFullYear(),
										startMonth.getUTCMonth() + 1,
										1
									)
								);

								const startDate =
									startMonth <= booking.start
										? booking.start
										: startMonth;
								const endDate =
									endMonth >= booking.end
										? booking.end
										: endMonth;

								let indexDate = new Date(
									Date.UTC(
										startDate.getUTCFullYear(),
										startDate.getUTCMonth(),
										startDate.getUTCDate() + 1
									)
								);
								const finishDate = new Date(
									Date.UTC(
										endDate.getUTCFullYear(),
										endDate.getUTCMonth(),
										endDate.getUTCDate()
									)
								);

								const convertDate = (date) => {
									return (
										date.getUTCHours() * 2 +
										(date.getUTCMinutes() >= 30 ? 1 : 0)
									);
								};

								if (
									DateUtility.isSameDate(endDate, startDate)
								) {
									freeslots[startDate.getUTCDate() - 1] =
										freeslots[
											startDate.getUTCDate() - 1
										].filter(
											(time) =>
												time <=
													convertDate(startDate) ||
												time >= convertDate(endDate)
										);
								} else {
									// Remove all dates inbetween.
									freeslots[startDate.getUTCDate() - 1] =
										freeslots[
											startDate.getUTCDate() - 1
										].filter(
											(time) =>
												time <= convertDate(startDate)
										);

									while (indexDate < finishDate) {
										freeslots[indexDate.getUTCDate() - 1] =
											[];

										indexDate = new Date(
											Date.UTC(
												indexDate.getUTCFullYear(),
												indexDate.getUTCMonth(),
												indexDate.getUTCDate() + 1
											)
										);
									}

									if (
										endDate < endMonth &&
										startDate.getUTCDate() !==
											endDate.getUTCDate()
									) {
										freeslots[endDate.getUTCDate() - 1] =
											freeslots[
												endDate.getUTCDate() - 1
											].filter((time) => {
												let result =
													time >=
													convertDate(endDate);
												return result;
											});
									}
								}
							});

							setAvailableDays(freeslots);
						}
					} else {
						setError(true);
					}
				} else {
					const allDays = [];

					for (let i = 0; i < 31; i++) {
						const daySlots = [];
						for (let j = 0; j < 48; j++) {
							daySlots.push(j);
						}
						allDays.push(daySlots);
					}

					if (isMounted) {
						setAvailableDays(allDays);
						setIsLoadingAvailableDays(false);
					}
				}
			}
		};

		availableDaysFunction();

		return () => {
			isMounted = false;
		};
	}, [debounceSelectedMonth, id, fetchAvailableDays]);

	useEffect(() => {
		let isMounted = true;

		const today = new Date();
		const selection =
			value !== undefined && value.isSelected()
				? value.getSelectedDateUTC()
				: !isMin && min !== undefined && min.dateSelected()
				? min.getSelectedDateUTC()
				: isMin && max !== undefined && max.dateSelected()
				? max.getSelectedDateUTC()
				: today;

		const month = new Date(
			Date.UTC(selection.getUTCFullYear(), selection.getUTCMonth(), 1)
		);

		if (display === 'calender') {
			if (isMounted) {
				setIsLoadingAvailableDays(true);
				setSelectedMonth(month);
			}
		}

		return () => {
			isMounted = false;
		};
	}, [min, max, value, display, isMin]);

	useEffect(() => {
		let isMounted = true;

		const fetchClosestBooking = async () => {
			if (other.dateSelected() && id !== undefined) {
				try {
					setIsLoadingClosestBooking(true);

					const time = other.isSelected()
						? other.getSelectedUTC()
						: other.getSelectedDateUTC();

					const response = await axios.get(
						process.env.REACT_APP_API_URL +
							`/autocampers/${id}/closest?time=${time.getTime()}&${
								isMin ? 'delivery' : 'pickup'
							}=${true}`
					);

					if (response?.status === 200) {
						let result = [];
						if (response.data.length > 0) {
							result.push(new Date(response.data[0]));
						}
						if (isMounted) setClosestBooking(result);
					}
				} catch (error) {
					if (isMounted) {
						setClosestError(true);
						setClosestBooking(null);
					}
				} finally {
					if (isMounted) setIsLoadingClosestBooking(false);
				}
			} else {
				if (isMounted) setClosestBooking([]);
			}
		};

		fetchClosestBooking();

		return () => {
			isMounted = false;
		};
	}, [id, other, isMin]);

	const updateValue = async (newValue) => {
		const newTime = new TimeObject(newValue, value.getTime);

		setValue(newValue);
		setDisplay('clock');

		const otherDate = other.getSelectedDateUTC();
		const selectDay = new Date(
			Date.UTC(
				newValue.getUTCFullYear(),
				newValue.getUTCMonth(),
				newValue.getUTCDate()
			)
		);
		let otherTime = other;

		if (
			(isMin &&
				(otherDate < selectDay ||
					(closestBooking !== null &&
						closestBooking.length > 0 &&
						closestBooking[0].getTime() >
							selectDay.getTime() + 86400000))) ||
			(!isMin &&
				(otherDate > selectDay ||
					(closestBooking !== null &&
						closestBooking.length > 0 &&
						closestBooking[0] < selectDay)))
		) {
			resetOther();
			otherTime = new TimeObject(null);
		}

		if (onChange) {
			if (isMin) {
				await onChange(newTime, otherTime);
			} else {
				await onChange(otherTime, newTime);
			}
		}
	};

	const updateClock = async (clock) => {
		const newTime = new TimeObject(value.getDate, clock);
		let otherTime = other;
		setClock(clock);
		setDisplay('');

		if (
			other.isSelected() &&
			((isMin &&
				otherTime.getSelectedUTC() <= newTime.getSelectedUTC()) ||
				(!isMin &&
					otherTime.getSelectedUTC() >= newTime.getSelectedUTC()))
		) {
			resetOther();
			otherTime = new TimeObject(null);
		}

		if (onChange) {
			if (isMin) await onChange(newTime, otherTime);
			else await onChange(otherTime, newTime);
		}
	};

	const displayCalender = () => {
		setDisplay('calender');
	};

	const previousMonth = () => {
		setIsLoadingAvailableDays(true);
		setError(null);
		setSelectedMonth((prevMonth) => {
			return new Date(
				Date.UTC(prevMonth.getFullYear(), prevMonth.getMonth() - 1, 1)
			);
		});
	};

	const nextMonth = () => {
		setIsLoadingAvailableDays(true);
		setError(null);
		setSelectedMonth((prevMonth) => {
			return new Date(
				Date.UTC(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 1)
			);
		});
	};

	return (
		<div className='time-selection-div'>
			<div className='time-selection-container'>
				<div
					className={
						'time-selection-date-container ' +
						(value.dateSelected()
							? 'time-selection-date-container-first'
							: '')
					}
				>
					<div
						className='time-selection-icon-container'
						onClick={() => displayCalender()}
					>
						<img
							src='/calender-primary.svg'
							className='time-selection-icon'
							alt='calender'
						/>
					</div>
					<div
						className='time-selection-text-container'
						onClick={() => displayCalender()}
					>
						<div className='time-selection-text-bottom-container'>
							<p className='time-selection-title'>{text}</p>
						</div>
						<div className='time-selection-text-top-container'>
							<p className='time-selection-value-date'>
								{!value.dateSelected()
									? 'Tilføj dato'
									: value.stringDate()}
							</p>
						</div>
					</div>
					{display === 'calender' && (
						<Calender
							setDate={updateValue}
							selected={value}
							other={other}
							max={max}
							min={min}
							hide={() => {
								setDisplay('');
							}}
							isMin={isMin}
							resetOther={resetOther}
							availableDays={availableDays}
							month={selectedMonth}
							previousMonth={previousMonth}
							nextMonth={nextMonth}
							isLoading={
								isLoadingAvailableDays ||
								isLoadingClosestBooking
							}
							error={error === true || closestError === true}
							closestBooking={closestBooking}
						/>
					)}
				</div>
				{value.dateSelected() && (
					<div className='time-selection-clock-container'>
						<div
							onClick={() => setDisplay('clock')}
							className='time-selection-clock'
						>
							<p>
								{value.getTime !== -1
									? value.stringClock()
									: 'Vælg'}
							</p>
						</div>
						{display === 'clock' && (
							<ClockSelection
								display={display}
								time={value}
								availableDays={availableDays}
								setClock={updateClock}
								hide={() => {
									setDisplay('');
								}}
								isLoading={
									isLoadingAvailableDays ||
									isLoadingClosestBooking
								}
								error={error === true || closestError === true}
								closestBooking={closestBooking}
							/>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export default TimeSelection;
