import React from 'react';

import './styles.css';

export default function LoadingContainer(props) {
	const { requestObject, showErrors } = props;

	if (requestObject.getStatus() === 0) {
		return <div className='loading-container'></div>;
	} else if (requestObject.getStatus() === 1) {
		return <React.Fragment>{props.children}</React.Fragment>;
	} else if (requestObject.getStatus() === 2) {
		return (
			<div className='loading-error-container'>
				{' '}
				{(showErrors === true || showErrors === undefined) && (
					<React.Fragment>
						<span className='loading-error-code'>
							{requestObject.getResponseCode()}
						</span>
						<span className='loading-error-message'>
							{requestObject.getMessage()}
						</span>
					</React.Fragment>
				)}
			</div>
		);
	}
}
