const AcBookHeader = ({ display, autocamper, scrollToCalender }) => {
	return (
		<div
			className={
				display
					? 'ac-page-book-header'
					: 'ac-page-book-header ac-page-book-header-hidden'
			}
		>
			<div className='ac-page-book-header-price-container'>
				<p className='ac-page-book-header-price'>
					{autocamper?.price ? autocamper.price : ''}
				</p>
				<p className='ac-page-book-header-unit'>DKK/DAG</p>
			</div>
			<div className='ac-page-book-header-button-container'>
				<button
					className='ac-page-detail-book-button'
					onClick={scrollToCalender}
				>
					Book nu
				</button>
			</div>
		</div>
	);
};

export default AcBookHeader;
